export const step4ToServer = (data, countries) => {
  const {
    residency,
    citizenship,
    homeAddressCountry,
    homeAddressZipCode,
    homeAddressRegion,
    homeAddressCity,
    homeAddressStreetAddress,
    mailAddressCountry,
    mailAddressZipCode,
    mailAddressRegion,
    mailAddressCity,
    mailAddressStreetAddress,
    docType,
    docNumber,
    issuanceDate,
    expirationDate,
    personalNumber,
    ...other
  } = data;
  const homeAddress = {
    country: homeAddressCountry,
    zipCode: homeAddressZipCode,
    region: homeAddressRegion,
    city: homeAddressCity,
    address: homeAddressStreetAddress
  };
  const mailAddress = {
    country: mailAddressCountry,
    zipCode: mailAddressZipCode,
    region: mailAddressRegion,
    city: mailAddressCity,
    address: mailAddressStreetAddress
  };
  const citizenshipDocument =
    residency !== citizenship
      ? { docType, docNumber, issuanceDate, expirationDate, personalNumber }
      : null;
  const residences = [countries.filter((item) => item.country === residency)[0].id];
  const citizenships = [countries.filter((item) => item.country === citizenship)[0].id];

  return {
    homeAddress,
    mailAddress,
    citizenshipDocument,
    residences,
    citizenships,
    step: 4,
    ...other
  };
};
