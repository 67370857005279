import React from 'react';
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Text,
  IconArrowDown,
  IconArrowUp,
  Button
} from '@aragon/ui';
import styles from '../style.module.css';
import textStyle from '../../../utils/textStyle';

const AssetsTable = ({ header, data }) => {
  const headerItems = header.map((item) => <TableHeader title={item} />);

  const dataItems = data.map((item) => (
    <TableRow>
      <TableCell />
      <TableCell>
        <Text style={textStyle('body3')}>{item.date}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.type}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.assetsTitle}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.quantity}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.ticker}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.transactionType}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>
          <IconArrowUp className={styles['arrowText']} color='#2CC68F' /> {item.profit}{' '}
          <IconArrowDown className={styles['arrowText']} color='#FF6969' /> {item.loss}
        </Text>
      </TableCell>
      <TableCell className={`${styles['tableThSm']} ${styles['details']}`}>
        <Button>Details</Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <Table
      className={styles['no-border']}
      header={
        <TableRow className={styles['tableHeader']}>
          <TableHeader />
          {headerItems}
          <TableHeader />
        </TableRow>
      }
    >
      {dataItems}
    </Table>
  );
};

export default AssetsTable;
