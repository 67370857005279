import React from 'react';
import { QueryClient, QueryClientProvider as QueryProvider } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const QueryClientProvider = ({ children }) => {
  return (
    <QueryProvider client={queryClient}>
      {children}
    </QueryProvider>
  );
};
