import axios from 'axios';
import { API_URL } from '../../../utils/static/api';

export const getAssets = (params) => {
  return axios.get(`${API_URL}/api/v1/investor/assets`, { params: params });
};

export const getAsset = (id) => {
  return axios.get(`${API_URL}/api/v1/investor/asset`, { params: { assetId: id } });
};

export const getMyAssets = (params) => {
  return axios.get(`${API_URL}/api/v1/investor/myAssets`, { params: params });
};

export const makeOrder = (data) => {
  return axios.post(`${API_URL}/api/v1/investor/assets/order`, data);
};
