import React from 'react';
import { UploadDocumentsBox } from '../../../../components';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

export const UtilityBill = ({ form, label, ...props }) => {
  const {
    field: { onChange, value, name },
    fieldState: { error }
  } = useController({
    ...form,
    rules: { required: 'Required' },
    defaultValue: null
  });

  if (error) console.log({ error });

  return (
    <Container>
      <UploadDocumentsBox
        docs={value ? [value] : null}
        setDocs={onChange}
        label={'Utility Bill'}
        {...props}
      />
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
  width: 50%;
  padding: 0 8px 0 0;
`;
