import axios from 'axios';
import { API_URL } from '../../../utils/static/api';

const getAvailableLanguages = () => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/language`);
};
const setLanguage = ({languageId}) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/language`, {
    languageId
  });
};
const getAllQuestionnaires = () => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaires/all`);
};
const getAllQuestionnairesTranslation = (languageId) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaires/all/language`, { params: { languageId } });
};
const getQuestionnaireSections = ({ questionSectionTypeId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/sections`, { params: { questionSectionTypeId } });
};
const getQuestionnaireSectionsTranslation = ({ questionSectionTypeId, languageId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/sections/language`, {
    params: {
      questionSectionTypeId,
      languageId
    }
  });
};

const setQuestionnaireInfo = ({ questionSectionTypeId, languageId, name, title, descriptions }) => {
  return axios.put(`${API_URL}/api/kyc/v1/admin/questionnaires/all`, {
    questionSectionTypeId,
    languageId,
    name,
    title,
    descriptions
  });
};
const setQuestionnaireSection = ({ questionSectionTypeId, languageId, newText, questionSectionId, questionGroupId, questionId, availableAnswerId }) => {
  return axios.put(`${API_URL}/api/kyc/v1/admin/questionnaire/sections`, {
    questionSectionTypeId,
    languageId,
    newText,
    questionSectionId,
    questionGroupId,
    questionId,
    availableAnswerId
  });
};
const activateQuestionnaireTranslation = ({ questionSectionTypeId, languageId }) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/questionnaire/active`, {
    questionSectionTypeId,
    languageId
  });
};
const isQuestTranslationActive = ({ questionSectionTypeId, languageId }) => (
  axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/active/check`, {
    params: {
      questionSectionTypeId,
      languageId
    }
  })
);


export const adminLocalizationApi = {
  getAvailableLanguages,
  getAllQuestionnaires,
  setQuestionnaireInfo,
  getQuestionnaireSections,
  setQuestionnaireSection,
  activateQuestionnaireTranslation,
  getAllQuestionnairesTranslation,
  getQuestionnaireSectionsTranslation,
  isQuestTranslationActive,
  setLanguage
};

export const adminLocalizationKeys = {
  getAvailableLanguages: 'adminLocalizationApi.getAvailableLanguages',
  getAllQuestionnaires: 'adminLocalizationApi.getAllQuestionnaires',
  getQuestionnaireSections: 'adminLocalizationApi.getQuestionnaireSections',
  getAllQuestionnairesTranslation: 'adminLocalizationApi.getAllQuestionnairesTranslation',
  getQuestionnaireSectionsTranslation: 'adminLocalizationApi.getQuestionnaireSectionsTranslation',
  isQuestTranslationActive: 'adminLocalizationApi.isQuestTranslationActive'
};
