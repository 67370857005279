export const VALID_TILL_CANCEL_OPTION = 'Good till cancel';
export const VALID_TILL_DATE_OPTION = 'Good till date';
export const VALID_TILL_DAY_OPTION = 'Day';

export const VALID_TILL_OPTIONS = [
  VALID_TILL_CANCEL_OPTION,
  VALID_TILL_DATE_OPTION,
  VALID_TILL_DAY_OPTION
];

export const ORDER_TYPES_LIST = ['TRADE'];

export const ORDER_STATUS_TABS = ['All orders', 'Processed orders', 'Current orders'];

export const ORDER_TAB_STATUSES = {
  'All orders': null,
  'Processed orders': ['WORKING', 'PARTIALLY_EXECUTED'],
  'Current orders': [
    'CANCELED',
    'REJECTED',
    'EXPIRED',
    'EXECUTED',
    'EXECUTED_DEAL',
    'IN_PROCESS_DEAL'
  ]
};

export const MIFID_STATUSES = ['EXECUTION'];

export const ORDERS_ON_PAGE = 5;
