import { message } from 'antd';
import React, { useEffect } from 'react';

export const Toast = ({ toast }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const info = () => {
    messageApi.info(toast);
  };

  useEffect(() => {
    if (toast) {
      try {
        info();
      } catch (e) {
        console.log(e);
      }
    }
  }, [toast]);

  return <>{contextHolder}</>;
};

