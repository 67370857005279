import React, { useContext, useState } from 'react';
import { Modal, Typography, Input, Button, Popconfirm } from 'antd';
import { MessageContext } from '../../../../app/MessageProvider';
import { queryClient } from '../../../../app/QueryClientProvider';
import { Container } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router-dom";
import {adminReferralApi, adminReferralApiKeys} from "../../../../api";

export const SetAgentModal = ({ open, onClose, isFirst }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const messageApi = useContext(MessageContext);

  const makeRequest = async () => {
    if (code) {
      try {
        await adminReferralApi.setReferralCodeAgent({ investorId: +id, referralCodeAgent: code });

        await queryClient.refetchQueries({queryKey: [adminReferralApiKeys.getAgentsList]});
        messageApi.success(t('Request is sent'));
        setCode('');
        onClose();
      } catch (e) {
        console.error(e);
        messageApi.error(t('Something went wrong'));
      }
    } else {
      messageApi.warning(t('Please enter referral code'));
    }
  };

  return (
    <Modal open={open} onCancel={onClose} title={t('Set agent')} okText={t('Make request')}
           footer={[
             <Button key="back" onClick={onClose}>
               {t("Cancel")}
             </Button>,
             <Popconfirm
               title={t("Set agent")}
               description={t("Are you sure you want to set the new agent?")}
               okText={t("Yes")}
               cancelText={t("No")}
               onConfirm={makeRequest}
               disabled={isFirst}
             >
               <Button type="primary" onClick={isFirst && makeRequest}>
                 {t('Set')}
               </Button>
             </Popconfirm>,
           ]}>
    <Container marginBottom={4}>
        <Typography.Text>{t('Set agent request')}</Typography.Text>
      </Container>
      <Input
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder={t('Enter agent code')}
      />
    </Modal>
  );
};
