import React, { useState } from 'react';
import styled from 'styled-components';
import { Row } from '../../layout';
import { withTranslation } from 'react-i18next';
import {default as PhoneInputWithCountry} from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css'
import { Body4 } from '../../typography';

export const PhoneInput = withTranslation()((
  {
    t,
    value,
    setValue,
    marginBottom,
    marginTopLabel,
    label = t('Phone number'),
    required,
    disabled,
    error,
    name,
    control
  }) => {

    return (
      <div style={{ textAlign: 'left' }}>
        <Row flexDirection='column' marginTop={marginTopLabel} marginBottom={marginBottom}>
          <Label>{label}</Label>
          <PhoneInputWithCountry
            international
            name={name}
            control={control}
            rules={{ required: required }}
            disabled={disabled}
            value={value}
            onChange={setValue}/>
        </Row>
        {error && (
          <Body4 marginTop={8} color={'#FF424F'}>
            {t('Required')}
          </Body4>
        )}
      </div>
    );
  }
);

const Label = styled.label`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 150%;
  color: #27272a;
  margin-bottom: 8px;
  position: relative;
  
  &::after {
    display: inline-block;
    content: '*';
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    margin-left: 6px;
  }
`;