import React from 'react';
import { history } from '../../../routing';
import styled from 'styled-components';
import { Body2 } from '../../../ui';
import { COLORS } from '../../../utils/static';

const MoreBtn = ({ link }) => {
  return (
    <MoreButton onClick={() => history.push(link)}>
      <Body2 color={COLORS.aragonBlue}>More</Body2>
    </MoreButton>
  );
};

export default MoreBtn;

const MoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 1px;

  :hover {
    background: #e9f0f5;
  }
`;
