import React from 'react';
import { Radio as AntdRadio } from 'antd';
import styled from 'styled-components';

export const Radio = ({ children, ...props }) => {
  return <StyledAntdRadio {...props}>{children}</StyledAntdRadio>;
};

const StyledAntdRadio = styled(AntdRadio)`
  &&& {
    font-weight: 400 !important;
  }
  ${({ disabled }) =>
    !disabled
      ? `
  &:hover {
    span.ant-radio > span {
      border: 2px solid #4797ff !important;
    }
    span.ant-radio-checked > .ant-radio-inner {
      border-width: 1px !important;
      border-color: #4797ff !important;
    }
  }
  .ant-radio-inner::after {
    background-color: #4797ff !important;
  }
  span.ant-radio-checked  span.ant-radio-inner {
    background-color: #fff !important;
    border-color: #c3c6c9 !important;
  }
  span.ant-radio-checked .ant-radio-inner {
    border-width: 1px;
    border-color: #4797ff !important;
  }
`
      : `
      .ant-radio-inner {
        border-color: #C2C2C2 !important;
        background-color: #F5F5F6 !important;
      }
      .ant-radio-inner::after {
    background-color: #C2C2C2 !important;
  }
      `}
`;
