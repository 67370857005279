import React from 'react';
import { DateRangePicker as AragonDRP } from '@aragon/ui';
import styled from 'styled-components';
import { Container, Label2 } from '../../../ui';

export const DateRangePicker = ({ label, ...props }) => {
  return (
    <DatePickerWrapper>
      {label && <Label2>{label}</Label2>}
      <AragonDRP {...props} />
    </DatePickerWrapper>
  );
};

const DatePickerWrapper = styled(Container)`
  button {
    width: 100%;
    & > div {
      width: 100%;

      * {
        font-weight: 300;
      }
    }
  }
`;
