import React, { useEffect, useState } from 'react';
import { BackButton, LinkButton, Container, IconView, Row } from '../../../ui';
import { RegContainer, Button } from '../../../components';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../modules/kycModule/components';
import { authApi } from '../../../api';
import { useSelector } from 'react-redux';
import { PASSWORD_REGEX } from '../../../utils/static';
import { history } from '../../../routing';
import { useTranslation } from 'react-i18next';
import { Form, message, Typography } from 'antd';


export const ResetPassword = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const { getValues, control, handleSubmit } = useForm();
  const [codeSentEmail, setCodeSentEmail] = useState((user && user.email) || '');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (user && user.email) {
      sendCode({ email: user.email });
    }
  }, []);

  const sendCode = async (data) => {
    try {
      if (user && user.email) {
        await authApi.changePasswordSendCode(data.email);
      } else {
        await authApi.resetPasswordSendCode(data.email);
      }
      setCodeSentEmail(data.email);
      messageApi.open({
        type: 'success',
        content: user ? t('Code sent to your email') : t('Code sent'),
      });
    } catch (e) {
      if (e?.response?.data?.message === 'INVESTOR_NOT_FOUND') {
        messageApi.open({
          type: 'error',
          content: t('Account not found'),
        });
      } else {
        messageApi.open({
          type: 'error',
          content: t('Something went wrong'),
        });
      }
    }
  };

  const resetPassword = async (data) => {
    try {
      if (user && user.email) {
        await authApi.changePassword({
          code: data.code,
          newPassword: data.password
        });
      } else {
        await authApi.resetPassword({
          email: codeSentEmail,
          code: data.code,
          newPassword: data.password
        });
      }
      messageApi.open({
        type: 'success',
        content: user ? t('Password successfully changed') : t('Password successfully reset'),
      });
      history.push(user ? '/profile' : '/login');
    } catch (e) {
      if (e?.response?.data?.message === 'CONFIRM_CODE_IS_WRONG') {
        messageApi.open({
          type: 'error',
          content: t('Wrong code'),
        });
      } else {
        messageApi.open({
          type: 'error',
          content: t('Something went wrong'),
        });
      }
    }
  };


  return (
    <RegContainer type='centered'>
      <Container marginBottom={48} style={{ textAlign: 'left' }}>
        <BackButton onClick={() => history.goBack()} />
      </Container>

      <Typography.Title level={2} style={{ textAlign: 'center', fontWeight: 600 }}>
        {user ? t('Change password') : t('Reset password')}
      </Typography.Title>

      {!codeSentEmail && <Form layout='vertical'>
        <FormInput
          label={t('E-mail')}
          form={{ name: 'email', control, rules: { required: t('Required') } }}
          textTransform={'lowercase'}
          marginTopLabel={24}
        />

        <Row justifyContent='center' marginTop={24}>
          <Button
            type='filled'
            size='large'
            text={t('Send code')}
            onClick={() => handleSubmit(sendCode)()}
          />
        </Row>
      </Form>}

      {codeSentEmail && <div>
        {!user && <Container style={{ textAlign: 'left' }} marginBottom={16}>
          <LinkButton onClick={() => setCodeSentEmail('')}>
            {t('Change account')}
          </LinkButton>
        </Container>}

        <Form layout='vertical' >
          <FormInput
            label={t('Code')}
            form={{ name: 'code', control, rules: { required: t('Required') } }}
            marginBottom={16}
          />
          <FormInput
            label={t('New password')}
            form={{
              name: 'password',
              control,
              rules: {
                required: t('Required'),
                validate: (v) => PASSWORD_REGEX.test(v) || t('At least 8 characters, both numbers and latin symbols in upper- and lowercase.'),
                deps: ['repeatPassword']
              }
            }}
            type={showPassword ? 'text' : 'password'}
            icon={<IconView onClick={() => setShowPassword(!showPassword)} />}
            marginTopLabel={24}
          />
          <FormInput
            label={t('Repeat password')}
            form={{
              name: 'repeatPassword',
              control,
              rules: {
                required: t('Required'),
                validate: (v) => getValues('password') === v || t('Passwords must match')
              }
            }}
            type={showPassword2 ? 'text' : 'password'}
            icon={<IconView onClick={() => setShowPassword2(!showPassword2)} />}
            marginTopLabel={24}
          />

          <Row justifyContent='center' marginTop={24}>
            <Button
              type='filled'
              size='large'
              text={user ? t('Change password') : t('Reset password')}
              onClick={() => handleSubmit(resetPassword)()}
            />
          </Row>
        </Form>
      </div>}
      {contextHolder}
    </RegContainer>
  );
};
