import React from 'react';
import { Row, Col } from '../../../../ui';
import { AppSideBar } from '../../../index';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

export const AppContainer = ({ hideAppContainer = false, children }) => {
  const { userRole } = useSelector((state) => state.user);

  if (userRole) {
    return (
      <Container>
        <div>
          <Row wrap={'nowrap'}>
            {!hideAppContainer && (userRole === 'admin' || userRole === 'superAdmin') && (
              <Col auto>
                <AppSideBar userRole={userRole} />
              </Col>
            )}

            <Col
              paddingX={
                !hideAppContainer && (userRole === 'admin' || userRole === 'superAdmin') ? 20 : 0
              }
            >
              {children}
            </Col>
          </Row>
        </div>

        {/* <Footer /> */}
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
