import React from 'react';
import { RegLoginRoutes } from './RegLoginRouter';
import { AppContainer, RegContainer } from '../components/common/organisms';
import { InvestorRoutes } from './InvestorRouter';
import { AdminRoutes } from './AdminRouter';

export const RootRouter = ({ routerMode, hideAppContainer }) => {
  if (routerMode === 'reg') {
    return (
      <RegContainer>
        <RegLoginRoutes />
      </RegContainer>
    );
  }

  return (
    <AppContainer hideAppContainer={hideAppContainer}>
      {routerMode === 'investor' && <InvestorRoutes />}
      {routerMode === 'admin' && <AdminRoutes />}
      {routerMode === 'superAdmin' && <AdminRoutes />}
    </AppContainer>
  );
};
