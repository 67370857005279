import React, { useState } from 'react';
import { Table } from '..';
import { Row } from '../../../ui';
import { ButtonSortQuestionnaries } from '../ButtonSortQuestionnaries';
import { useTranslation } from 'react-i18next';

export const TableQuestionnaires = ({ data, getFuncFromObj }) => {
  const [activeTab, setActiveTab] = useState('new');
  const { t } = useTranslation();

  return (
    <>
      <Row gap={24} marginBottom={32} alignCenter>
        <ButtonSortQuestionnaries
          text={t('NEW')}
          amount={data.new.length > 0 && data?.new.length}
          active={activeTab === 'new'}
          onClick={() => setActiveTab('new')}
        />
        <ButtonSortQuestionnaries
          text={t('PASSED')}
          amount={data.passed.length > 0 && data?.passed.length}
          active={activeTab === 'passed'}
          onClick={() => setActiveTab('passed')}
        />
        <ButtonSortQuestionnaries
          text={t('IN REVIEW')}
          amount={data.inProgress.length > 0 && data?.inProgress.length}
          active={activeTab === 'inProgress'}
          onClick={() => setActiveTab('inProgress')}
        />
        <ButtonSortQuestionnaries
          text={t('REJECTED')}
          amount={data.rejected.length > 0 && data?.rejected.length}
          active={activeTab === 'rejected'}
          onClick={() => setActiveTab('rejected')}
        />
        <ButtonSortQuestionnaries
          text={t('DRAFT')}
          amount={data.draft.length > 0 && data?.draft.length}
          active={activeTab === 'draft'}
          onClick={() => setActiveTab('draft')}
        />
        <ButtonSortQuestionnaries
          text={t('Verification')}
          amount={null}
          active={activeTab === 'verification'}
          onClick={() => setActiveTab('verification')}
        />
      </Row>

      <Table data={data[activeTab]} isVerification={activeTab === 'verification'} getFuncFromObj={getFuncFromObj} />
    </>
  );
};
