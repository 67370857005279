import React from 'react';
import { FormInput } from '../../components';
import { EMAIL_REGEX } from '../../../../utils/static';
import { useTranslation } from 'react-i18next';

export const EmailInput = ({ form, ...props }) => {
  const { t } = useTranslation();
  const { rules, ...otherForm } = form;
  return (
    <FormInput
      form={{
        ...otherForm,
        rules: { ...rules, pattern: { value: EMAIL_REGEX, message: t('Invalid e-mail address') } }
      }}
      label={'E-mail'}
      textTransform={'lowercase'}
      type={'email'}
      // icon={isEmailValid ?  <IconCheck /> : <IconCross hide={isEmailValid === null}/>}
      {...props}
    />
  );
};
