import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';
import { Progress, SyncIndicator } from '../../../components';
import { Col, Container, Row, Block } from '../../../ui';
import { SHOWED_SECTIONS_COUNT } from '../../../utils/static/kyc';
import { history } from '../../../routing';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import { MessageContext } from '../../../app/MessageProvider';
import { queryClient } from '../../../app/QueryClientProvider';
import { adminKYCApi } from '../../../api';
import { AddQuestionModal } from '../../../components/admin/modals/AddQuestion';
import { Button as CustomButton } from '../../../components';
import arrowBackIcon from '../../../assets/icons/arrow_back.svg';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';

export const EditQuestionnaire = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [addQuestionOpened, setAddQuestionOpened] = useState(false);
  const [selectedQuestionGroup, setSelectedQuestionGroup] = useState('');

  const { data: languages } = useQuery({
    queryKey: adminLocalizationKeys.getAvailableLanguages,
    queryFn: adminLocalizationApi.getAvailableLanguages,
    select: data1 => data1.data
  });

  const [selectedLanguage, setSelectedLanguage] = useState(
    languages?.find((i) => i.code === i18n.language.toUpperCase()).id
  );

  const messageApi = useContext(MessageContext);

  const { data: allQuestionnaires } = useQuery({
    queryKey: adminLocalizationKeys.getAllQuestionnaires,
    queryFn: adminLocalizationApi.getAllQuestionnaires,
    select: data1 => data1.data
  });
  const { data: allQuestionnairesTranslated } = useQuery({
    queryKey: [adminLocalizationKeys.getAllQuestionnairesTranslation, selectedLanguage],
    queryFn: () => adminLocalizationApi.getAllQuestionnairesTranslation(selectedLanguage),
    select: data1 => data1.data
  });
  const { data } = useQuery({
    queryKey: adminLocalizationKeys.getQuestionnaireSections,
    queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: id }),
    select: data1 => data1.data
  });
  const { data: questionnaireTranslation } = useQuery({
    queryKey: [adminLocalizationKeys.getQuestionnaireSectionsTranslation, selectedLanguage],
    queryFn: () => adminLocalizationApi.getQuestionnaireSectionsTranslation({
      questionSectionTypeId: id,
      languageId: selectedLanguage
    }),
    select: data1 => data1.data
  });
  const { data: isActive } = useQuery({
    queryKey: [adminLocalizationKeys.isQuestTranslationActive, selectedLanguage],
    queryFn: () => adminLocalizationApi.isQuestTranslationActive({
      questionSectionTypeId: id,
      languageId: selectedLanguage
    }),
    select: data1 => data1.data
  });

  const [currentSection, setCurrentSection] = useState(0);

  const currentQuestTranslated = allQuestionnairesTranslated?.find(quest => quest.id === id);

  const editQuestionnaire = async ({ questId, questionSectionId, questionGroupId, questionId, availableAnswerId, value, prevValue }) => {
    if (value !== prevValue || !prevValue) {
      setLoading(true);
      let initialQuestionnaire = [...questionnaireTranslation];
      let initialCurrentQuestTranslated = {...currentQuestTranslated};

      if (questId) {
        initialCurrentQuestTranslated.name = value;
      }

      if (questionSectionId) {
        const indexToUpdate = initialQuestionnaire.findIndex(obj => obj.id === questionSectionId);
        if (indexToUpdate !== -1) {
          initialQuestionnaire[indexToUpdate].section = value;
        }
      }

      if (questionGroupId) {
        initialQuestionnaire.every(obj => {
          const groupToUpdate = obj.questionGroups.findIndex(group => group.id === questionGroupId);
          if (groupToUpdate !== -1) {
            obj.questionGroups[groupToUpdate].title = value;
            return false;
          }
          return true;
        });
      }

      if (questionId) {
        initialQuestionnaire.forEach(obj => {
          obj.questionGroups.every(group => {
            const questionToUpdate = group.questions.findIndex(question => question.id === questionId);
            if (questionToUpdate !== -1) {
              group.questions[questionToUpdate].question = value;
              return false;
            }
            return true;
          });
        });
      }

      if (availableAnswerId) {
        initialQuestionnaire.forEach(obj => {
          obj.questionGroups.forEach(group => {
            group.questions.every(question => {
              const answerToUpdate = question.availableAnswers.findIndex(answer => answer.id === availableAnswerId);
              if (answerToUpdate !== -1) {
                question.availableAnswers[answerToUpdate].answer = value;
                return false;
              }
              return true;
            });
          });
        });
      }

      initialCurrentQuestTranslated.questionSections = initialQuestionnaire;

      saveQuestionnaire(initialCurrentQuestTranslated);
    }
  };

  const deleteQuestionnaire = async ({ questionGroupId, questionId, availableAnswerId }) => {
    setLoading(true);
    let initialQuestionnaire = [...questionnaireTranslation];
    let initialCurrentQuestTranslated = {...currentQuestTranslated};

    if (questionGroupId) {
      initialQuestionnaire.every(obj => {
        const groupToUpdate = obj.questionGroups.findIndex(group => group.id === questionGroupId);
        if (groupToUpdate !== -1) {
          delete obj.questionGroups[groupToUpdate];
          return false;
        }
        return true;
      });
    }

    if (questionId) {
      initialQuestionnaire.forEach(obj => {
        obj.questionGroups.every(group => {
          const questionToUpdate = group.questions.findIndex(question => question.id === questionId);
          if (questionToUpdate !== -1) {
            delete group.questions[questionToUpdate];
            return false;
          }
          return true;
        });
      });
    }

    if (availableAnswerId) {
      initialQuestionnaire.forEach(obj => {
        obj.questionGroups.forEach(group => {
          group.questions.every(question => {
            const answerToUpdate = question.availableAnswers.findIndex(answer => answer.id === availableAnswerId);
            if (answerToUpdate !== -1) {
              delete question.availableAnswers[answerToUpdate].answer;
              return false;
            }
            return true;
          });
        });
      });
    }

    initialCurrentQuestTranslated.questionSections = initialQuestionnaire;
    console.log(initialCurrentQuestTranslated);
    saveQuestionnaire(initialCurrentQuestTranslated);
  };

  const addQuestion = async ({ questionGroupId, title, type }) => {
    setLoading(true);
    let initialQuestionnaire = [...questionnaireTranslation];
    let initialCurrentQuestTranslated = {...currentQuestTranslated};

    if (type === 'INPUT') {
      initialQuestionnaire.every(obj => {
        const groupToUpdate = obj.questionGroups.findIndex(group => group.id === questionGroupId);
        if (groupToUpdate !== -1) {
          obj.questionGroups[groupToUpdate].questions.push({
            "id": uuidv4(),
            "question": title,
            "type": type,
            "isRequired": false,
            "visibleConditions": null,
            "notRequiredConditions": null,
            "visibleOperator": "AND",
            "autoInsert": null,
            "sortOrder": "0"});
          return false;
        }
        return true;
      });
    }

    initialCurrentQuestTranslated.questionSections = initialQuestionnaire;
    console.log(initialCurrentQuestTranslated);
    saveQuestionnaire(initialCurrentQuestTranslated);
  };

  const saveQuestionnaire = async (quest) => {
    try {
      await adminKYCApi.changeQuestionnaire(quest);
      setLoading(false);
      queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSectionsTranslation] }, { queryKey: [adminLocalizationKeys.getAllQuestionnairesTranslation] });
      messageApi.success(t('Saved'));
    } catch (e) {
      setLoading(false);
      messageApi.error(e);
      console.error(e);
    }
  }

  if (!data) return t('Loading') + '...';

  return (
    <Row nowrap>
      <Col>
        <MainContainer paddingY={64} paddingRight={23} paddingBottom={'0'} paddingLeft={15}>
          <div>
            <Row cols={2} gap={16} nowrap marginBottom={24}>
              <Col>
                <Block width={100}>
                  <CustomButton
                    type='text'
                    size='large'
                    text='Back'
                    icon={<img src={arrowBackIcon} alt='arrow-back' />}
                    onClick={() => history.push('/editor')}
                  />
                </Block>
              </Col>
              {currentQuestTranslated && (
                <Typography.Title
                  editable={
                    selectedLanguage
                      ? {
                          onChange: (value) =>
                            editQuestionnaire({
                              questId: currentQuestTranslated.id,
                              value: value,
                              prevValue: currentQuestTranslated.name
                            })
                        }
                      : false
                  }
                  level={4}
                  disabled={!selectedLanguage}
                  style={{ margin: 0 }}
                >
                  {currentQuestTranslated.name}
                </Typography.Title>
              )}
            </Row>

            <Progress steps={data.length} currentStep={currentSection + 1} />

            <Row cols={1} gap={16} nowrap marginTop={20}>
              <RenderQuestionnaire
                questionnaire={questionnaireTranslation}
                currentSection={currentSection}
                selectedLanguage={selectedLanguage}
                editQuestionnaire={editQuestionnaire}
                deleteQuestionnaire={deleteQuestionnaire}
                setAddQuestionOpened={setAddQuestionOpened}
                setSelectedQuestionGroup={setSelectedQuestionGroup}
              />
            </Row>
          </div>

          <StickyBox paddingY={32} paddingX={40}>
            <Row gap={24} justifyContent='space-between'>
              <Block width={124}>
                <Button
                  size='large'
                  onClick={() => setCurrentSection(currentSection - 1)}
                  disabled={currentSection === 0}
                >
                  {t('Back')}
                </Button>
              </Block>

              <Block width={124}>
                <Button
                  type='primary'
                  size='large'
                  onClick={() => setCurrentSection(currentSection + 1)}
                  disabled={currentSection + 1 >= data.length}
                >
                  {t('Next')}
                </Button>
              </Block>
            </Row>
          </StickyBox>
        </MainContainer>
      </Col>
      <SyncIndicator loading={loading} text={`${t('Processing')}...`} />
      <AddQuestionModal
        open={addQuestionOpened}
        onClose={() => setAddQuestionOpened(false)}
        addQuestion={addQuestion}
        questionGroupId={selectedQuestionGroup}
      />
    </Row>
  );
};

const RenderQuestionnaire = ({ questionnaire, currentSection, editQuestionnaire, deleteQuestionnaire, setAddQuestionOpened, setSelectedQuestionGroup }) => {
  return (
    <div>
      {questionnaire &&
        questionnaire
          .slice(currentSection, currentSection + SHOWED_SECTIONS_COUNT)
          .map((sectionObj) => (
            <div key={sectionObj.id}>
              <Typography.Title
                editable={{
                  onChange: (value) =>
                    editQuestionnaire({
                      questionSectionId: sectionObj.id,
                      value: value,
                      prevValue: sectionObj.section
                    })
                }}
                level={4}
              >
                {sectionObj.section}
              </Typography.Title>

              <Container paddingLeft={8}>
                {sectionObj.questionGroups?.map((questionGroup) => (
                  <Container paddingLeft={8}>
                    {questionGroup.title && (
                      <Row>
                        <div
                          onClick={() =>
                            deleteQuestionnaire({
                              questionGroupId: questionGroup.id
                            })
                          }
                        >
                          <DeleteIcon />
                        </div>
                        <Typography.Title
                          editable={{
                            onChange: (value) =>
                              editQuestionnaire({
                                questionGroupId: questionGroup.id,
                                value: value,
                                prevValue: questionGroup.title
                              })
                          }}
                          level={4}
                        >
                          {questionGroup.title}
                        </Typography.Title>
                      </Row>
                    )}

                    {questionGroup.questions.map((question) => (
                      <Container paddingLeft={16}>
                        <Row>
                          <>
                            {question.question && (
                              <Row>
                                <div
                                  onClick={() =>
                                    deleteQuestionnaire({
                                      questionId: question.id
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                                <Typography.Title
                                  editable={{
                                    onChange: (value) =>
                                      editQuestionnaire({
                                        questionId: question.id,
                                        value: value,
                                        prevValue: question.question
                                      })
                                  }}
                                  level={5}
                                >
                                  {question.question}
                                </Typography.Title>
                              </Row>
                            )}
                          </>
                          <Typography.Text> ({question.type})</Typography.Text>
                        </Row>

                        {question.availableAnswers.map((answer) => (
                          <Container paddingLeft={16}>
                            <Row>
                              <div
                                onClick={() =>
                                  deleteQuestionnaire({
                                    availableAnswerId: answer.id
                                  })
                                }
                              >
                                <DeleteIcon />
                              </div>
                              <Typography.Text
                                editable={{
                                  onChange: (value) =>
                                    editQuestionnaire({
                                      availableAnswerId: answer.id,
                                      value: value,
                                      prevValue: answer.answer
                                    })
                                }}
                              >
                                {answer.answer}
                              </Typography.Text>
                            </Row>
                          </Container>
                        ))}
                        {question.availableAnswers && question.availableAnswers.length > 0 && (
                          <AddAnswerBlock>
                            <div>
                              <AddIcon size='small' />
                              <Typography.Text>Add answer</Typography.Text>
                            </div>
                          </AddAnswerBlock>
                        )}
                      </Container>
                    ))}
                    <AddQuestionBlock>
                      <div
                        onClick={() => {
                          setAddQuestionOpened(true);
                          setSelectedQuestionGroup(questionGroup.id);
                        }}
                      >
                        <AddIcon />
                        <Typography.Text>Add question</Typography.Text>
                      </div>
                    </AddQuestionBlock>
                  </Container>
                ))}
                <AddQuestionGroupBlock>
                  <div>
                    <AddIcon />
                    <Typography.Text>Add question group</Typography.Text>
                  </div>
                </AddQuestionGroupBlock>
              </Container>
            </div>
          ))}
    </div>
  );
};

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;
const StickyBox = styled(Container)`
  background: #ffffff;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
  position: sticky;
  bottom: 0;
  margin: 40px -23px 0 -15px;
`;
const DeleteIcon = styled(DeleteOutlined)`
  color: #006fff;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    opacity: 0.6;
  }
`;
const AddBlock = styled(Row)`
  margin-top: 3px;
  margin-left: 16px;
  padding-top: 3px;
  border-top: 1px solid #cccccc;

  & > div {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;
const AddAnswerBlock = styled(AddBlock)`
  margin-top: 3px;
  margin-bottom: 8px;
  padding-top: 3px;
`;
const AddQuestionBlock = styled(AddBlock)`
  margin-top: 3px;
  margin-bottom: 12px;
`;
const AddQuestionGroupBlock = styled(AddBlock)`
  margin-top: 8px;
  margin-bottom: 12px;
`;
const AddIcon = styled(PlusSquareOutlined)`
  color: #08bee5;
  cursor: pointer;
  margin-right: 10px;
`;