import React from 'react';
import {Row, Body2Light, Col} from '../../../../ui';
import { COLORS } from '../../../../utils/static';

export const BoxLine = ({ title = '', data = null, children, TextStyle = Body2Light }) => {
  return (
    <Row alignCenter justifyContent={'space-between'} margin={-4} paddingY={8} nowrap>
      <TextStyle color={COLORS.greyMedium}>{title}</TextStyle>
      <Col auto style={{textAlign: 'right'}}>
        {(data && <TextStyle>{data}</TextStyle>) || children}
      </Col>
    </Row>
  );
};
