import React, { useContext } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useQuery } from 'react-query';
import { investorApi, investorKeys, investorReferralApi } from '../../../../api/investor';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MessageContext } from '../../../../app/MessageProvider';

let generated = false;

async function generateCode(refetch) {
  try {
    generated = true;
    await investorApi.generateMyRefferalCode();
    if (refetch) refetch();
  } catch (e) {
  }
}


export const AddReferralModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { investor } = useSelector(state => state);
  const messageApi = useContext(MessageContext);

  const requestCode = async () => {
    try {
      await investorReferralApi.requestRefferalCode(investor.accountRole);
      messageApi.success(t('Request is sent'));

      onClose();
    } catch (e) {
      messageApi.error(t('Something went wrong'));
      console.error(e);
    }
  };

  return (
    <Modal open={open} onCancel={onClose} title={t('Add referral')} footer={null}>
      {investor.referralCode && <div>
        {t('Share this code with your referral')} <Typography.Text copyable code>{investor.referralCode}</Typography.Text>
      </div>}

      {!investor.referralCode && <div>
        <Typography.Paragraph>{t("You don't have referral code yet")}</Typography.Paragraph>
        <Typography.Paragraph strong>{t('Request referral code')}</Typography.Paragraph>

        <Button onClick={requestCode} type={'primary'}>
          {t('Make request')}
        </Button>
      </div>}
    </Modal>
  );
};
