import React, { useCallback, useMemo, useState } from 'react';
import { Input, Checkbox, Button, Text } from '../../../components';
import styled from 'styled-components';
import {
  Link,
  Row,
  Block,
  Container,
  BREAKPOINTS
} from '../../../ui';
import { userActions } from '../../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { newObject } from '../../../utils/helpers';
import { resendEmailCode, investorApi } from '../../../api';
import { EMAIL_REGEX, COLORS } from '../../../utils/static';
import { Form, message, Typography } from 'antd';
import arrowBackIcon from '../../../assets/icons/arrow_back.svg';
import { useTranslation } from 'react-i18next';

export const LoginViaEmail = ({ history }) => {
  const [codeSent, setCodeSent] = useState(false);

  return (
    <StyledMainRow flexDirection={'column'} paddingY={120} alignCenter justifyContent={'center'} paddingX={16}>
      <StyledContainer>
        <Button
          size='large'
          type='text'
          text='Back'
          icon={arrowBackIcon}
          onClick={() => history.goBack()}
        />
      </StyledContainer>
      {codeSent ? <VerifyEmail /> : <InsertData goNext={() => setCodeSent(true)} />}
    </StyledMainRow>
  );
};

const InsertData = ({ goNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveAccount, setSaveAccount] = useState(false);
  const [data, setData] = useState({ email: '', password: '' });
  const [emailError, setEmailError] = useState('');
  const [isEmailFound, setIsEmailFound] = useState(null);
  const [emailValidated, setEmailValidated] = useState(false);

  const onChangeData = (key, value, data) => {
    setData(newObject(data, key, value));
  };

  const onChangeEmail = useCallback(
    (() => {
      let timer;

      return (...args) => {
        onChangeData(...args);

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          validateEmail(args[1]);
        }, 800);
      };
    })(),
    []
  );

  const checkValidation = () => {
    return new Promise((resolve, reject) => {
      if (data.email) {
        investorApi.phoneEmailValidation(null, data.email.toLowerCase()).then((res) => {
          if (res.status === 200) {
            const { data } = res;

            if (data.validEmail !== undefined) {
              setIsEmailFound(!data.validEmail);
              data.validEmail ? reject() : resolve();
            }
          }
        });
      } else {
        setIsEmailFound(null);
        reject();
      }
    });
  };

  const validateEmail = (email) => {
    const validEmail = EMAIL_REGEX.test(email.toLowerCase());

    !validEmail ? setEmailError('Invalid e-mail address') : setEmailError('');

    if (!emailValidated) setEmailValidated(true);
  };

  const handleNext = async () => {
    try {
      await checkValidation();

      dispatch(
        userActions.auth(
          {
            ...data,
            email: data.email.toLowerCase()
          },
          goNext
        )
      );
    } catch (e) {
      console.error(e);
    }
  };

  const disableNext = useMemo(() => {
    return !emailValidated || !data.email || !data.password || emailError;
  }, [emailValidated, data.email, data.password, emailError]);

  return (
    <Block maxWidth={380} style={{ width: '100%' }}>
      <Typography.Title level={2} style={{ textAlign: 'center', fontWeight: 600 }}>
        {t('Log in to start your session')}
      </Typography.Title>

      <Form layout='vertical' >
        <Container marginTop={60}>
          <Input
            required
            label={t('E-mail')}
            value={data.email}
            onChange={(e) => onChangeEmail('email', e.target.value, data)}
            error={emailError}
          />

          <Input
            marginTopLabel={24}
            required
            label={t('Password')}
            value={data.password}
            onChange={(e) => onChangeData('password', e.target.value, data)}
            password
          />

          <Row alignCenter justifyContent={'space-between'} marginTop={12}>
            <Container marginBottom={2} paddingLeft={2}>
              <Checkbox checked={saveAccount} onChange={() => setSaveAccount(!saveAccount)}>
                <Text>{t('Remember me')}</Text>
              </Checkbox>
            </Container>

            <Block marginRight={'-16'}>
              <Link to={'/reset-password'}>
                <Button type='text' size='large' text={t('Forgot password')} />
              </Link>
            </Block>
          </Row>
        </Container>

        <Container marginTop={28}>
          <Button
            type='filled'
            size='large'
            text={t('Log in')}
            onClick={handleNext}
            disabled={disableNext}
          />
        </Container>
      </Form>

      <Row alignCenter justifyContent={'center'} marginTop={40} wrap>
        <Text color={COLORS.greyMedium} fontSize={18}>
          {t('Don’t have an account yet?')}
        </Text>

        <Block marginLeft={'-8'}>
          <Link to={'/registration/investor'}>
            <Button type='text' size='large' text={t('Sign up')} />
          </Link>
        </Block>
      </Row>
    </Block>
  );
};

const VerifyEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.user);
  const [code, setCode] = useState('');

  const handleSendCode = () => {
    resendEmailCode(email).then(console.log);
  };

  const handleNext = () => {
    dispatch(userActions.setTokenByEmail({ email: email, code: code }));
  };

  return (
    <div>
      <Typography.Title level={2}>{t('Verify your account')}</Typography.Title>

      <Container marginTop={24}>
        <Text fontSize={18}>{t('The verification code has been sent on your e-mail:')}</Text>
      </Container>

      <Form layout='vertical'>
        <Container marginTop={48}>
          <Input
            label={t('Verification code')}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type={'number'}
          />
        </Container>
        {/* <Input
        label={'Verification code'}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        type={'number'}
      /> */}

        <Row marginTop={40} marginBottom={48} gap={8}>
          <Text>{t('Didn’t receive the code?')}</Text>

          <Text color={COLORS.aragonBlue} onClick={handleSendCode}>
            {t('Try again')}
          </Text>
        </Row>

        <Button type='filled' size='large' text={t('Next')} onClick={handleNext} />
      </Form>
    </div>
  );
};

const StyledContainer = styled(Container)`
  position: absolute;
  top: 30px;
  left: 60px;
  width: min-content;
  @media (max-width: ${BREAKPOINTS.md}px) {
    top: 15px;
    left: 15px;
  }
`;

const StyledMainRow = styled(Row)`
  min-height: 100vh;
  min-height: 100dvh;
    
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 70px;
  }
`;
