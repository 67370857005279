import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox } from '../../../../ui';
import { Col, Row } from '../../../../ui/layout';
import { Body3Light } from '../../../../ui/typography';
import { COLORS } from '../../../../utils/static';
import { Label } from '../../../../components';

const defaultRowProps = { margin: -31, colsMarginBottom: 8, flexDirection: 'column' };

export const CheckboxGroupForm = ({
  form,
  label,
  question,
  rowProps = defaultRowProps,
  disabled,
  required = false
}) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: []
  });

  const { value, onChange } = field;

  const onChangeField = (checked, id) => {
    let valueCopy = [...value];
    checked ? valueCopy.push(id) : (valueCopy = valueCopy.filter((item) => item !== id));

    onChange(valueCopy);
  };

  return (
    <div>
      {question.question && (
        <Label label={question.question} required={required}>
          {/* <Body2Light marginBottom={12}>
            {question.question}
            {required && <Span color={COLORS.aragonBlue}>&nbsp;*</Span>}
          </Body2Light> */}

          <Row {...rowProps}>
            {question?.availableAnswers?.map((answer, key) => {
              return (
                <Col auto key={key}>
                  <Checkbox
                    label={answer.answer}
                    checked={value.includes(answer.id)}
                    onChange={(checked) => onChangeField(checked, answer.id)}
                    disabled={disabled}
                  />
                </Col>
              );
            })}
          </Row>
        </Label>
      )}
      {error && (
        <Body3Light color={COLORS.red} marginTop={12}>
          {error.message}
        </Body3Light>
      )}
    </div>
  );
};
