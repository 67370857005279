import React from 'react';
import { TableRow } from '@aragon/ui';
import { Cell } from '../index';

export const EmptyBody = ({ title }) => {
  return (
    <TableRow>
      <Cell cellProps={{ style: { border: 'none' } }}>{title}</Cell>
    </TableRow>
  );
};
