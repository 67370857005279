import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvestorsTable, TableBoxContainer } from '../../../../components';
import { Row, Col } from '../../../../ui';
import {
  INVESTOR_STATUS_DESCRIPTION,
  INVESTOR_STATUS_TABS,
  IS_IN_REVIEW_TAB_VALUE, LEGAL_STATUS_DESCRIPTION, STATUS_TAB_VALUE
} from '../../../../utils/static';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'antd';

const TAB_DESCR = {
  PERSONAL: INVESTOR_STATUS_DESCRIPTION,
  COMPANY: LEGAL_STATUS_DESCRIPTION
};

export const InvestorsTableBox = (
  {
    investors,
    updateSearchParams,
    INVESTORS_ON_PAGE,
    selectedTab
  }) => {
  const { t } = useTranslation();
  const { type } = useParams();
  const [search, setSearch] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const [accountType, setAccountType] = useState(0);
  const accountTypes = [
    {
      label: t('All types'),
      value: 0,
    },
    {
      label: t('Agents'),
      value: 1,
    },
    { 
      label: t('Assistants'),
      value: 2,
    }];

  useEffect(() => {
    if (search) setSearch('');
    if (selectedPage) setSelectedPage(0);
    refetchInvestors({ searchValue: '' });
  }, [type]);

  const refetchInvestors = ({ searchValue = search, typeValue = type, skip = 0, isAgent = accountType === 1, isRecommender = accountType === 2 }) => {
    const searchParams = {
      search: searchValue,
      accountStatus: STATUS_TAB_VALUE[selectedTab],
      inReview: IS_IN_REVIEW_TAB_VALUE[selectedTab],
      account: typeValue,
      skip: skip
    };
    if (isAgent) searchParams.isAgent = true;
    if (isRecommender) searchParams.isRecommender = true;

    updateSearchParams(searchParams);
  };

  //onUpdateSelectedTab
  useEffect(() => {
    onUpdateSearch();
  }, [selectedTab]);

  //onUpdateSearch
  const onUpdateSearch = () => {
    refetchInvestors({});
    setSelectedPage(0);
  };
  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      onUpdateSearch();
    }
  };

  //onUpdateAccountType
  const onUpdateAccountType = (type) => {
    console.log(type);
    refetchInvestors({ isAgent: type === 1, isRecommender: type === 2 });
    setAccountType(type);
    setSelectedPage(0);
  };

  //onUpdatePage
  const onUpdatePage = (page) => {
    refetchInvestors({ skip: page * INVESTORS_ON_PAGE });
    setSelectedPage(page);
  };

  if (investors) {
    const descriptions = TAB_DESCR[type] || INVESTOR_STATUS_TABS;

    return (
      <TableBoxContainer
        title={t(descriptions[selectedTab])}
        table={
          <InvestorsTable
            data={investors?.investors}
            onChange={onUpdatePage}
            count={investors.count}
          />
        }
        // aside={<ExportButton/>}
        innerBoxProps={{ paddingBottom: 16 }}
      >
        <Row margin={-8} marginY={16}>
          <Col auto minWidth={'304px'}>
            <Input.Search
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              onBlur={onUpdateSearch}
              onKeyDown={handleSearchKeyDown}
              placeholder={t('Search')}
              size='large'
              onSearch={onUpdateSearch}
            />
          </Col>

          <Col auto minWidth={'164px'}>
            <Select options={accountTypes} defaultValue={accountTypes[0]} size='large' onChange={onUpdateAccountType} style={{width: 160}}/>
          </Col>
        </Row>
      </TableBoxContainer>
    );
  }

  return null;
};
