import React, { useEffect } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export const SyncIndicator = ({ loading, text }) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'loading',
      content: text ?? `${t('Loading')}...}`,
      duration: 0,
    });
  };

  useEffect(() => {
    if (loading) {
      success();
    } else {
      messageApi.destroy();
    }
  }, [loading]);


  return (
    <>
      {contextHolder}
    </>
  );
};
