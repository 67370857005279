import React from 'react';
import { Box } from '../../../../components';
import { Row, Col, Input } from '../../../../ui';

export const InvestorTaxInputBox = ({ data, changeData }) => {
  return (
    <Box>
      <Row margin={-8} cols={4}>
        <Col>
          <Input
            label={'TAX residency'}
            value={data.taxResidency}
            onChange={(e) => changeData('taxResidency', e.target.value)}
          />
        </Col>
        <Col width={'50%'}>
          <Input
            label={'TIN NR'}
            value={data.tinNR}
            onChange={(e) => changeData('tinNR', e.target.value)}
          />
        </Col>
      </Row>
    </Box>
  );
};
