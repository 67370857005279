import React from 'react';
import styled from 'styled-components';
import { GU, IconCheck as IconCheckAragon } from '@aragon/ui';

export const IconCheck = ({ onClick, hide = false, size, ...props }) => {
  return (
    <IconCheckWrapper onClick={onClick} hide={hide} size={size} {...props}>
      <IconCheckAragon />
    </IconCheckWrapper>
  );
};

const IconCheckWrapper = styled.div`
  width: ${({ size }) => (size ? `${size}px` : `${4 * GU}px`)};
  min-width: ${({ size }) => (size ? `${size}px` : `${4 * GU}px`)};
  height: ${({ size }) => (size ? `${size}px` : `${4 * GU}px`)};
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  padding: 4px;
  background: #2cc68f;
  color: #ffffff;
  border-radius: 100%;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
