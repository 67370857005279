import React from 'react';
import { Table, TableHeader, TableRow, TableCell, Text } from '@aragon/ui';
import styles from '../style.module.css';
import textStyle from '../../../utils/textStyle';
import styled from 'styled-components';
import { Body3Light, Button } from '../../../ui';

const AssetsAvailableAssetsTable = ({ header, data = [] }) => {
  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const dataItems = data.map((item, key) => (
    <TableRow key={key}>
      <TableCell>
        <Text style={textStyle('body3')}>{item.assetid}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.ticker}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.type}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.issuer}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.issuedate}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.quantity}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle('body3')}>{item.price}</Text>
      </TableCell>
      <TableCell className={`${styles['tableThSm']} ${styles['details']}`}>
        <Button size={'small'} width={'75'}>
          <Body3Light>Details</Body3Light>
        </Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <StyledTable header={<TableRow>{headerItems}</TableRow>} noSideBorders>
      {dataItems}
    </StyledTable>
  );
};

export default AssetsAvailableAssetsTable;

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
  }
`;
