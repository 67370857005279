import axios from 'axios';
import { API_URL } from '../../utils/static/api';

const getInvestors = (params) => {
  return axios.get(`${API_URL}/api/kyc/v1/superAdmin/investors`, { params: params });
};
const uploadDeepCheckReport = ({ checkId, file }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('checkInvestorId', checkId);

  return axios.post(`${API_URL}/api/kyc/v1/superAdmin/checkInvestor/uploadReport`, formData);
};

export const superAdminApi = {
  getInvestors,
  uploadDeepCheckReport
};
