import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { InvestorDocRequest } from '../../containers';
import {
  Home,
  Investors,
  InvestorDetails,
  QuestionnaireAnswers,
  SumsubAnswers,
  Localization,
  TranslateQuestionnaire,
  Editor,
  EditQuestionnaire
} from '../../pages';

export const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact path='/home' component={Home} />
      <Route exact path='/investors/:type' component={Investors} />
      <Route exact path='/investor/:id' component={InvestorDetails} />
      <Route
        exact
        path='/investor/:id/questionnaire/:questionnaireId'
        component={QuestionnaireAnswers}
      />
      <Route exact path='/investor/:id/sumsub-verification' component={SumsubAnswers} />
      <Route
        exact
        path='/investor/:id/documents/request/:requestId'
        component={InvestorDocRequest}
      />
      <Route exact path='/localization' component={Localization} />
      <Route exact path='/localization/:id' component={TranslateQuestionnaire} />

      <Route exact path='/editor' component={Editor} />
      <Route exact path='/editor/:id' component={EditQuestionnaire} />

      <Redirect to={'/investors?type=PERSONAL'} />
    </Switch>
  );
};
