import React from 'react';
import { QuestionGroup } from '../QuestionGroup';
import { Container } from '../../../../ui/layout';

export const Section = ({ section, ...props }) => {
  return (
    <Container>
      {section.questionGroups &&
        section.questionGroups.map((questionGroup, index) => (
          <QuestionGroup questionGroup={questionGroup} key={index} {...props} />
        ))}
    </Container>
  );
};
