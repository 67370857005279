import React from 'react';
import { ProfileDocuments as RenderProfileDocuments, SyncIndicator } from '../../../../components';
import { useQuery } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { useTranslation } from 'react-i18next';

export const ProfileDocuments = () => {
  const { t } = useTranslation();
  const {
    data: requestedDocs,
    refetch,
    isLoading: isLoading1
  } = useQuery(['getRequestedDocuments'], kycApi.getRequestedDocuments, { refetchInterval: 5000 });
  const { data: questsDocs, isLoading: isLoading2 } = useQuery(
    ['getQuestsDocuments'],
    kycApi.getQuestsDocuments,
    { refetchInterval: 5000 }
  );

  return (
    <div>
      {!isLoading1 && !isLoading2 && (
        <RenderProfileDocuments
          documents={requestedDocs?.data}
          questsDocs={questsDocs?.data}
          refetchDocs={refetch}
          investorId={requestedDocs?.data[0].investorId}
        />
      )}

      <SyncIndicator loading={isLoading1 || isLoading2} />
    </div>
  );
};
