import { Box as BoxAragon, GU } from '@aragon/ui';
import styled from 'styled-components';
import { COLORS } from '../../../utils/static/colors';

export const InnerBox = styled.div`
  padding: ${(p) => (p.padding ? `${p.padding}px` : `${3 * GU}px`)};
  border-bottom: 1px solid ${COLORS.greyBasic};

  ${({ paddingX }) =>
    paddingX
      ? `
    padding-left: ${paddingX}px;
    padding-right: ${paddingX}px;
  `
      : ``}

  ${({ paddingY }) =>
    paddingY
      ? `
    padding-top: ${paddingY}px;
    padding-bottom: ${paddingY}px;
  `
      : ``} 
  
  ${({ paddingRight }) =>
    paddingRight
      ? `
    padding-right: ${paddingRight}px;
  `
      : ``}
  
  ${({ paddingLeft }) =>
    paddingLeft
      ? `
    padding-left: ${paddingLeft}px;
  `
      : ``}
  
  ${({ paddingTop }) =>
    paddingTop
      ? `
    padding-top: ${paddingTop}px;
  `
      : ``}
  
  ${({ paddingBottom }) =>
    paddingBottom
      ? `
    padding-bottom: ${paddingBottom}px;
  `
      : ``}
  
  ${({ paddingCss }) =>
    paddingCss
      ? `
    padding: ${paddingCss};
  `
      : ``}
  
  &:last-child {
    border-bottom: none;
  }

  ${(p) =>
    p.noBorder
      ? `
    border-bottom: none;
  `
      : ``}
`;

export const Box = styled(BoxAragon)`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px!important` : '0')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0')};
  ${p => p.borderRadius ? `border-radius: ${p.borderRadius}px;` : ''}

  & > h1 {
    padding: ${p => p.headingPaddingY ? `${p.headingPaddingY}px` : '7px'} ${3 * GU}px;
    ${p => p.headingSize ? `font-size: ${p.headingSize}px;` : ''}
    font-weight: 400;
    height: auto;
  }

  ${({ topRadius }) =>
    topRadius
      ? `
    border-top-left-radius: ${topRadius}px;
    border-top-right-radius: ${topRadius}px;
  `
      : ``}

  ${({ paddingX }) =>
    paddingX
      ? `
    > div {
      padding-left: ${paddingX}px;
      padding-right: ${paddingX}px;
    }
  `
      : ``}
  
  ${({ paddingY }) =>
    paddingY
      ? `
    > div {
      padding-top: ${paddingY}px;
      padding-bottom: ${paddingY}px;
    }
  `
      : ``} 
  
  ${({ paddingBottom }) =>
    paddingBottom
      ? `
    > div {
      padding-bottom: ${paddingBottom}px;
    }
  `
      : ``}
  
  ${({ paddingLeft }) =>
    paddingLeft
      ? `
    > div {
      padding-left: ${paddingLeft}px;
    }
  `
      : ``}
  
  ${({ paddingRight }) =>
    paddingRight
      ? `
    > div {
      padding-right: ${paddingRight}px;
    }
  `
      : ``}
    
  ${({ innerPadding }) =>
    innerPadding
      ? `
    ${InnerBox} {
      padding: ${innerPadding}px;
    }
  `
      : ``}
  
  ${({ innerPaddingX }) =>
    innerPaddingX
      ? `
    ${InnerBox} {
      padding-left: ${innerPaddingX}px;
      padding-right: ${innerPaddingX}px;
    }
  `
      : ``}
  
  ${({ innerPaddingY }) =>
    innerPaddingY
      ? `
    ${InnerBox} {
      padding-top: ${innerPaddingY}px;
      padding-bottom: ${innerPaddingY}px;
    }
  `
      : ``}
`;
