import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { BREAKPOINTS, Block, Col, Container, Row } from '../../../ui';
import { ReactComponent as ManIcon } from '../../../assets/icons/man.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/icons/question.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
import { ReactComponent as SettingIcon } from '../../../assets/icons/setting.svg';
import userProfileBg from '../../../assets/user/tabs-bg-profile.png';
import documentsBg from '../../../assets/user/documents-bg.png';
import questionnairesBg from '../../../assets/user/questionnaires-bg.png';
import logoImg from '../../../assets/project_logo_full.png';
import { COLORS } from '../../../utils/static';
import { LanguageInvestor } from '../../common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';

const Tab = ({ tab, currentTab, onClick, children }) => {
  return (
    <StyledRow
      width={'calc(100% - 50px)'}
      nowrap
      gap={12}
      alignCenter
      paddingY={23}
      paddingLeft={16}
      onClick={onClick}
      active={currentTab === tab}
      tab={tab}
    >
      {children}
    </StyledRow>
  );
};

export const Tabs = ({ tab, setTab, menuVisible, setMenuVisible }) => {
  const { investor } = useSelector((state) => state);
  const { languageId } = investor;
  const { t } = useTranslation();

  return (
    <StyledBlock width={284} menuVisible={menuVisible}>
      <Row
        nowrap
        flexDirection={'column'}
        height={'100%'}
        paddingBottom={60}
        justifyContent={'space-between'}
      >
        <BG tab={tab}>
          <Container paddingBottom={40}>
            <Container hidden mdVisible>
              <Row paddingY={20} paddingX={20} justifyContent={'flex-end'}>
                <CloseOutlined style={{ fontSize: 24 }} onClick={() => setMenuVisible(false)} />
              </Row>
            </Container>
            <Img src={logoImg} alt='' />

            <Tab
              currentTab={tab}
              onClick={() => {
                setTab(0);
                setMenuVisible(false);
              }}
              tab={0}
            >
              <ManIcon width={24} height={24} />

              <Typography.Text>{t('Personal data')}</Typography.Text>
            </Tab>

            <Tab
              currentTab={tab}
              onClick={() => {
                setTab(1);
                setMenuVisible(false);
              }}
              tab={1}
            >
              <QuestionIcon width={24} height={24} />

              <Typography.Text>{t('Questionnaires')}</Typography.Text>
            </Tab>

            <Tab
              currentTab={tab}
              onClick={() => {
                setTab(2);
                setMenuVisible(false);
              }}
              tab={2}
            >
              <DocumentIcon width={24} height={24} />

              <Typography.Text>{t('Documents')}</Typography.Text>
            </Tab>
          </Container>
        </BG>

        <Container>
          <StyledRow alignCenter width={'fit-content'} nowrap gap={12}>
            <LanguageInvestor languageId={languageId} />

            {/* <SettingIcon width={24} height={24} />

            <Typography.Text>Settings</Typography.Text> */}
          </StyledRow>
        </Container>
      </Row>
    </StyledBlock>
  );
};

const StyledBlock = styled(Block)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: #f6f9ff;
  height: 100vh;
  @media (max-width: ${BREAKPOINTS.md}px) {
    position: fixed;
    top: 0;
    /* left: -500px; */
    ${({ menuVisible }) => (!menuVisible ? 'left: -500px;' : 'left: 0;')}
    transition: left 0.3s ease-in-out;
  }
`;

const BG = styled.div`
  background-position: top left;
  background-repeat: no-repeat;
  background: ${({ tab }) =>
    tab === 0
      ? `#fff url(${userProfileBg}) no-repeat`
      : tab === 1
      ? `#fff url(${questionnairesBg}) no-repeat`
      : tab === 2
      ? `#fff url(${documentsBg}) no-repeat`
      : ''};

  @media (max-width: ${BREAKPOINTS.md}px) {
    background: unset;
  }
`;

const Img = styled.img`
  margin: 30px 40px 89px;
  width: 204px;
  height: 62px;
`;

const StyledRow = styled(Row)`
  margin-left: 50px;
  &:hover {
    cursor: pointer;
    svg,
    g,
    path {
      stroke: #006fff;
    }
    span {
      color: #006fff;
    }
  }

  ${({ active }) =>
    active
      ? `
      pointer-events: none;
    svg,
    g,
    path {
      font-weight: 700;
      stroke: #006fff;
      stroke-width: 2px;
    }
    span {
      font-weight: 700;
      color: #006fff;
    }
  `
      : ''}

  ${({ tab, active }) =>
    active && tab === 0
      ? `
      svg,
      g,
      path {
        fill: #006fff;
        stroke: initial;
        stroke-width: 2px;
      }

      &:hover {
        svg,
        g,
        path {
          stroke: initial;
          fill: #006fff;
        }
      } 
  `
      : tab === 0
      ? `
      &:hover {
        svg,
        g,
        path {
          stroke: initial;
          fill: #006fff;
        }
      } 
  `
      : ''}
`;
