import React from 'react';
import { InnerBox } from '../../../../ui';
import { Box, BoxLine } from '../../../../components';

export const AddressBox = ({ title = 'Address', data = {}, inner = false, ...props }) => {
  const { country, city, address } = data;

  if (inner) {
    return (
      <InnerBox {...props}>
        <BoxLine title='Country' data={country} />
        <BoxLine title='City' data={city} />
        <BoxLine title='Address' data={address} />
      </InnerBox>
    );
  } else {
    return (
      <Box heading={title} paddingY={16} {...props}>
        <BoxLine title='Country' data={country} />
        <BoxLine title='City' data={city} />
        <BoxLine title='Address' data={address} />
      </Box>
    );
  }
};
