import axios from 'axios';
import { API_URL } from '../../../utils/static/api';

export const getTransactions = (params) => {
  return axios.get(`${API_URL}/api/v1/investor/transactions`, { params: params });
};

export const makeTransfer = (data) => {
  return axios.post(`${API_URL}/api/v1/investor/transfer`, data);
};

export const transferSendCode = (type) => {
  return axios.get(`${API_URL}/api/v1/investor/transferSendSms`, { params: { type: type } });
};

export const submitTransfer = (code) => {
  return axios.post(`${API_URL}/api/v1/investor/submitTransfer`, { code: code });
};

/**
 * makeTransfer data example
 * {
  "type": "WITHDRAWAL",
  "amount": 500,
  "fromPaymentDetailId": "1",
  "toPaymentDetailId": "3",
  "paymentDetails": "paymentDetails",
  "fee": 2.5
}
 */
