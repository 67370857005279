import React from 'react';
import { Box, InnerBox } from '../../../../components';
import { Col, Row, Typography } from 'antd';

export const TableBoxContainer = ({
  title,
  aside,
  table,
  children,
  boxProps,
  innerBoxProps,
  rowProps
}) => {
  return (
    <Box padding={0} {...boxProps}>
      <InnerBox padding={'0'} noBorder {...innerBoxProps}>
        <Row justify={'space-between'} {...rowProps}>
          <Typography.Text>{title}</Typography.Text>

          <Col >{aside}</Col>
        </Row>

        {children}
      </InnerBox>

      {table}

    </Box>
  );
};
