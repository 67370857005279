export const paymentDetailsTemplate = {
  bankAccountScan: null,
  accountName: '',
  bicSwiftCode: '',
  bankName: '',
  bankCode: '',
  country: '',
  city: '',
  address: '',
  correspondentBankForSwift: '',
  ibanAccountNumber: '',
  currency: ''
};

export const idDocumentsTemplate = {
  idDocumentPhoto: null,
  selfieWithIdPhoto: null,
  idDocument: '',
  documentNumber: '',
  personalCode: '',
  birthDate: '',
  issuingState: '',
  issuingOrganization: '',
  dateOfIssuance: '',
  dateOfExpiry: '',
  maritalStatus: '',
  nameSurnameOfSpouse: ''
};

export const addressTemplate = {
  country: '',
  city: '',
  address: ''
};

export const companyInfoTemplate = {
  logo: null,
  name: '',
  type: '',
  email: '',
  website: '',
  rating: ''
};

export const companyRegistrationTemplate = {
  registrationDate: null,
  registrationNumber: '',
  lei: '',
  countryOfRegistration: '',
  taxResidency: '',
  taxNumber: ''
};

export const representativeInfoTemplate = {
  positionInTheCompany: '',
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  phone: ''
};

export const investorInfoTemplate = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  phone: '',
  email: '',
  taxResidency: '',
  tinNR: ''
};
