import React from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

export const Modal = ({ isModalOpen, setIsModalOpen, children }) => {
  return (
    <StyledAntdModal
      centered={true}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      closeIcon={<CloseIcon width={24} height={24} />}
    >
      {children}
    </StyledAntdModal>
  );
};

const StyledAntdModal = styled(AntdModal)`
  .ant-modal-content {
    padding: 60px 42px;
    border-radius: 40px;
  }

  .ant-modal-close {
    top: 28px;
    inset-inline-end: 28px;
    &:hover {
      background-color: unset;
      opacity: 0.6;
    }
  }
`;
