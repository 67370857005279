import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileInfoView } from '../View';
import { investorApi } from '../../../../../api/investor';
import { investorActions } from '../../../../../store/investor';
import { useQuery } from 'react-query';
import { investorKeys } from '../../../../../api/investor';

export const ProfileInfo = () => {
  const dispatch = useDispatch();
  const { investor } = useSelector((state) => state);

  useEffect(() => {
    dispatch(investorActions.getAdditionalInfo());
  }, []);

  return <ProfileInfoView investor={investor} />;
};
