import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { InvestorProfile, ResetPassword } from '../../pages';
import { DocumentRequest } from '../../containers';
import { Questionnaire, SumsubQuestionnaire } from '../../modules';

export const InvestorRoutes = () => {
  return (
    <Switch>
      <Route exact path='/profile' component={InvestorProfile} />
      <Route exact path='/questionnaire/:id' component={Questionnaire} />
      <Route exact path='/sumsub-verification' component={SumsubQuestionnaire} />
      <Route exact path={'/documents/request/:id'} component={DocumentRequest} />

      <Route exact path='/change-password' component={ResetPassword} />

      <Redirect to={'/profile'} />
    </Switch>
  );
};
