import React from 'react';
import styles from '../style.module.css';

const RegContainer = ({ children, type, ...props }) => {
  return (
    <div
      className={type === 'centered' ? styles['regContainerCentered'] : styles['regContainer']}
      {...props}
    >
      {children}
    </div>
  );
};

export default RegContainer;
