import React from 'react';
import styled from 'styled-components';
import { Progress as AntdProgress, Typography } from 'antd';
import { Col, Row } from '../../../../ui';
import successIcon from '../../../../assets/icons/success-standard-line.svg';
import inProccessIcon from '../../../../assets/icons/time-slot.svg';
import lockIcon from '../../../../assets/icons/lock.svg';
import { useTranslation } from 'react-i18next';

export const TierProgress = ({ label = '', progress = 0, disabled, ...props }) => {
  const { t } = useTranslation();

  const statusIcon = disabled ? lockIcon : progress >= 100 ? successIcon : inProccessIcon;

  return (
    <Row nowrap gap={24}>
      <Row nowrap gap={8} marginTop={5}>
        <Img src={statusIcon} alt='' />
        {label && <Text>{t(`${label}`)}</Text>}
      </Row>

      <Col wide>
        <Number lock={disabled}>
          {Math.min(progress, 100)}% <span>/ 100%</span>
        </Number>
        <AntdProgress
          style={{ margin: 0 }}
          {...props}
          showInfo={false}
          size={['default', 10]}
          percent={progress}
        />
      </Col>
    </Row>
  );
};

const Text = styled(Typography.Text)`
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
`;

const Number = styled(Typography.Text)`
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${(p) => (p.lock ? 400 : 600)};
  text-align: end;
  & span {
    color: #b5b5b5;
  }
`;
const Img = styled.img`
  width: 20px;
  height: 20px;
`;
