import React from 'react';
import { history } from '../../../../routing';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

export const BackBar = ({ url = null }) => {
  const { t } = useTranslation();
  const onBack = () => {
    url ? history.push(url) : history.goBack();
  };

  return <div style={{ width: '100%' }}>
    <Button icon={
      <LeftOutlined />
    } onClick={onBack}>
      {t('Back')}
    </Button>
  </div>;
};

const Button = styled(AntdButton)`
  height: 52px;
    padding: 10px 20px;
`;
