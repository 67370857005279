import { Button as AntdButton } from 'antd';
import React from 'react';
import { Button } from '../../ui';

export const TestPage = () => {
  return (
    <div style={{ padding: 20 }}>
      <AntdButton>button</AntdButton>
      <Button>button</Button>
    </div>
  );
};
