import {VenomConnect} from 'venom-connect';
import {ProviderRpcClient} from 'everscale-inpage-provider';
import React, { createContext, useEffect, useState } from 'react';
import { Base64 } from 'js-base64';
import { venomApi } from '../../api/venom';
import { investorActions } from '../../store/investor';
import { useDispatch } from 'react-redux';

export const VenomContext = createContext(null);

export const VenomProvider = ({ children }) => {
  const [venomConnect, setVenomConnect] = useState();
  const dispatch = useDispatch();
  const init = async () => {
    const _venomConnect = await initVenomConnect();
    setVenomConnect(_venomConnect);
    console.log('venom connect', _venomConnect);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const off = venomConnect?.on('connect', (provider) => onConnect(provider, dispatch));

    return () => {
      off?.();
    };
  }, [venomConnect]);

  return (
    <VenomContext.Provider value={venomConnect}>
      {children}
    </VenomContext.Provider>
  );
};

async function generateOneTimeCodeForVenomWallet(userVenomWalletPublicKey) {
  if (userVenomWalletPublicKey === '') {
    return '';
  }
  const oneTimeCodeData = await venomApi.generateCode(userVenomWalletPublicKey);

  return oneTimeCodeData.data || '';
}

async function onConnect(provider, dispatch) {
  const providerState = await provider?.getProviderState?.();
  const publicKey = providerState?.permissions.accountInteraction?.publicKey;
  if (publicKey === '' || publicKey === undefined) {
    return '';
  }
  console.log({ publicKey });
  const oneTimeCodeData = await generateOneTimeCodeForVenomWallet(publicKey);
  console.log({ oneTimeCodeData });

  if (oneTimeCodeData.length > 1) {
    const result = await provider?.signData({
      publicKey: String(publicKey),
      data: Base64.encode(oneTimeCodeData)
    });

    console.log({ result });

    if (result && publicKey) {
      await venomApi.loginBySignature({
        publicKey,
        signature: result.signature,
        authCode: oneTimeCodeData
      });
      console.log('after login!!')

      dispatch(investorActions.getInvestor(true));
    }
  }
}

const initVenomConnect = async () => {
  return new VenomConnect({
    theme: 'light',
    checkNetworkId: 1000,
    checkNetworkName: 'Venom Testnet',
    providersOptions: {
      venomwallet: {
        walletWaysToConnect: [
          {
            package: ProviderRpcClient,
            packageOptions: {
              fallback: VenomConnect.getPromise('venomwallet', 'extension') || (() => Promise.reject()),
              forceUseFallback: true
            },
            packageOptionsStandalone: {
              // fallback: () =>
              //   EverscaleStandaloneClient.create({connection: {
              //       id: 1010,
              //       group: 'venom_testnet',
              //       type: 'jrpc',
              //       data: {
              //         endpoint: 'https://jrpc-testnet.venom.foundation/rpc'
              //       }
              //     }}),
              forceUseFallback: true
            },

            id: 'extension',
            type: 'extension'
          }
        ],
        defaultWalletWaysToConnect: [
          'mobile',
          'ios',
          'android'
        ]
      }
    }
  });
};
