import React from 'react';
import { Container, Row } from '../../../../../ui/layout';
import { Input, Button } from '../../../../../ui/atoms';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

export const RejectModal = ({ active, onClose, comment, setComment, error, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Modal open={active} onCancel={onClose}>
      <Container marginTop={40}>
        <Input
          label={t('Comment')}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          error={error}
        />
      </Container>

      <Row justifyContent={'center'} marginTop={28}>
        <Button label={t('Reject')} onClick={onSubmit} mode={'strong'} />
      </Row>
    </Modal>
  );
};
