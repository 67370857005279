import React from 'react';
import styled from 'styled-components';
import { EyeInvisibleOutlined } from '@ant-design/icons';


export const IconView = ({ onClick }) => {
  return (
    <IconViewWrapper onClick={onClick}>
      <EyeInvisibleOutlined />
    </IconViewWrapper>
  );
};

const IconCheckWrapper = styled.div`
  width: 32px;
  height: 32px;
  padding: 5px;
  background: #2cc68f;
  color: #ffffff;
  border-radius: 100%;
`;
const IconViewWrapper = styled(IconCheckWrapper)`
  padding: 3px;
  background: #ffffff;
  mix-blend-mode: normal;
  border: 1px solid #dde4e9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: #08bee5;
  cursor: pointer;
`;
