import axios from 'axios';
import { API_URL } from '../../utils/static/api';

const generateCode = (publicKey) => {
  return axios.get(`${API_URL}/api/v1/auth/generateVenomWalletAuthCode`, { params: { publicKey } });
};
const loginBySignature = ({ publicKey, authCode, signature }) => {
  return axios.post(`${API_URL}/api/v1/investor/loginViaVenomWallet`, { publicKey, authCode, signature });
};
const issueVC = (publicKey) => {
  return axios.post(`${API_URL}/api/v1/investor/issueVC`, { publicKey });
};

export const venomApi = {
  generateCode,
  loginBySignature,
  issueVC
};
