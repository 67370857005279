import { Link as LinkInit } from 'react-router-dom';
import { Button } from 'antd';
import { COLORS } from '../../../utils/static';
import styled from 'styled-components';

export const ExternalLink = ({ children, ...props }) => {
  return <StyledExternalLink type='link' {...props}>{children}</StyledExternalLink>
}

export const LinkButton = ({ children, ...props }) => {
  return <StyledLinkButton type='link' {...props}>{children}</StyledLinkButton>
}

export const Link = styled(LinkInit)`
  color: ${(p) => (p.color ? p.color : p.defaultColor ? COLORS.blue : COLORS.aragonBlue)};
  text-decoration: ${(p) => (p.external ? 'underline' : 'none')};
`;
const StyledExternalLink = styled(Button)`
  color: ${(p) => (p.color ? p.color : p.defaultColor ? COLORS.blue : COLORS.blueAntd)};
  font-size: 12px;
  padding: 0;
    
  span {
    text-decoration: ${(p) => (p.external ? 'underline' : 'none')};
  }
`;
export const StyledLinkButton = styled(Button)`
  color: ${(p) => (p.color ? p.color : COLORS.aragonBlue)};
  font-weight: inherit;
  font-size: 12px;
  padding: 0;
`;
