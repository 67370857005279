import React, { useMemo } from 'react';
import { Box } from '../../../../components';
import { Body1Light, Container } from '../../../../ui';
import { FormInput } from '../../components';
import { CountryInput, CountrySelect } from '../../Fields';
import { useTranslation } from 'react-i18next';

export const AddressBox = ({ label, form, disabled, hideField, adminView, required, children }) => {
  const { t } = useTranslation();
  const { name, control, rules, ...otherForm } = form;

  const resultRules = useMemo(() => {
    if (rules) {
      const { required, ...propsRules } = rules;

      return { required, otherRules: propsRules };
    }

    return {};
  }, [rules]);

  return (
    <Container>
      {/*<Body1Light marginBottom={24}>{label || name}</Body1Light>*/}
      {!adminView && children}

      {!hideField &&
        fields.map((field, key) => {
          const RenderedField = componentByType[field];
          const fieldRequired = field !== 'Region' && resultRules.required;

          return (
            <Container marginBottom={16} key={key}>
              <RenderedField
                form={{
                  name: `${name}.${field}`,
                  control: control,
                  rules: {
                    required: fieldRequired,
                    ...resultRules.otherRules
                  },
                  ...otherForm
                }}
                label={t(labelByType[field])}
                required={field !== 'Region' && required}
                disabled={disabled}
              />
            </Container>
          );
        })}
    </Container>
  );
};

const fields = ['Country', 'ZipCode', 'Region', 'City', 'StreetAddress'];

const componentByType = {
  Country: CountrySelect,
  ZipCode: FormInput,
  Region: FormInput,
  City: FormInput,
  StreetAddress: FormInput
};
const labelByType = {
  Country: 'Country',
  ZipCode: 'Zip/Postal code',
  Region: 'Region',
  City: 'City',
  StreetAddress: 'Street Address'
};
