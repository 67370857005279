import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { entitiesActions } from '../../../../store/entities';
import { message } from 'antd';

export const Toast = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state.entities);
  const ref = useRef();

  const info = () => {
    messageApi.info(toast);
  };

  useEffect(() => {
    if (toast) {
      try {
        info();
        dispatch(entitiesActions.hideToast());
      } catch (e) {
        console.error(e);
      }
    }
  }, [toast]);

  return <>{contextHolder}</>;
};
