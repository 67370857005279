import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Step1, Step2, Step3 } from './steps';
import { history } from '../../../routing';
import { BREAKPOINTS, Block, Container, Row } from '../../../ui';
import { Button, StepsProgress } from '../../../components';
import arrowBackIcon from '../../../assets/icons/arrow_back.svg';
import { Typography } from 'antd';
import { COLORS } from '../../../utils/static';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';

const goNext = (currentStep) => {
  history.push(`/registration/investor/${++currentStep}`);
};
const goBack = (currentStep) => {
  let newStep = --currentStep;
  if (newStep < 1) newStep = 1;
  history.push(`/registration/investor/${newStep}`);
};

const InvestorReg = () => {
  const { t } = useTranslation();
  const { page } = useParams();

  const renderStep = useCallback((page) => {
    switch (+page) {
      case 1:
        return <Step1 stepNumber={page} goNext={() => goNext(page)} goBack={() => goBack(page)} />;
      case 2:
        return <Step2 stepNumber={page} goNext={() => goNext(page)} goBack={() => goBack(page)} />;
      case 3:
        return <Step3 stepNumber={page} goNext={() => goNext(page)} goBack={() => goBack(page)} />;
      default:
        return null;
    }
  }, []);

  return (
    <StyledMainRow
      alignCenter
      flexDirection={'column'}
      paddingX={16}
      paddingTop={120}
      paddingBottom={132}
    >
      <StyledContainer>
        <Button
          size='large'
          type='text'
          text={t('Back')}
          icon={<ArrowLeftOutlined />}
          onClick={() => history.goBack()}
        />
      </StyledContainer>

      <Typography.Title
        level={2}
        style={{ textAlign: 'center', fontWeight: '600', color: COLORS.default }}
      >
        {t('Creation account')}
      </Typography.Title>

      <Block maxWidth={667} wide marginTop={57} paddingLeft={15}>
        <StepsProgress currentStep={page - 1} />
      </Block>
      {renderStep(page)}
    </StyledMainRow>
  );
};

export default InvestorReg;

const StyledMainRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 70px;
  }
`;

const StyledContainer = styled(Container)`
  position: absolute;
  top: 60px;
  left: 60px;
  width: min-content;
  @media (max-width: ${BREAKPOINTS.md}px) {
    top: 15px;
    left: 15px;
  }
`;
