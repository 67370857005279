import React from 'react';
import { history } from '../../../routing';
import { FooterButtons, OptionCard, RegContainer } from '../../../components';
import { Title2, Row, Link } from '../../../ui';
import styled from 'styled-components';
import LoginPassIcon from '../../../assets/entry-pages/login-pass.png';
import LoginWalletIcon from '../../../assets/entry-pages/login-wallet.png';

export const LoginPage = () => {
  return (
    <RegContainer type='centered'>
      <Title2>Login to start your session.</Title2>

      <Row justifyContent={'space-between'} marginTop={56} marginBottom={24}>
        <OptionCard
          title={'Log in with your\npassword'}
          onClick={() => {
            history.push('/login/email');
          }}
          icon={LoginPassIcon}
        />

        <OptionCard
          title={'Connect\nwallet'}
          onClick={() => {
            history.push('/login/wallet');
          }}
          icon={LoginWalletIcon}
        />
      </Row>

      <FooterButtons />

      <RepeatSendTitle>
        <span>Don’t have an account yet? </span>
        <Link to={'/registration/investor'}>Sign up</Link>
      </RepeatSendTitle>
    </RegContainer>
  );
};

const RepeatSendTitle = styled.p`
  font-size: 18px;
  margin: 40px 0 64px 0;
`;
