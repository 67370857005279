export const customTheme = {
  token: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 16,
    lineHeight: 1.375,
    colorText: '#272944',
    fontSizeHeading5: 18,
    fontSizeHeading4: 20,
    fontSizeHeading3: 24,
    fontSizeHeading2: 28,
    lineHeightHeading5: 1.3333333333333333,
    lineHeightHeading4: 1.4,
    lineHeightHeading3: 1.6666666666666666,
    lineHeightHeading2: 1.4285714286,
    fontWeightStrong: 700
  },
  components: {
    Typography: {
      // fontFamilyCode: 'Overpass, sans-serif',
      titleMarginBottom: 0,
      fontSizeHeading4: 20,
      fontSizeHeading2: 28,
      algorithm: true
    },
    Select: {
      borderRadiusLG: 4,
      fontSizeLG: 14,
      algorithm: true
    },
    DatePicker: {
      borderRadiusLG: 4,
      fontSizeLG: 14,
      algorithm: true
    }
  }
};
