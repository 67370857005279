import React, { useState } from 'react';
import { Modal } from '@aragon/ui';
import { UploadDocumentsBox } from '../../InputBoxes';
import { Container } from '../../../../ui/layout';
import { Button } from '../../../../ui/atoms';
import { useTranslation } from 'react-i18next';

export const UploadFilesModal = ({ onSubmit, multiple = false, ...props }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  return (
    <Modal padding={30} {...props}>
      <UploadDocumentsBox
        label={t('Upload file')}
        docs={files}
        setDocs={(files) =>
          Array.isArray(files) ? setFiles(files) : setFiles(files ? [files] : [])
        }
        multiple={multiple}
      />

      <Container marginTop={20} style={{ textAlign: 'center' }}>
        <Button label={t('Save')} onClick={() => onSubmit(files)} mode={'strong'} />
      </Container>
    </Modal>
  );
};
