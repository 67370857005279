import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

export const Text = ({ fontWeight, fontSize, color, maxWidth, children }) => {
  return (
    <StyledText fontWeight={fontWeight} fontSize={fontSize} color={color} maxWidth={maxWidth}>
      {children}
    </StyledText>
  );
};

const StyledText = styled(Typography.Text)`
  ${({ fontSize }) => fontSize && `font-size:${fontSize}px;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`}
`;
