import React, { useContext, useState } from 'react';
import { Modal, Typography, Input } from 'antd';
import { MessageContext } from '../../../../app/MessageProvider';
import { queryClient } from '../../../../app/QueryClientProvider';
import { getErrorMessage } from '../../../../utils/helpers';
import { Container } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import {adminReferralApi, adminReferralApiKeys} from "../../../../api";
import {useParams} from "react-router-dom";

export const SetAssistantModal = ({ open, onClose }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const messageApi = useContext(MessageContext);

  const makeRequest = async () => {
    if (code) {
      try {
        await adminReferralApi.setReferralCodeRecommender({ investorId: +id, referralCodeRecommender: code });

        await queryClient.refetchQueries({queryKey: [adminReferralApiKeys.getRecommendersList]});
        messageApi.success(t('Request is sent'));
        setCode('');
        onClose();
      } catch (e) {
        console.error(e);
        messageApi.error(t(getErrorMessage(e)));
      }
    } else {
      messageApi.warning(t('Please enter referral code'));
    }
  };

  return (
    <Modal open={open} onCancel={onClose} title={t('Set assistant')} onOk={makeRequest} okText={t('Make request')}>
      <Container marginBottom={4}>
        <Typography.Text>{t('Set assistant request')}</Typography.Text>
      </Container>
      <Input
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder={t('Enter assistant code')}
      />
    </Modal>
  );
};
