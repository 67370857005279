import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { investorActions } from './store/investor';
import { RootRouter, ScrollToTop } from './routing';
import { goToRegistration } from './utils/helpers';
import { Spin } from 'antd';
import { Overlay, Toast } from './components';
import { userActions, userReducer } from './store/user';
import styled from 'styled-components';
import { ExternalLink } from './ui';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.entities);
  const { userRole, showUserGuide, hideAppContainer } = useSelector((state) => state.user);
  const [routerMode, setRouterMode] = useState('');

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    const token = localStorage.getItem('token');

    if (userRole && token) {
      dispatch(userActions.setToken({ userRole: userRole, accessToken: token }));
    } else {
      dispatch(userActions.setRole(null));
    }
  }, []);

  useEffect(() => {
    (async () => {
      switch (userRole) {
        case 'investor': {
          try {
            const investor = await dispatch(investorActions.getInvestor());
            if (
              !investor.conditionsRegistration.verifiedEmail ||
              !investor.conditionsRegistration.verifiedPhone
            ) {
              goToRegistration(investor);
              setRouterMode('reg');
              dispatch(userActions.setRole(null));
            } else {
              setRouterMode(userRole);
            }
          } catch (e) {
            dispatch(userActions.setRole(null));
            console.log(e);
          }

          break;
        }
        case 'admin': {
          setRouterMode(userRole);
          break;
        }
        case 'superAdmin': {
          setRouterMode(userRole);
          break;
        }
        case null: {
          setRouterMode('reg');
          break;
        }
        case undefined: {
          break;
        }
        default: {
          setRouterMode('reg');
        }
      }
    })();
  }, [userRole]);

  return (
    <ScrollToTop>
      {routerMode && (
        <>
          <AppContainer id={'app-container'}>
            <RootRouter routerMode={routerMode} hideAppContainer={hideAppContainer} />
          </AppContainer>

          <Toast />

          <Overlay
            visible={showUserGuide}
            onClose={() => dispatch(userReducer.setShowUserGuide(false))}
          >
            {t('We are delighted to welcome you to our system!')}
            <br />
            {t('Here you will be able to complete and submit all the necessary documents and forms.')}
            {t(' ')}
            {t('As you complete them, new ones may appear as necessary.')}
            {t(' ')}
            {t('Depending on your level of authorisation, you may be assigned to tiers 1-3.')}
            {t(' ')}
            {t("This means higher transaction limits and other features.")}
            <br />{t('If you have any questions, please contact us')} - <ExternalLink
            href={'mailto:compliance@casecapital.co.il'} external>
            compliance@casecapital.co.il
          </ExternalLink>
          </Overlay>
        </>
      )}

      {!routerMode && (
        <>
          <Loading />
        </>
      )}
    </ScrollToTop>
  );
};

export default App;

const Loading = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  svg {
    height: 80px;
    width: 80px;
  }
`;

const AppContainer = styled.div`
  min-height: 100vh;
    min-height: 100dvh;
`;
