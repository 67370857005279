import React, { useEffect, useState } from 'react';
import { IconConnect, Modal } from '@aragon/ui';
import { Typography } from 'antd';
import { Button, Col, Row, Title5, IconCheck, LinkButton } from '../../../../ui';
import { IdentityBadge } from '../../../../components';
import Web3 from 'web3';

export const ConnectMetamask = ({ walletAddress, setWalletAddress }) => {
  const [openedModal, setOpenedModal] = useState(false);

  useEffect(() => {
    checkMetamaskInstalled();
  }, []);

  const checkMetamaskInstalled = () => {
    if (!window.ethereum) {
      setOpenedModal(true);
    }
  };

  const getWalletAddress = async () => {
    let web3 = new Web3(window.ethereum);
    return (await web3.eth.getAccounts())[0];
  };

  const connectToWallet = async () => {
    checkMetamaskInstalled();

    try {
      await window.ethereum.request({
        method: 'wallet_requestPermissions',
        params: [{ eth_accounts: {} }]
      });
      getWalletAddress().then((walletAddress) => {
        if (walletAddress) {
          setWalletAddress(walletAddress);
        }
      });
    } catch (e) {
      console.log('error connectToWallet', e);
    }
  };

  return (
    <div>
      <Typography.Text style={{ display: 'block', width: '100%', textAlign: 'left', marginBottom: '10px' }}>
        Connect your wallet account
      </Typography.Text>

      {!walletAddress ? (
        <Button icon={<IconConnect />} label='Connect account' onClick={connectToWallet} />
      ) : (
        <Row alignItems={'center'}>
          <IdentityBadge entity={walletAddress} connectedAccount />
          <IconCheck size={24} />

          <Col padding={32} style={{ display: 'flex' }}>
            <Button
              label='Reconnect'
              onClick={connectToWallet}
              icon={<IconConnect />}
              size={'small'}
            />
          </Col>
        </Row>
      )}

      <Modal visible={openedModal} onClose={() => setOpenedModal(false)}>
        <Title5 marginBottom={8}>You have to install Metamask wallet first</Title5>
        <LinkButton href={'https://metamask.io'} target={'_self'} external>
          https://metamask.io
        </LinkButton>
      </Modal>
    </div>
  );
};
