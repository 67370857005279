export const COLORS = {
  default: '#212B36',
  white: '#ffffff',
  greyBasic: '#DDE4E9',
  greyMedium: '#637381',
  greyLight: '#C4CDD5',
  greyText: '#808089',
  greyText2: '#B5B5B5',
  iconColor: '#8FA4B5',
  darkBabyBlue: '#F1F3F7',
  aragonDarkBlue: '#0792AF',
  aragonBlue: '#08BEE5',
  blueAntd: '#006FFF',
  aragonLightBlue: 'rgba(149, 236, 255, 0.5)',
  blue: '#3E7BF6',
  lightBlue: `rgba(62, 123, 246, 0.95)`,
  lightblue: `rgba(62, 123, 246, 0.95)`,
  red: '#FF6969',
  yellow: '#F7D858',
  orange: '#F89E08',
  mintGreen: '#2CC68F',
  aragonLightTurquoise: 'rgba(156, 247, 243, 0.5)',
  newStatus: '#0791DF',
  inReviewStatus: '#0951BC',
  passedStatus: '#088F15',
  rejectedStatus: '#CF160A',
  draftStatus: '#626262',
  inProcessStatus: '#E97001'
};
