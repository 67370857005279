import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  EnterPage,
  InvestorRegStart,
  InvestorReg,
  LoginViaEmail,
  ResetPassword,
  TestPage
} from '../../pages';
import { AgentRegStart } from '../../pages/registration/agent';
import { AssistantRegStart } from '../../pages/registration/assistant';

export const RegLoginRoutes = () => {
  return (
    <Switch>
      <Route exact path='/' component={EnterPage} />
      <Route exact path='/registration/investor' component={InvestorRegStart} />
      <Route exact path='/registration/investor/:page' component={InvestorReg} />
      <Route exact path='/registration/agent' component={AgentRegStart} />
      <Route exact path='/registration/agent/:page' component={InvestorReg} />
      <Route exact path='/registration/assistant' component={AssistantRegStart} />
      <Route exact path='/registration/assistant/:page' component={InvestorReg} />
      <Route exact path='/login' component={LoginViaEmail} />
      <Route exact path='/reset-password' component={ResetPassword} />

      <Route exact path='/test' component={TestPage} />

      <Redirect to={'/'} />
    </Switch>
  );
};
