import React from 'react';
import { TableCell, TableHeader as TH, GU } from '@aragon/ui';
import { Body3Light } from '../../../../ui';
import styled from 'styled-components';

export const Cell = ({ children, cellProps, Container = Body3Light, ...props }) => (
  <StyledCell {...cellProps}>
    <Container {...props}>{children}</Container>
  </StyledCell>
);

const StyledCell = styled(TableCell)`
  padding: ${(p) => (p.padding ? p.padding : '20px 10px')};

  :first-child {
    padding-left: ${3 * GU}px;
  }
  :last-child {
    padding-right: ${3 * GU}px;
  }
`;

export const TableHeader = styled(TH)`
  padding-left: 10px;
  font-weight: 400;
  ${(p) => (p.color ? `color: ${p.color};` : '')}
  ${(p) => (p.textAlign ? `text-align: ${p.textAlign};` : '')}
  
  :first-child {
    padding-left: ${3 * GU}px;
  }
`;
