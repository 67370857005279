export const ASSET_TYPES = {
  EQUITY: 'Equity',
  BOND: 'Bond'
};

export const ASSET_TYPES_LIST = ['EQUITY', 'BOND'];
export const ASSET_TYPES_LIST_DISPLAY = ASSET_TYPES_LIST.map((item) => ASSET_TYPES[item]);

export const EQUITY_TYPES_LIST = ['FUND_SHARE'];
export const EQUITY_TYPES = {
  FUND_SHARE: 'Fund Share'
};
export const EQUITY_TYPES_LIST_DISPLAY = EQUITY_TYPES_LIST.map((item) => EQUITY_TYPES[item]);

export const BUY_ASSET_FEE_PERCENT = 1;
