import React, { useRef } from 'react';
import { Container } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../design2.0';

export const FileUploadButton = ({ multiple = false, onChange, buttonProps, ...props }) => {
  const { t } = useTranslation();
  const myRef = useRef();

  const handleChange = (e) => {
    if (e.target.files.length) {
      onChange(e.target.files);
      myRef.current.value = '';
    }
  };

  const handleClick = () => {
    myRef.current.click();
  };

  return (
    <Container {...props}>
      <Button onClick={handleClick} text={t('Choose File')} {...buttonProps} />

      <input
        type='file'
        ref={myRef}
        style={{ display: 'none' }}
        onChange={handleChange}
        multiple={multiple}
      />
    </Container>
  );
};
