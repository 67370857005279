import React from 'react';
import styled from 'styled-components';
import { Steps } from 'antd';
import dotActiveIcon from '../../../../assets/icons/dot-active.svg';
import dotDisabledIcon from '../../../../assets/icons/dot-disabled.svg';
import { useTranslation } from 'react-i18next';
import { BREAKPOINTS } from '../../../../ui';

export const StepsProgress = ({ currentStep }) => {
  const { t } = useTranslation();
  // const step = currentStep - 1;
  const items = [
    {
      title: t('Personal\ndetails'),
      icon: <Icon src={dotActiveIcon} active />
    },
    {
      title: t('Verification\ne-mail'),
      icon: currentStep > 0 ? <Icon src={dotActiveIcon} active /> : <Icon src={dotDisabledIcon} />
    },
    {
      title: t('Verification phone\nnumber'),
      icon: currentStep > 1 ? <Icon src={dotActiveIcon} active /> : <Icon src={dotDisabledIcon} />
    }
  ];

  return (
    <Container>
      <StyledSteps
        labelPlacement='vertical'
        responsive={false}
        current={currentStep}
        items={items}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 53px 35px 0 0;
`;

const StyledSteps = styled(Steps)`
  .ant-steps-item-content {
    margin: unset;
    position: absolute !important;
    bottom: 59px !important;
    left: 13px !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon,
  .ant-steps-item-active .ant-steps-item-icon .ant-steps-icon,
  .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
    top: -2px !important;
    left: -5px;
  }
  .ant-steps-item-title {
    font-size: 16px !important;
    white-space: pre !important;
    line-height: normal;
  }
  .ant-steps-item:first-child .ant-steps-item-container .ant-steps-item-content {
    left: -9px !important;
  }
  .ant-steps-item-tail {
    padding: 0 !important;
  }
  .ant-steps-item-tail::after {
    height: 2px;
    background-color: #ebebeb !important;
  }
  .ant-steps-item:last-child .ant-steps-item-container .ant-steps-item-title {
    left: -26px !important;
  }
  .ant-steps-item:nth-child(2) .ant-steps-item-container .ant-steps-item-title {
    left: -20px !important;
  }
  .ant-steps-item-title {
    @media (max-width: ${BREAKPOINTS.smd}px) {
      font-size: 11px !important;
    }
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  ${({ active }) => (active ? `filter: drop-shadow(0px 0px 12px rgba(0, 111, 255, 0.24));` : ``)}
`;
