const textStyle = (name) => {
  return TEXT_STYLES[name];
};

const TEXT_STYLES = {
  title1: {
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '1.5'
  },
  title2: {
    fontSize: '26px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  title3: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  title4: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  title5: {
    display: `block`,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5,
    marginBottom: `20px`
  },
  body1: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body2: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body3: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body4: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body5: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: 1.5
  },
  label1: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5,
    textTransform: 'uppercase'
  },
  label2: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: 'uppercase'
  },
  label3: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: 1.5,
    textTransform: 'uppercase'
  },
  address1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5,
    fontFamily: 'aragon-ui-monospace, monospace'
  },
  address2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5,
    fontFamily: 'aragon-ui-monospace, monospace'
  },
  title3Wide: {
    display: 'inline-block',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: 1.5,
    width: '100%'
  },
  body1Wide: {
    display: 'inline-block',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: 1.5,
    width: '100%'
  },
  body3Wide: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5,
    width: '100%'
  },
  body4Wide: {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.5,
    width: '100%'
  },
  label2Wide: {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: 'uppercase',
    width: '100%'
  }
};

export default textStyle;
