import { dateString } from "../../../../utils/helpers";
import { DISPLAY_INVESTOR_ACCOUNT_STATUS } from "../../../../utils/static";

export const transformData = (data) => {
  return data?.map((item)=> {
    return {
      id: item.id,
      key: item.id,
      name: `${item.firstName} ${item.lastName}`,
      representatives: item.representatives ? item?.representatives[0]?.answer : '-',
      tier: item.tier,
      email: item.email,
      accountStatus: DISPLAY_INVESTOR_ACCOUNT_STATUS[item.accountStatus],
      createdAt: dateString(new Date(item.createdAt)),
      updatedAt: dateString(new Date(item.updatedAt))
      // ...item
    };}
)
}