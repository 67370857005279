import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../store/user';
import { entitiesActions } from '../../../store/entities';
import styled from 'styled-components';
import { GU, Info } from '@aragon/ui';
import { RegContainer, FooterButtons, OptionCard, ConnectMetamask } from '../../../components';
import { Title2, Row, Body4Light, LinkButton, Body4 } from '../../../ui';
import { authApi } from '../../../api/auth';
import { web3Api } from '../../../api/web3';
import { COLORS } from '../../../utils/static';

export const LoginViaWallet = () => {
  const dispatch = useDispatch();
  const [connect, setConnect] = useState(true);
  const [walletAddress, setWalletAddress] = useState('');

  const handleNext = async () => {
    try {
      const getMessageRes = await authApi.getMessage(walletAddress);

      if (getMessageRes.status === 200) {
        try {
          const signMessageRes = await web3Api.signMessage(
            getMessageRes.data.message,
            walletAddress
          );

          authApi.verifyMessage(signMessageRes, walletAddress).then((res) => {
            if (res.status === 200) {
              dispatch(userActions.setToken(res.data));
            }
          });
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      const errorMessage = e.response.data.message;
      console.log(errorMessage);
      if (errorMessage === 'USER_NOT_FOUND') {
        dispatch(entitiesActions.addToast('User not found'));
      }
    }
  };

  return (
    <StepsPanelContainer>
      <RegContainer type='centered'>
        <Title>Connect wallet</Title>

        {!connect ? (
          <Row>
            <OptionCard
              title={'Connect wallet'}
              className={'col-auto'}
              backgroundColor={'#FFD48C'}
              onClick={() => setConnect(true)} //ask about it
            />

            <OptionCard
              title={'Use SSI'}
              className={'col-auto'}
              backgroundColor={'#08BEE5'}
              onClick={() => {}}
            />
            <InfoBlock title='How to create a wallet?' style={{ textAlign: 'left' }}>
              <Body4Light>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet. Download Metamask
              </Body4Light>
            </InfoBlock>
          </Row>
        ) : (
          <Row>
            <ConnectMetamask walletAddress={walletAddress} setWalletAddress={setWalletAddress} />

            <InfoBlock title='How to create a wallet?' style={{ textAlign: 'left' }}>
              <Body4Light color={COLORS.aragonDarkBlue}>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet.&thinsp;
                <LinkButton>
                  <Body4 color={COLORS.blue}>Download Metamask</Body4>
                </LinkButton>
              </Body4Light>
            </InfoBlock>
          </Row>
        )}

        <FooterButtons onNext={handleNext} nextDisabled={!walletAddress} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${7 * GU}px;
`;
const InfoBlock = styled(Info)`
  margin: ${GU * 4}px 0;
`;
