import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
   html, body {
       overflow: unset !important;
       margin: 0;
   }
   
   * {
       box-sizing: border-box;
   }

   body #root {
       font-family: 'Open Sans', sans-serif;
   }
    
   h1, h2, h3, h4, h5 {
       margin: 0;
   }
   
  .ant-typography+h1.ant-typography,
  .ant-typography+h2.ant-typography,
  .ant-typography+h3.ant-typography,
  .ant-typography+h4.ant-typography,
  .ant-typography+h5.ant-typography {
      margin: 0;
      /* font-family: 'Overpass', sans-serif; */
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  /* Firefox */
  input[type=number] {
      -moz-appearance: textfield;
  }

  .PhoneInputCountrySelect {
    &:disabled {
      cursor: not-allowed;
    }
  }
  .PhoneInputInput {
    padding: 9.5px 8px 9.5px 16px;
    height: 40px !important;
    font-size: 14px;
    color: #27272a;
    border-color: #dddde3;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 1.375;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    transition: all 0.2s;
    margin-left: 3px;
    
    &:hover {
      border-color: #4096ff;
      border-inline-end-width: 1px;
      z-index: 1;
    }
    
    &:focus {
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      border-inline-end-width: 1px;
      outline: 0;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }
  }

   .ant-progress.ant-progress-status-success .ant-progress-bg {
       background-color: #1677ff;
   }
`;
