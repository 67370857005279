import React, { createContext } from 'react';
import { message } from 'antd';

export const MessageContext = createContext(null);

export const MessageProvider = ({ children }) => {
  const [messageApi, messageContext] = message.useMessage();

  return (
    <MessageContext.Provider value={messageApi}>
      {children}

      {messageContext}
    </MessageContext.Provider>
  );
};
