import InvestorRegStart from './registration/investor';
import InvestorReg from './registration/investor/InvestorReg';
export * from './login';
export * from './admin';
export * from './investor';
export * from './EnterPage';
export * from './test';

export {
  InvestorRegStart,
  InvestorReg,
};
