import React from 'react';
import { Progress as AntProgress, Typography } from 'antd';
import { Row } from '../../../../ui';
import styled from 'styled-components';

export const Progress = ({ currentStep = 0, steps = 1, ...props }) => {
  return (
    <StyledRow nowrap>
      <AntProgress
        style={{ margin: 0, width: '100%' }}
        showInfo={false}
        {...props}
        format={() => `${currentStep}/${steps}`}
        percent={Math.floor((currentStep / steps) * 100)}
      />

      <Text>
        {currentStep}/{steps}
      </Text>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  gap: 18px;
`;

const Text = styled(Typography.Text)`
  width: 3em;
  line-height: 24px;
  max-height: 24px;
  overflow: hidden;
`;
