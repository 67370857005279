import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';

const ConfigureStepsItem = ({ stepNumber, step, label, currentStep, titleProps, show, onClick = null }) => {

  const stepStyles = useMemo(() => {
    if (Array.isArray(step)) {
      if (step === currentStep || step.includes(currentStep)) {
        return {
          background: '#0000FF',
          color: '#0000FF'
        };
      }
      if (Array.isArray(currentStep) ? step[step.length - 1] < currentStep[0] : step[step.length - 1] < currentStep) {
        return {
          background: '#008000',
          color: '#008000'
        };
      }
      return {
        background: '#ECEFF4',
        color: '#9CA7B8'
      };
    } else {
      if (step === currentStep) {
        return {
          background: '#0000FF',
          color: '#0000FF'
        };
      }
      if (Array.isArray(currentStep) ? step < currentStep[0] : step < currentStep) {
        return {
          background: '#008000',
          color: '#008000'
        };
      }
      return {
        background: '#ECEFF4',
        color: '#9CA7B8'
      };
    }
  }, [step, currentStep]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          minHeight: `${3 * 8}px`,
          marginBottom: `${2 * 8}px`,
          cursor: onClick ? 'pointer' : 'default'
        }}
        onClick={onClick}
      >
        <StepIcon
          style={{
            ...stepStyles
          }}
        >
          {(
            Array.isArray(step)
              ? Array.isArray(currentStep)
                ? step[step.length - 1] < currentStep[0]
                : step[step.length - 1] < currentStep
              : Array.isArray(currentStep)
              ? step < currentStep[0]
              : step < currentStep
          ) ? (
            <CheckCircleOutlined />
          ) : (
            <span>{stepNumber}</span>
          )}
        </StepIcon>

        <StepTitle {...titleProps}>{label}</StepTitle>
      </div>
    </>
  );
};

export default ConfigureStepsItem;

const StepIcon = styled.div`
    width: ${3 * 8}px;
    height: ${3 * 8}px;
    padding: ${0.5 * 8}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 300;
    font-size: 14px;
    color: #637381;

    > span {
        transform: translateY(2px);
    }
`;
const StepTitle = styled.p`
    margin: 0;
    margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : `${8}px`)};
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(2px);
`;
