import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';

export const Tooltip = ({
  title,
  children,
  placement = 'top',
  arrow = true,
  maxWidth = '290px'
}) => {
  return (
    <AntdTooltip
      title={title}
      placement={placement}
      arrow={arrow}
      color='#fff'
      maxWidth={maxWidth}
      overlayInnerStyle={{
        padding: '10px 8px',
        borderRadius: '3px',
        fontSize: '12px',
        fontWeight: '300',
        color: '#637381'
      }}
      trigger={'hover'}
      overlayStyle={{
        maxWidth: maxWidth,
        filter: 'drop-shadow(0px 4px 12px rgba(0, 111, 255, 0.18))'
      }}
    >
      {children}
    </AntdTooltip>
  );
};
