import React, { useEffect, useRef } from 'react';
import { ToastHub, Toast as AragonToast } from '@aragon/ui';
import { useDispatch, useSelector } from 'react-redux';
import { entitiesActions } from '../../../../store/entities';

const timeout = 3000;

export const Toast = () => {
  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state.entities);
  const ref = useRef();

  useEffect(() => {
    if (toast) {
      try {
        ref.current.click();
        dispatch(entitiesActions.hideToast());
      } catch (e) {
        console.error(e);
      }
    }
  }, [toast]);

  return (
    <ToastHub timeout={timeout}>
      <AragonToast>{(addToast) => <div ref={ref} onClick={() => addToast(toast)} />}</AragonToast>
    </ToastHub>
  );
};
