import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../common/Button';
import { userActions } from '../../../store/user';
import { Block } from '../../../ui';
import { useTranslation } from 'react-i18next';

export const LogOutButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLogout = () => {
    dispatch(userActions.logout());
  };
  return (
    <Block width={90}>
      <Button type='text' size='large' text={t('Logout')} onClick={onLogout} />
    </Block>
  );
};
