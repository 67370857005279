import React, { useMemo, useState } from 'react';
import { BREAKPOINTS, Block, Col, Container, Hr, PageContainer, Row } from '../../../../ui/layout';
import { BackButton } from '../../../../ui/atoms/BackButton';
import { history } from '../../../../routing';
import { Box } from '../../../customAragon/Box';
import { Body1Light, Body2Light, Text, Title4 } from '../../../../ui/typography';
import { COLORS } from '../../../../utils/static/colors';
import {
  ENABLED_DOC_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR,
  STATUS_COLOR,
  STATUS_DISPLAY
} from '../../../../utils/static/kyc';
import { dateString, downloadFileByLink, getFileSrc } from '../../../../utils/helpers/common';
// import { Button } from '../../../../ui/atoms/Button';
import { ExternalLink } from '../../../../ui/atoms/Link';
import { useMutation } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { UploadDocumentsBox } from '../../InputBoxes/UploadDocuments';
import styled from 'styled-components';
import { entitiesActions } from '../../../../store/entities';
import { adminKYCApi } from '../../../../api/admin/kyc';
// import { Input } from '../../../../ui/atoms/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LogOutButton, Modal as ANTDModal, Input, Label } from '../../../design2.0';
import arrowBackIcon from '../../../../assets/icons/arrow_back.svg';
import { Form, Typography, Modal } from 'antd';
import { ReactComponent as NewStatusIcon } from '../../../../assets/icons/status/new.svg';
import { ReactComponent as PassedStatusIcon } from '../../../../assets/icons/status/passed.svg';
import { ReactComponent as InReviewStatusIcon } from '../../../../assets/icons/status/in-review.svg';
import { ReactComponent as InProcessStatusIcon } from '../../../../assets/icons/status/in-process.svg';
import { ReactComponent as RejectedStatusIcon } from '../../../../assets/icons/status/rejected.svg';
import { ReactComponent as DraftStatusIcon } from '../../../../assets/icons/status/draft.svg';
import { useTranslation } from 'react-i18next';

export const DocRequestView = ({
  isFetching,
  data,
  refetch,
  requestId,
  investorId,
  viewMode = 'investor',
  approveRequest,
  rejectRequest
}) => {
  const { t } = useTranslation();

  const { investor } = useSelector((state) => state);
  const [modalVisible, setModalVisible] = useState(false);
  const [addCommentToId, setAddCommentToId] = useState(-1);

  const requestInfo = useMemo(() => {
    if (data && data.data) {
      const { kycDocuments, ...requestObj } = data.data;
      return {
        ...requestObj,
        files:
          kycDocuments && kycDocuments.length > 0
            ? kycDocuments.map((file) => ({
                id: file.id,
                comment: file.comments[0] ? file.comments[0].comment : '',
                file: getFileSrc(file.documentURL)
              }))
            : [{}]
      };
    }

    return {};
  }, [data]);

  const onBack = () => {
    viewMode === 'admin'
      ? history.push(`/investor/${investorId}`, { lastProfileTab: investor.lastProfileTab })
      : history.push('/profile', { lastProfileTab: investor.lastProfileTab });
  };

  const currentDocId = useMemo(() => {
    try {
      return requestInfo.files[0]?.id;
    } catch (e) {}

    return null;
  }, [requestInfo]);

  const STATUS_ICON = {
    PENDING: <NewStatusIcon width={16} height={16} />,
    DRAFT: <DraftStatusIcon width={16} height={16} />,
    PROCESSING: <InReviewStatusIcon width={16} height={16} />,
    QUESTION_UNACCEPTED: <InProcessStatusIcon width={16} height={16} />,
    UNACCEPTED: <RejectedStatusIcon width={16} height={16} />,
    ACCEPTED: <PassedStatusIcon width={16} height={16} />
  };
  const boxStatus = data?.data?.status;
  console.log(boxStatus);
  return (
    <>
      {!isFetching && requestInfo && (
        <>
          <HeaderRow paddingY={24} paddingX={44} justifyContent='space-between' nowrap>
            <Container mdHidden>
              <Block width={210}>
                <Button
                  type='text'
                  size='large'
                  text={t('Back to Documents')}
                  icon={arrowBackIcon}
                  onClick={onBack}
                />
              </Block>
            </Container>

            <Container mdVisible hidden>
              <Button type='text' size='large' icon={arrowBackIcon} onClick={onBack} />
            </Container>
            <LogOutButton />
          </HeaderRow>

          <StyledMainRow
            nowrap
            paddingTop={60}
            paddingX={16}
            justifyContent={'center'}
            gap={24}
            alignItems='flex-end'
          >
            <Container>
              <Row alignCenter gap={24}>
                <Typography.Title level={2}>{requestInfo.kycTypeDocument?.title}</Typography.Title>

                <Row nowrap alignCenter gap={8} paddingTop={4}>
                  {STATUS_ICON[boxStatus]}

                  <Text fontWeight={600} color={STATUS_COLOR[boxStatus]}>
                    {requestInfo.status ? t(`${STATUS_DISPLAY[requestInfo.status]}`) : '-'}
                  </Text>
                </Row>
              </Row>

              <Block marginTop={16} maxWidth={415} wide>
                <Typography.Paragraph>
                  {requestInfo.kycTypeDocument?.descriptions}
                </Typography.Paragraph>
              </Block>

              <Container marginTop={32}>
                <Text fontSize={20} fontWeight={600}>
                  {t('Details')}
                </Text>
              </Container>

              <Grid marginTop={20}>
                <Text fontSize={14} color={COLORS.greyText2}>
                  {t('Created date')}:
                </Text>

                <Text>{requestInfo.created ? dateString(new Date(requestInfo.created)) : '-'}</Text>

                <Text fontSize={14} color={COLORS.greyText2}>
                  {t('Updated date')}:
                </Text>

                <Text>{requestInfo.updated ? dateString(new Date(requestInfo.updated)) : '-'}</Text>

                <Text fontSize={14} color={COLORS.greyText2}>
                  {t('Expiration date')}:
                </Text>

                <Text>
                  {requestInfo.expiration ? dateString(new Date(requestInfo.expiration)) : '-'}
                </Text>
              </Grid>
            </Container>

            <StyledContainer paddingLeft={32}>
              <Text fontWeight={600} fontSize={20}>
                {t('Attached file')}
              </Text>

              {requestInfo.files.map((file, index) => (
                <Row margin={-16} marginBottom={8} justifyContent={'space-between'}>
                  <Body1Light fontWeight={400}>
                    {file.file ? (
                      <ExternalLink onClick={() => downloadFileByLink('file', file.file)} external>
                        {t('File')}
                        {requestInfo.files.length - index}
                      </ExternalLink>
                    ) : (
                      <Text fontSize={14} color={COLORS.greyText2}>
                        {t('Upload a file(s) to make it appear')}
                      </Text>
                    )}
                  </Body1Light>
                  <Col auto>
                    <Row alignCenter>
                      {file.comment && <Body2Light color={COLORS.red}>{file.comment}</Body2Light>}
                      {viewMode === 'admin' && file.file && !file.comment && (
                        <Button
                          text={t('New comment')}
                          onClick={() => setAddCommentToId(file.id)}
                          size={'small'}
                        />
                      )}
                    </Row>
                    <AddCommentModal
                      visible={addCommentToId !== -1}
                      onClose={() => setAddCommentToId(-1)}
                      refetchRequest={refetch}
                      docId={addCommentToId}
                    />
                  </Col>
                </Row>
              ))}

              <Row marginTop={32}>
                <Block>
                  <Button
                    type='filled'
                    size='large'
                    text={t('Upload file')}
                    onClick={() => setModalVisible(true)}
                    disabled={
                      investor.accountStatus !== 'ACTIVE' ||
                      (requestInfo.status && !ENABLED_STATUSES_INVESTOR[requestInfo.status])
                    }
                  />
                </Block>
                {viewMode === 'admin' && (
                  <Row margin={-8} nowrap marginTop={15}>
                    <Col auto>
                      <Button
                        text={t('Approve')}
                        onClick={() => approveRequest(currentDocId)}
                        mode={'strong'}
                        size={'small'}
                        disabled={!ENABLED_DOC_STATUSES_ADMIN[requestInfo.status]}
                      />
                    </Col>
                    <Col auto>
                      <Button
                        text={t('Reject')}
                        onClick={() => rejectRequest(currentDocId)}
                        size={'small'}
                        disabled={!ENABLED_DOC_STATUSES_ADMIN[requestInfo.status]}
                      />
                    </Col>
                  </Row>
                )}
              </Row>
            </StyledContainer>
            <UploadFileModal
              visible={modalVisible}
              onClose={setModalVisible}
              refetchRequest={refetch}
              requestId={requestId}
            />
          </StyledMainRow>
        </>
      )}
    </>
  );
};

const AddCommentModal = ({ visible, onClose, docId, refetchRequest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [comment, setComment] = useState('');

  const addDocComment = async () => {
    try {
      dispatch(entitiesActions.showLoader('Saving...'));
      await adminKYCApi.addRequestComment({ kycDocumentId: docId, comment });
      await refetchRequest();
      onClose();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast('Something went wrong'));
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  return (
    <Modal open={visible} onClose={onClose}>
      <Form layout='vertical'>
        <Input label={t('Comment')} value={comment} onChange={(e) => setComment(e.target.value)} />
      </Form>
      <Container marginTop={16} style={{ textAlign: 'center' }}>
        <Button text={t('Add comment')} onClick={addDocComment} />
      </Container>
    </Modal>
  );
};

const UploadFileModal = ({ visible, onClose, requestId, refetchRequest }) => {
  const [file, setFile] = useState(null);
  const { mutateAsync } = useMutation(kycApi.uploadDocument);
  const { t } = useTranslation();

  const onUpload = async () => {
    try {
      await mutateAsync({ requestDocumentId: requestId, file: file });
      await refetchRequest();
      onClose();
      setFile(null);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ANTDModal isModalOpen={visible} setIsModalOpen={onClose}>
      <UploadDocumentsBox
        docs={file ? [file] : null}
        setDocs={(file) => setFile(file)}
        buttonProps={{
          label: 'Pick file'
        }}
      />

      <Container marginTop={24} style={{ textAlign: 'center' }}>
        <Button text={t('Upload')} onClick={onUpload} type='primary' disabled={!file} />
      </Container>
    </ANTDModal>
  );
};

const HeaderRow = styled(Row)`
  background: #f6f9ff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StyledContainer = styled(Container)`
  height: fit-content;
  border-left: 2px solid #d9d9d9;
  @media (max-width: ${BREAKPOINTS.md}px) {
    border-left: none;
    padding-left: unset;
  }
`;

const StyledMainRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const Grid = styled(Container)`
  display: grid;
  flex-direction: row;
  grid-template-columns: max-content max-content;
  row-gap: 18px;
  column-gap: 12px;
`;

const StyledPageContainer = styled(PageContainer)`
  padding-bottom: 32px;
  max-width: 1200px;
  margin: 0 auto;
`;
