import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  filters: null
};

const assets = createSlice({
  name: 'assetsReducer',
  initialState,
  reducers: {
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setMyAssets(state, action) {
      state.myAssets = action.payload;
    },
    setCurrentAsset(state, action) {
      state.currentAsset = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    }
  }
});

export const { setAssets, setCurrentAsset, setMyAssets, setFilters } = assets.actions;

export default assets.reducer;
