import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api/admin/localization';

export const Home = () => {
  const res1 = useQuery({
    queryKey: adminLocalizationKeys.getAvailableLanguages,
    queryFn: adminLocalizationApi.getAvailableLanguages
  });
  const res2 = useQuery({
    queryKey: adminLocalizationKeys.getAllQuestionnaires,
    queryFn: adminLocalizationApi.getAllQuestionnaires
  });
  const res3 = useQuery({
    queryKey: adminLocalizationKeys.getQuestionnaireSections,
    queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: 'aa69fa8d-bc42-45f2-99cd-4002bfb2140e' })
  });

  const { mutate } = useMutation({
    mutationFn: () => adminLocalizationApi.setQuestionnaireInfo({
      questionSectionTypeId: 'aa69fa8d-bc42-45f2-99cd-4002bfb2140e',
      languageId: '1',
      name: 'new name',
      title: 'new title',
      descriptions: 'nwe descr'
    })
  });

  const { mutate: mutate2 } = useMutation({
    mutationFn: () => adminLocalizationApi.activateQuestionnaireTranslation({
      questionSectionTypeId: 'aa69fa8d-bc42-45f2-99cd-4002bfb2140e',
      languageId: '1'
    })
  });



  return (
    <div>
      <div onClick={mutate}>Change</div>
      <div onClick={() => {
        console.log('activate');
        mutate2();
      }}>Activate</div>
    </div>
  );
};
