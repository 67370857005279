import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../utils/static';
import { Text } from '../common';

export const ButtonSortQuestionnaries = ({ text = 'sort', active = false, amount, onClick }) => {
  const capitalizedText = text.charAt(0).toUpperCase() + text.toLocaleLowerCase().slice(1);
  return (
    <CustomButton active={active} onClick={onClick}>
      <Text color={active && COLORS.white} fontWeight={active && '600'}>
        {capitalizedText}
      </Text>

      {amount && (
        <Amount active={active}>
          <Text color={active ? COLORS.blueAntd : COLORS.white} fontSize={12} fontWeight={600}>
            {amount}
          </Text>
        </Amount>
      )}
    </CustomButton>
  );
};

const CustomButton = styled.div`
  padding: 8px 12px;
  height: 38px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background-color: ${({ active }) => (active ? COLORS.blueAntd : '#f4f4f4')};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const Amount = styled.div`
  padding: 2px 10px;
  height: 21px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  background: ${({ active }) => (active ? COLORS.white : COLORS.blueAntd)};
`;
