import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProfileDocuments } from '../../../containers';
import { ProfileInfo } from '../../../components';
import { ProfileStatus } from '../../../components/investor';
import { Row, Block, Container, BREAKPOINTS } from '../../../ui';
import { useQuery } from 'react-query';
import { kycApi } from '../../../api/kyc';
import { ENABLED_STATUSES_INVESTOR } from '../../../utils/static/kyc';
import { COLORS } from '../../../utils/static/colors';
import { useDispatch } from 'react-redux';
import { investorActions } from '../../../store/investor';
import { LogOutButton, Tabs } from '../../../components/design2.0';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const InvestorProfile = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [selected, setSelected] = useState(state?.lastProfileTab || 0);
  const [menuVisible, setMenuVisible] = useState(false);
  const { data } = useQuery(['getQuestionnaires'], kycApi.getQuestionnaires);

  useEffect(() => {
    dispatch(investorActions.setLastProfileTab(selected));
    console.log('first useEffect');
  }, [selected]);

  const activeQuestsCount = useMemo(() => {
    if (data && data.data) {
      return data.data.reduce(
        (result, item) => result + (ENABLED_STATUSES_INVESTOR[item.status] ? 1 : 0),
        0
      );
    }
  }, [data]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    console.log('hidden scroll');
  }, [menuVisible]);

  return (
    <>
      <Row nowrap style={{ backgroundColor: COLORS.white }}>
        <Tabs
          tab={selected}
          setTab={setSelected}
          menuVisible={menuVisible}
          setMenuVisible={setMenuVisible}
        />

        <Block wide paddingTop={24} paddingX={60} style={{ flexGrow: 1 }}>
          <StyledHeaderRow justifyContent={'flex-end'} marginRight={'-16'} alignCenter>
            <Container hidden mdVisible>
              <MenuOutlined style={{ fontSize: 24 }} onClick={() => setMenuVisible(true)} />
            </Container>
            <LogOutButton />
          </StyledHeaderRow>

          <div onClick={() => setMenuVisible(false)}>
            {selected === 0 && <ProfileInfo />}

            {selected === 1 && <ProfileStatus />}

            {selected === 2 && <ProfileDocuments />}
          </div>
        </Block>
      </Row>
    </>
  );
};

const StyledHeaderRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    justify-content: space-between;
  }
`;
