import React from 'react';
import { useController } from 'react-hook-form';
import { Input } from '../../../../components';

export const FormInput = ({ form, marginTopLabel, ...props }) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });

  return (
    <Input
      {...field}
      {...props}
      error={error && error.message}
      ref={ref}
      marginTopLabel={marginTopLabel}
    />
  );
};
