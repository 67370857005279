export const TRANSFER_TYPES = ['WITHDRAWAL', 'FUNDING'];

export const DISPLAY_TRANSFER_TYPE = {
  WITHDRAWAL: 'Withdrawal',
  FUNDING: 'Funding'
};

export const TRANSFER_STATUSES_LIST = ['PENDING', 'EXECUTED', 'DECLINED'];

export const TRANSACTIONS_ON_PAGE = 5;
