export const LANGUAGES = [
  {
    'id': '1',
    'language': 'Russian',
    'code': 'RU'
  },
  {
    'id': '2',
    'language': 'German',
    'code': 'DE'
  },
  {
    'id': '3',
    'language': 'Latvian',
    'code': 'LV'
  },
  {
    'id': '4',
    'language': 'English',
    'code': 'EN'
  }
];
