import React from 'react';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Button = ({
  type = 'default',
  text,
  size = 'medium',
  disabled = false,
  loading = false,
  onClick,
  block = true,
  ...props
}) => {
  return (
    <StyledAntdButton
      onClick={onClick}
      block={block}
      disabled={disabled}
      size={size}
      type={type}
      loading={loading}
      {...props}
    >
      {text}
    </StyledAntdButton>
  );
};

const StyledAntdButton = styled(AntdButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  &:active {
    opacity: 1 !important;
  }
  ${({ size }) =>
    size === 'medium'
      ? `
  padding: 10px 16px !important;
  height: 40px !important;
  min-width: 40px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  `
      : size === 'large'
      ? `
  padding: 12px 16px !important;
  height: 48px !important;
  min-width: 48px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  `
      : size === 'small'
      ? `
  padding: 8px 12px !important;
  height: 36px !important;
  min-width: 36px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  `   : size === 'x-small'
                                    ? `
  padding: 6px 10px !important;
  height: 32px !important;
  min-width: 32px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  `
      : ''}
  ${({ type, disabled }) =>
    type === 'default' && !disabled
      ? `
  border: 1px solid #006fff ;
  color: #006fff;
  &:hover {
    opacity: 0.64;
  }
  &:active {
  background-color: #C2DCFF !important;
  border: 1px solid #0052BD !important;
  color: #0052BD !important;
  }
  `
      : type === 'filled' && !disabled
      ? `
  border: none;
  background-color: #006FFF ;
  color: #FFF;
  &:hover {
    opacity: 0.64;
  }
  &:active {
    background-color: #0052BD !important;
  }
  `
      : type === 'text' && !disabled
      ? `
  color: #006FFF;
  &:hover {
    opacity: 0.64;
    color: #006FFF !important;
    background-color: transparent !important;
  }
  &:active {
    color: #0052BD !important;
  }
  `
      : ''}
    ${({ disabled, type }) =>
    disabled && type === 'default'
      ? `
    border: 1px solid #C2C2C2 !important;
    color: #808080 !important;
  `
      : disabled && type === 'filled'
      ? `
    background-color: #C2C2C2 !important;
    color: #808080 !important;
    cursor: not-allowed !important;
  `
      : disabled && type === 'text'
      ? `
      color: #808080 !important;
  `
      : ''}
    ${({ loading }) =>
    loading
      ? `
    width: 100% !important;
    opacity: unset !important;
    .ant-btn-loading-icon {
      margin: 0 !important;
    }
    span + span {
      display: none;
    }
    `
      : ''}
`;
