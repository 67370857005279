import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../utils/static/api';
import { useQuery } from 'react-query';

export const Img = ({ src, ...props }) => {
  const { data } = useQuery({
      queryKey: ['img', src],
      queryFn: () => axios.get(src, { responseType: 'blob' }),
      select: data1 => data1.data
    }
  );

  return <img {...props} src={data ? URL.createObjectURL(data) : data} />;
};
