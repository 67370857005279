import React, { useEffect, useState, useMemo } from 'react';
import { Question } from '../Question';
import { Container, InnerBox, TrashIcon, Block } from '../../../../ui';
import styled from 'styled-components';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { Button } from '../../../../components';
import { PlusOutlined } from '@ant-design/icons';

export const QuestionGroup = ({ questionGroup, watch, preFilledQuestions, control, ...props }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const watchedFields = useWatch({
    control,
    name: questionGroup?.visibleConditions
      ? questionGroup?.visibleConditions?.map((item) => item.id)
      : []
  });

  //isVisible logic
  useEffect(() => {
    const conditions = questionGroup.visibleConditions;
    const operator = questionGroup.visibleOperator;

    if (conditions) {
      let isVisible = visible;

      if (operator === 'AND') {
        isVisible = true;
        for (const [index, value] of watchedFields.entries()) {
          if (
            Array.isArray(value)
              ? !value.includes(conditions[index].value)
              : conditions[index].value !== value
          ) {
            isVisible = false;
            break;
          }
        }
      }

      if (operator === 'OR') {
        isVisible = false;
        for (const [index, value] of watchedFields.entries()) {
          if (
            Array.isArray(value)
              ? value.includes(conditions[index].value)
              : conditions[index].value === value
          ) {
            isVisible = true;
            break;
          }
        }
      }

      if (operator === 'NOT') {
        isVisible = true;
        for (const [index, value] of watchedFields.entries()) {
          if (
            Array.isArray(value)
              ? value.includes(conditions[index].value)
              : conditions[index].value === value
          ) {
            isVisible = false;
            break;
          }
        }
      }

      if (visible !== isVisible) setVisible(isVisible);
    }
  }, [watchedFields, questionGroup]);

  if (visible) {
    if (!questionGroup.isMultiple) {
      return (
        <div>
          {questionGroup.title && (
            <Block marginBottom={15}>
              {' '}
              <Typography.Text>{t(`${questionGroup.title}`)}</Typography.Text>
            </Block>
          )}

          {questionGroup.questions &&
            questionGroup.questions.map((questionObj, index) => (
              <Block marginBottom={24}>
                <Question
                  question={questionObj}
                  watch={watch}
                  groupVisibleConditions={questionGroup.visibleConditions}
                  preFilledQuestions={preFilledQuestions}
                  control={control}
                  {...props}
                  key={index}
                />
              </Block>
            ))}
        </div>
      );
    } else {
      return (
        <Multiple
          questionGroup={questionGroup}
          preFilledQuestions={preFilledQuestions}
          watch={watch}
          control={control}
          {...props}
        />
      );
    }
  }

  return null;
};

const Multiple = ({ questionGroup, watch, control, preFilledQuestions, ...props }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `group_${questionGroup.id}`
  });

  const appendValues = useMemo(() => {
    const result = {};

    questionGroup.questions.forEach((item) => (result[item.id] = ''));

    return result;
  }, [questionGroup]);

  useEffect(() => {
    const multipleMin = questionGroup.multipleMin || 1;

    if (append && fields.length < multipleMin) {
      setImmediate(() => {
        append(new Array(multipleMin).fill({ ...appendValues }));
      });
    }
  }, [questionGroup, appendValues, append]);

  const multipleMin = useMemo(() => {
    return questionGroup?.multipleMin || 1;
  }, [questionGroup]);

  return (
    <div>
      {questionGroup.title && (
        <Block marginBottom={15}>
          <Typography.Text>{t(`${questionGroup.title}`)}</Typography.Text>
        </Block>
      )}

      {fields.map((field, fieldIndex) => {
        const groupPreFilledObj = preFilledQuestions[`group_${questionGroup.id}.${fieldIndex}`];

        return (
          <MultipleBox key={field.id} padding={'0'} lastBox={fields.length - 1 === fieldIndex}>
            {questionGroup.questions &&
              questionGroup.questions.map((questionObj, index) => (
                <Container paddingY={8}>
                  <Question
                    question={questionObj}
                    watch={watch}
                    control={control}
                    groupVisibleConditions={questionGroup.visibleConditions}
                    name={`group_${questionGroup.id}.${fieldIndex}.${questionObj.id}`}
                    key={index}
                    preFilledQuestions={preFilledQuestions}
                    disableQuestion={groupPreFilledObj && groupPreFilledObj[questionObj.id]}
                    {...props}
                  />
                </Container>
              ))}

            <DeleteButtonContainer>
              <TrashIcon
                onClick={() => remove(fieldIndex)}
                disabled={groupPreFilledObj || fieldIndex < multipleMin}
              />
            </DeleteButtonContainer>
          </MultipleBox>
        );
      })}

      {!props.disabled && (
        <Button
          onClick={() => append({ ...appendValues })}
          icon={<PlusOutlined />}
          shape='circle'
          size='x-small'
          block={false}
          disabled={questionGroup.multipleMax && fields.length >= questionGroup.multipleMax}
          style={{ margin: '5px 0 25px' }}
        />
      )}
    </div>
  );
};

const MultipleBox = styled(InnerBox)`
  // box-shadow:2px 2px 12px rgb(0 0 0 / 12%);
  // padding: 12px;
  // border-radius: 8px;
  position: relative;
  ${(p) => (p.lastBox ? 'border: none;' : 'padding-bottom: 16px;')}
`;
const QuestionGroupWrapper = styled(Container)`
  border-bottom: 1px solid #dde4e9;

  :last-child {
    padding-bottom: 0;
    border-bottom: unset;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const DeleteButtonContainer = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 2;
`;
