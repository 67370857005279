import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Input as AntdInput } from 'antd';
import { Body4 } from '../../../../ui';
import { Label } from '../Label';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export const Input = forwardRef(
  (
    {
      disabled = false,
      status,
      successMessage = 'Success message',
      onChange,
      iconAfter,
      password = false,
      value,
      label,
      icon,
      required,
      isRequired,
      textTransform = null,
      error,
      maxLength,
      clearRegex = null,
      marginTopLabel,
      suffix,
      type,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <Label required={isRequired || required} label={label} marginTop={marginTopLabel}>
          {password === false ? (
            <StyledAntdInput
              type={type}
              maxLength={maxLength}
              ref={ref}
              onChange={onChange}
              placeholder={label}
              disabled={disabled}
              status={status}
              suffix={suffix}
              password={password}
              value={value}
              {...props}
            />
          ) : (
            <StyledAntdPasswordInput
              type={type}
              maxLength={maxLength}
              ref={ref}
              value={value}
              onChange={onChange}
              placeholder={label}
              disabled={disabled}
              status={status}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              password={password}
              {...props}
            />
          )}
        </Label>
        {error && (
          <Body4 marginTop={8} color={'#FF424F'}>
            {error}
          </Body4>
        )}

        {status === 'success' && (
          <Body4 marginTop={8} color={'#00AB56'}>
            {successMessage}
          </Body4>
        )}
      </>
    );
  }
);

const StyledAntdInput = styled(AntdInput)`
  padding: ${({ status, password }) =>
    status === 'error' || status === 'success' || password === true
      ? '8px 8px 8px 16px'
      : '9.5px 8px 9.5px 16px'};
  height: 40px !important;
  font-size: 14px;
  color: #27272a;
  border-color: #dddde3;
  border-radius: 4px;
  &:hover {
    border-color: #006fff;
  }
  &.ant-input:placeholder-shown {
    color: #808089;
  }
  ${({ status }) => (status === 'success' ? 'border-color: #00AB56' : '')}
`;

const StyledAntdPasswordInput = styled(StyledAntdInput.Password)`
  padding: ${({ status, password }) =>
    status === 'error' || status === 'success' || password === true
      ? '8px 8px 8px 16px'
      : '9.5px 8px 9.5px 16px'};
  height: 40px !important;
  font-size: 14px;
  color: #27272a;
  border-color: #dddde3;
  border-radius: 4px;
  &:hover {
    border-color: #006fff;
  }
  &.ant-input:placeholder-shown {
    color: #808089;
  }
  ${({ status }) => (status === 'success' ? 'border-color: #00AB56' : '')}
`;

