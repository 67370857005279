import React, { useEffect, useMemo, useState } from 'react';
import { sendEmailCode, verifyEmail } from '../../../../api';
import { Button, Text, Input } from '../../../../components';
import { Block, Container, Row } from '../../../../ui';
import { COLORS } from '../../../../utils/static';
import { useDispatch, useSelector } from 'react-redux';
import { investorActions } from '../../../../store/investor';
import { entitiesActions } from '../../../../store/entities';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

export const Step2 = ({ stepNumber, goNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.investor);
  const [code, setCode] = useState('');

  useEffect(() => {
    console.log('send code');
    handleSendCode();
  }, []);

  const handleSendCode = () => {
    sendEmailCode().then();
  };

  const handleNext = async () => {
    try {
      const res = await verifyEmail(code);

      console.log(res);
      if (res.status === 200) {
        const { verifiedEmail } = res.data;
        dispatch(investorActions.setVerifiedEmail(verifiedEmail));

        goNext();
      }
    } catch (e) {
      const errorMessage = e.response?.data.message;
      if (errorMessage === 'CONFIRM_CODE_IS_WRONG') {
        dispatch(entitiesActions.addToast('Wrong code'));
      }
    }
  };

  const disableButton = useMemo(() => {
    return !code;
  }, [code]);

  return (
    <>
      <Container marginTop={24}>
        <Text color={COLORS.greyText}>
          {t('The verification code has been sent on your e-mail:')}{' '}
          <Text color={COLORS.blueAntd}>{email}</Text>
        </Text>
      </Container>

      <Form layout='vertical' style={{ maxWidth: '490px', width: '100%' }}>
        <Block wide marginTop={60}>
          <Input
            label={t('Verification code')}
            required
            placeholder={t('Enter code')}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Block>

        <Block wide>
          <Row marginTop={40} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'space-between'} wide>
            <Block maxWidth={140} wide>
              <Button
                type='filled'
                size='large'
                text={t('Next')}
                onClick={handleNext}
                disabled={disableButton}
              />
            </Block>

            <Row alignCenter>
              <Text>{t('Didn’t receive the code?')}</Text>

              <Block marginLeft={'-8'}>
                <Button type='text' size='large' text={t('Try again')} onClick={handleSendCode} />
              </Block>
            </Row>
          </Row>
        </Block>
      </Form>
    </>
  );
};
