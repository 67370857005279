import axios from 'axios';
import { API_URL } from '../../../utils/static';
import { userActions } from '../../../store/user';
import { kycApi } from '../../../api/kyc';

export * from './formatData';

export const step1 = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { email, phone, password } = data;
      const body = { email, phone, password };
      const res = await axios.post(`${API_URL}/api/kyc/v1/investor`, body);

      const { accessToken, role, ...user } = res.data;
      localStorage.setItem('token', accessToken);
      localStorage.setItem('userRole', role);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      // TODO: EMAIL_EXIST
      resolve(user);
    } catch (e) {
      reject(e);
    }
  });
};

export const verifyEmail = ({ verifyEmail }) => {
  return axios.post(`${API_URL}/api/v1/investor/verifyEmail`, { code: verifyEmail });
};

export const verifyPhone = ({ verifyPhone }) => {
  return axios.post(`${API_URL}/api/v1/investor/verifyPhone`, { code: verifyPhone });
};

export const step4 = (data) => {
  return axios.put(`${API_URL}/api/kyc/v1/investor`, { account: 'PERSONAL', ...data });
};

export const step5 = (data, dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { idDocument, temporaryResidentship } = data;
      const formData = new FormData();
      formData.append('step', '5');
      formData.append('passport', idDocument);
      formData.append('temporaryResidentship', temporaryResidentship);

      await axios.put(`${API_URL}/api/kyc/v1/investor`, formData);

      if (dispatch) dispatch(userActions.setRole('investor'));
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const sendPhoneCode = () => {
  return axios.get(`${API_URL}/api/v1/investor/sendPhoneSms`);
};

export const sendEmailCode = () => {
  return axios.get(`${API_URL}/api/v1/investor/sendEmailSms`);
};

export const getCountries = (languageId) => {
  return axios.get(`${API_URL}/api/kyc/v1/countries`, {params: {languageId: languageId}});
};

export const tier2 = ({ utilityBill, proofOfFunds }) => {
  const validatePOF = (data) => {
    const isError = data.some((item) => !item.subtype);

    return !isError;
  };

  return new Promise(async (resolve, reject) => {
    try {
      const isProofOfFunds = validatePOF(proofOfFunds);
      if (!isProofOfFunds) reject('DOC_SUBTYPE_REQUIRED');

      await kycApi.uploadDoc({
        file: utilityBill,
        type: 'UTILITY_BILL',
        subtype: 'OTHER'
      });

      await proofOfFunds.forEach((item) => {
        kycApi.uploadDoc({
          file: item.file,
          type: 'PROOF_OF_FUNDS',
          subtype: item.subtype
        });
      });

      await kycApi.applyTier2();
      resolve();
    } catch (e) {
      console.error('TIER_2_UPGRADE', e);
      reject();
    }
  });
};
