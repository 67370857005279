import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  transactions: null,
  currentTransaction: null,
  filters: null,
  newTransfer: null
};

const wallet = createSlice({
  name: 'walletReducer',
  initialState: initialState,
  reducers: {
    setTransactions(state, action) {
      state.transactions = action.payload;
    },
    setCurrentTransaction(state, action) {
      state.currentTransaction = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setNewTransfer(state, action) {
      state.newTransfer = action.payload;
    }
  }
});

export const { setTransactions, setCurrentTransaction, setFilters, setNewTransfer } =
  wallet.actions;

export default wallet.reducer;
