import React from 'react';
import { history } from '../../routing';
import { OptionCard, RegContainer } from '../../components';
import { Container, Row } from '../../ui';
import CreateAccountIcon from '../../assets/entry-pages/create-account.svg';
import LoginAccountIcon from '../../assets/entry-pages/login-account.svg';
import logoCaseCapital from '../../assets/logo-case-capital.png';
import { useTranslation } from 'react-i18next';

export const EnterPage = () => {
  const { t } = useTranslation();

  return (
    <RegContainer type='centered' style={{ maxWidth: 'unset' }}>
      <Container marginTop={20} marginBottom={48}>
        <img src={logoCaseCapital} style={{ maxWidth: 355, width: '100%' }} alt='logo' />
      </Container>

      <Row wide flexDirection={'row'} justifyContent={'center'} gap={64}>
        <OptionCard
          title={t('Create a new \naccount')}
          onClick={() => history.push('/registration/investor')}
          icon={CreateAccountIcon}
        />

        <OptionCard
          title={t('Log in existing \naccount')}
          onClick={() => history.push('/login')}
          icon={LoginAccountIcon}
        />
      </Row>
    </RegContainer>
  );
};
