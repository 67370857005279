import React, { useMemo } from 'react';
import { GU, useTheme, CircleGraph } from '@aragon/ui';
import ConfigureStepsItem from './ConfigureStepItem';
import { Title3 } from '../../../ui';
import styled from 'styled-components';

function StepsPanel({ step, steps, title, width = 224, setStep, isAdmin }) {
  const theme = useTheme();
  step = step - 1;

  // Mark identical siblings to only show the last step
  const [groupedSteps, displayedSteps] = useMemo(() => {
    // these get updated by the .map() to avoid another iteration
    let displayCount = 0;
    const skippedSteps = [];

    const groupedSteps = steps.map((step, index) => {
      if (isAdmin) {
        const hiddenCount = index - displayCount;
        displayCount++;
        return [index, index - hiddenCount, true];
      } else {
        if (skippedSteps.includes(index)) {
          displayCount++;
          return [index, index - 1, null]
        }

        const hiddenCount = index - displayCount;

        if (step !== steps[index + 1]) {
          displayCount++;
          return [index, index - hiddenCount, true];
        }

        let statusIndex = index;
        const indexes = [statusIndex];
        while (step === steps[statusIndex + 1] && statusIndex < steps.length) {
          statusIndex++;
          indexes.push(statusIndex);
          skippedSteps.push(index + 1);
        }

        return [
          // The index used for the status in the panel (last of the group)
          indexes,
          // The index used for the display in the panel (first of the group)
          index - hiddenCount,
          // Do not display the step
          false
        ];
      }
    });

    return [groupedSteps, displayCount];
  }, [steps]);

  return (
    <StepPanelContainer theme={theme} width={width}>
      <Title3 marginBottom={2 * GU} textAlign={'center'}>
        {title}
      </Title3>

      <CircleGraphContainer>
        <CircleGraph value={groupedSteps[step][1] / displayedSteps} size={162} />
        <CircleGraphStepsCounter>
          {`${groupedSteps[step][1] + 1}/${displayedSteps}`}
        </CircleGraphStepsCounter>
      </CircleGraphContainer>

      <StepsContainer>
        {groupedSteps.filter((item) => item[2] !== null).map(
          ([statusIndex, displayIndex, show], index) =>
            (
              <ConfigureStepsItem
                key={index}
                currentStep={groupedSteps[step][0]}
                label={Array.isArray(statusIndex) ? steps[statusIndex[0]] : steps[statusIndex]}
                step={statusIndex}
                stepNumber={displayIndex + 1}
                show={show}
                onClick={setStep ? () => setStep(displayIndex) : null}
              />
            )
        )}
      </StepsContainer>
    </StepPanelContainer>
  );
}

export default StepsPanel;

const StepPanelContainer = styled.aside`
    width: 100%;
    min-height: 100%;
    padding-top: ${5 * GU}px;
    background: ${(p) => p.theme.surface};
    box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.05);
    max-width: ${(p) => (p.width ? `${p.width}px` : '224px')};
    min-width: ${(p) => (p.width ? `${p.width}px` : '224px')};
`;
const Title = styled.h3`
    margin: 0 0 ${2 * GU}px 0;
    text-align: center;
`;
const CircleGraphContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 162px;
`;
const CircleGraphStepsCounter = styled.p`
    position: absolute;
    top: unset;
    bottom: ${3 * GU}px;
    font-size: 20px;
    color: #8e97b5;
    opacity: 0.7;
`;
const StepsContainer = styled.div`
    padding: ${5 * GU}px ${2.5 * GU}px ${3 * GU}px ${3 * GU}px;
`;
