import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from 'antd';
import { Block, Container, Row } from '../../../ui';
import { Button, Modal, Select, Text } from '../common';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { adminKYCApi } from '../../../api/admin/kyc';
import { kycApi } from '../../../api/kyc';
import { entitiesActions } from '../../../store/entities';

export const ModalDocument = ({
  isModalOpen,
  setIsModalOpen,
  visible,
  onClose,
  onSuccess,
  adminView = false,
  investorId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const { data: sections } = useQuery(
    ['getDocumentsSections'],
    adminView ? () => adminKYCApi.getDocumentsSections(investorId) : kycApi.getDocumentsSections
  );
  const { data: types, mutate: fetchTypes } = useMutation(
    adminView ? adminKYCApi.getDocumentSectionTypes : kycApi.getDocumentSectionTypes
  );
  const { mutateAsync: requestDocument } = useMutation(
    adminView ? adminKYCApi.addDocumentRequest : kycApi.requestDocument
  );

  const docsSectionsList = useMemo(() => (sections ? sections.data : null), [sections]);
  const docsTypesList = useMemo(() => (types ? types.data : null), [types]);

  useEffect(() => {
    if (visible) {
      setSelectedType(null);
      setSelectedSection(null);
    }
  }, [visible]);

  useEffect(async () => {
    try {
      await fetchTypes({
        sectionId: docsSectionsList.find((section) => section.id === selectedSection)?.id,
        investorId: investorId
      });
      setSelectedType(null);
    } catch (e) {
      console.error('FETCH_DOCS_TYPES', e);
    }
  }, [selectedSection]);

  const onRequestDoc = async () => {
    try {
      await requestDocument({
        docTypeId: docsTypesList.find((type) => type.id === selectedType)?.id
      });
      if (onSuccess) await onSuccess();
      dispatch(entitiesActions.addToast(t('Successfully added')));
      setSelectedSection(null);
      setSelectedType(null);
      onClose();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast(t('Something went wrong')));
    }
  };

  return (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <Typography.Title level={3}>{t('Adding document')}</Typography.Title>

      <Container marginTop={40} marginBottom={8}>
        <Text fontSize={14} fontWeight={700}>
          {t('Section')}
        </Text>
      </Container>

      <Select
        allowClear
        placeholder={t('Select an item')}
        onChange={setSelectedSection}
        disabled={!docsSectionsList}
        value={selectedSection}
        options={
          docsSectionsList
            ? docsSectionsList.map((item) => {
                return { value: item.id, label: String(item.name).toLocaleLowerCase() };
              })
            : []
        }
      />

      <Container marginTop={24} marginBottom={8}>
        <Text fontSize={14} fontWeight={700}>
          {t('Type')}
        </Text>
      </Container>

      <Select
        allowClear
        placeholder={t('Select an item')}
        placement='topLeft'
        value={selectedType}
        onChange={setSelectedType}
        disabled={!docsTypesList || docsTypesList.length === 0}
        options={
          docsTypesList
            ? docsTypesList.map((item) => {
                return { value: item.id, label: String(item.name).toLocaleLowerCase() };
              })
            : []
        }
      />

      <Row>
        <Block marginTop={40}>
          <Button
            type='filled'
            size='large'
            text={t('Add document')}
            onClick={onRequestDoc}
            disabled={!selectedType}
          />
        </Block>
      </Row>
    </Modal>
  );
};
