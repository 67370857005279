import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  info: null
};

const checkout = createSlice({
  name: 'checkoutReducer',
  initialState,
  reducers: {
    setInfo(state, action) {
      state.info = action.payload;
    }
  }
});

export const { setInfo } = checkout.actions;

export default checkout.reducer;
