import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api/admin/localization';
import { Box } from '../../../components/customAragon/Box';
import { Typography, Button } from 'antd';
import { Container, PageContainer } from '../../../ui/layout';
import { history } from '../../../routing';
import { Title2 } from '../../../ui/typography';
import { useTranslation } from 'react-i18next';

export const Localization = () => {
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: adminLocalizationKeys.getAllQuestionnaires,
    queryFn: adminLocalizationApi.getAllQuestionnaires,
    select: data1 => data1.data
  });

  return (
    <PageContainer>
      <Container paddingY={24}>
        <Title2>{t('Localization')}</Title2>
      </Container>

      {data && data?.map((quest => (
        <Box
          heading={quest.type}
          padding={16}
        >
          {quest.name && <Typography.Title level={5}>{quest.name}</Typography.Title>}
          <Typography.Text level={5}>{quest.title}</Typography.Text>

          <Container marginTop={12}>
            <Button mode={'strong'} onClick={() => history.push(`/localization/${quest.id}`)} type="primary">
              Edit
            </Button>
          </Container>
        </Box>
      )))}
    </PageContainer>
  );
};
