import React from 'react';
import { Box, InnerBox, Pagination } from '../../../../components';
import { Row, Col, Body2Light } from '../../../../ui';

export const TableBoxContainer = ({
  title,
  aside,
  table,
  paginationSettings,
  children,
  boxProps,
  innerBoxProps,
  rowProps
}) => {
  return (
    <Box padding={0} {...boxProps}>
      <InnerBox noBorder {...innerBoxProps}>
        <Row justifyContent={'space-between'} {...rowProps}>
          <Body2Light>{title}</Body2Light>

          <Col auto>{aside}</Col>
        </Row>

        {children}
      </InnerBox>

      {table}

      {paginationSettings?.visible && <Pagination {...paginationSettings} />}
    </Box>
  );
};
