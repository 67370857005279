import React, { useRef, useState } from 'react';
import styles from '../style.module.css';

const ImageUpload = ({ value = null, preview = null, onChange }) => {
  const [image, setImage] = useState({ preview: '', raw: '' });
  const myRef = useRef();

  const handleChange = (e) => {
    if (e.target.files.length) {
      onChange
        ? onChange(e.target.files[0])
        : setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
          });

      myRef.current.value = '';
    }
  };

  const handleClick = () => {
    myRef.current.click();
  };

  // const handleUpload = async e => {
  //     e.preventDefault();
  //     const formData = new FormData();
  //     formData.append("image", image.raw);
  //
  //     await fetch("YOUR_URL", {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "multipart/form-data"
  //         },
  //         body: formData
  //     });
  // };

  const previewSrc = value ? URL?.createObjectURL(value) : preview || image.preview;

  return (
    <div style={{ height: '116px' }}>
      <label onClick={handleClick}>
        {previewSrc ? (
          <div className={styles['imagePreview']}>
            <img src={previewSrc} alt='' />
          </div>
        ) : (
          <div className={styles['imageUpload']} />
        )}
      </label>
      <input type='file' ref={myRef} style={{ display: 'none' }} onChange={handleChange} />
      <br />
    </div>
  );
};

export default ImageUpload;
