import React from 'react';
import { TierProgress } from '../common';

export const TierProgressList = ({ tier }) => {
  return (
    <>
      <TierProgress label='Tier 1' progress={1 <= tier ? 100 : 0} disabled={0 > tier} />
      <TierProgress label='Tier 2' progress={2 <= tier ? 100 : 0} disabled={1 > tier} />
      <TierProgress label='Tier 3' progress={3 <= tier ? 100 : 0} disabled={2 > tier} />
    </>
  );
};
