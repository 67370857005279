import React, { useState } from 'react';
import { Header } from '@aragon/ui';
import { Button, PageContainer, Title2 } from '../../../../../ui';
import { Tabs, InvestorsTableBox } from '../../../../../components';
import { INVESTOR_STATUS_TABS, LEGAL_STATUS_TABS } from '../../../../../utils/static';
import { useTranslation } from 'react-i18next';

const TITLES = {
  PERSONAL: 'Individuals clients',
  COMPANY: 'Legal entities'
};
const TABS = {
  PERSONAL: INVESTOR_STATUS_TABS,
  COMPANY: LEGAL_STATUS_TABS
};
export const InvestorsView = ({ investors, fetchInvestors, INVESTORS_ON_PAGE, type }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const tabsList = TABS[type] || INVESTOR_STATUS_TABS;
  return (
    <PageContainer>
      <Header
        primary={<Title2>{t(TITLES[type]) || t('Investors')}</Title2>}
        // secondary={<Button label={'Create Profile'} mode={'strong'} paddingX={'12'} />}
      />

      <Tabs items={tabsList?.map(item => t(item))} selected={selectedTab} onChange={setSelectedTab} />

      <InvestorsTableBox
        investors={investors}
        updateSearchParams={fetchInvestors}
        INVESTORS_ON_PAGE={INVESTORS_ON_PAGE}
        selectedTab={selectedTab}
      />
    </PageContainer>
  );
};
