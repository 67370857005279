import React from 'react';
import { ImageUploadBox } from '../../../../components';
import { useController } from 'react-hook-form';

export const FilePicker = ({ form }) => {
  const { field } = useController({
    ...form,
    rules: { required: 'Required' },
    defaultValue: ''
  });

  return <ImageUploadBox {...field} label={form.name} />;
};
