import { getFileSrc } from '../../../utils/helpers';
import { TRANSACTIONS_ON_PAGE, TRANSFER_STATUSES_LIST, TRANSFER_TYPES } from '../../static/wallet';

export const addressToFront = (data) => {
  return {
    addressId: data.id,
    country: data.country,
    city: data.city,
    address: data.address
  };
};
export const idDocsToFront = (data) => {
  return {
    idDocumentId: data.id,
    idDocumentPhotoUrl: getFileSrc(data.idDocumentPhotoURL),
    selfieWithIdPhotoUrl: getFileSrc(data.selfieWithIdPhotoURL),
    idDocument: data.idDocument,
    documentNumber: data.documentNumber,
    personalCode: data.personalCode,
    birthDate: new Date(data.birthDate),
    issuingState: data.issuingState,
    issuingOrganization: data.issuingOrganization,
    dateOfIssuance: new Date(data.dateOfIssuance),
    dateOfExpiry: new Date(data.dateOfExpiry),
    maritalStatus: data.maritalStatus,
    nameSurnameOfSpouse: data.nameSurnameOfSpouse
  };
};

export const idDocsToServer = (data) => {
  const { idDocumentPhotoUrl, selfieWithIdPhotoUrl, ...otherData } = data;

  return { ...otherData };
};

export const investorInfoToFront = (data) => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    dateOfBirth: new Date(data.dateOfBirth),
    phone: data.phone,
    email: data.email,
    taxResidency: data.taxResidency,
    tinNR: data.tinNR
  };
};

export const transactionFiltersToBack = (filters) => {
  const defaultFilter = {
    take: TRANSACTIONS_ON_PAGE,
    reverse: true
  };

  if (filters) {
    return {
      type: TRANSFER_TYPES[filters.typeIndex - 1],
      status: TRANSFER_STATUSES_LIST[filters.statusIndex - 1],
      search: filters.search && filters.search,
      skip: filters.page * TRANSACTIONS_ON_PAGE,
      ...defaultFilter
    };
  }

  return defaultFilter;
};
