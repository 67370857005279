import React from 'react';
import { useController } from 'react-hook-form';
import { AutoComplete, Body4 } from '../../../../ui';
import { useCountries } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { Input, Label } from '../../../../components';

export const CountryInput = ({ form, label = 'Country', disabled, required, ...props }) => {
  const { t } = useTranslation();
  const COUNTRIES = useCountries();
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    rules: {
      ...form.rules,
      validate: (v) =>
        !COUNTRIES ||
        !v ||
        COUNTRIES.filter((item) => item.country === v)[0] ||
        t('Select country from list')
    },
    defaultValue: ''
  });

  if (disabled) {
    return <Input {...field} label={label} error={error && error.message} {...props} disabled />;
  }

  return (
    <AutoComplete
      style={{ width: '100%' }}
      {...field}
      label={label}
      items={COUNTRIES && COUNTRIES.map((item) => item.country)}
      error={error && error.message}
      required={required}
      block
      {...props}
    />
  );
};

export const ResidencyInput = (props) => {
  const { t } = useTranslation();
  return <CountryInput {...props} label={t('Residency')} />;
};

export const CitizenshipInput = (props) => {
  const { t } = useTranslation();
  return <CountryInput {...props} label={t('Citizenship')} />;
};

export const CountrySelect = ({ form, label, marginTopLabel, required, ...props }) => {
  const COUNTRIES = useCountries();
  const { t } = useTranslation();

  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    rules: {
      ...form.rules,
      validate: (v) =>
        !COUNTRIES ||
        !v ||
        COUNTRIES.filter((item) => item.id === v)[0] ||
        t('Select country from list')
    },
    defaultValue: ''
  });
  return (
    <>
      <Label label={label} marginTop={marginTopLabel} required={required}>
        <Select
          allowClear
          placeholder={label}
          size='large'
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={COUNTRIES?.map((item) => ({ value: item.id, label: item.country }))}
          {...props}
          {...field}
          error={error && error.message}
        />
      </Label>
      {error && (
        <Body4 marginTop={8} color={'#FF424F'}>
          {error.message}
        </Body4>
      )}
    </>
  );
};
