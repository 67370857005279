import React, { useEffect, useState } from 'react';
import { EditProfileInfoView } from '../View';
import { addressTemplate, idDocumentsTemplate, investorInfoTemplate } from '../../../utils/static';
import {
  isObjectEmpty,
  idDocsToFront,
  investorInfoToFront,
  addressToFront,
  idDocsToServer
} from '../../../utils/helpers';
import { investorActions } from '../../../store/investor';
import { useDispatch } from 'react-redux';

export const EditProfileInfo = ({ investor, saveTab, saveCallback }) => {
  const dispatch = useDispatch();
  const [investorInfo, setInvestorInfo] = useState({ ...investorInfoTemplate });
  const [legalAddress, setLegalAddress] = useState({ ...addressTemplate });
  const [actualAddress, setActualAddress] = useState({ ...addressTemplate });
  const [idDocs, setIdDocs] = useState({ ...idDocumentsTemplate });

  useEffect(() => {
    if (!isObjectEmpty(investor)) {
      setLegalAddress(addressToFront(investor.addresses?.legal));
      setActualAddress(addressToFront(investor.addresses?.actual));
      setIdDocs(idDocsToFront(investor.idDocument));
      setInvestorInfo(investorInfoToFront(investor));
    }
  }, [investor]);

  useEffect(() => {
    if (saveTab === 'ProfileInfo') {
      const saveInvestor = investorActions.updateInvestor(
        {
          id: investor.id,
          investorInfo: investorInfo,
          legalAddress: legalAddress,
          actualAddress: actualAddress,
          idDocs: idDocsToServer(idDocs)
        },
        dispatch
      );

      saveInvestor.then(saveCallback, () => {
        console.log('reject');
      });
    }
  }, [saveTab]);

  return (
    <EditProfileInfoView
      investorInfo={investorInfo}
      setInvestorInfo={setInvestorInfo}
      legalAddress={legalAddress}
      setLegalAddress={setLegalAddress}
      actualAddress={actualAddress}
      setActualAddress={setActualAddress}
      idDocs={idDocs}
      setIdDocs={setIdDocs}
    />
  );
};
