import React, { useState } from 'react';
import { history } from '../../../../routing';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../store/user';
import { Button as ANTDButton, SupportSidePanel } from '../../../../components';
import { SideBarConfigInvestor, SideBarConfigAdmin, SideBarConfigSuperAdmin } from './config';
import { Col, Container, Button, Block, Row } from '../../../../ui';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import logoImg from '../../../../assets/project_logo_full.png';
import { useLocation } from 'react-router-dom';
export const AppSideBar = ({ userRole }) => {
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(userActions.logout());
  };

  return (
    <SideBarContainer>
      {userRole === 'investor' && <InvestorList Logout={Logout} />}

      {userRole === 'admin' && <AdminList Logout={Logout} config={SideBarConfigAdmin} />}

      {userRole === 'superAdmin' && <AdminList Logout={Logout} config={SideBarConfigSuperAdmin} />}
    </SideBarContainer>
  );
};

const InvestorList = ({ Logout }) => {
  const { t } = useTranslation();
  const [openedSupport, setOppenedSupport] = useState(false);

  return (
    <StickyWrapper>
      <List>
        {SideBarConfigInvestor.map((item, key) => (
          <ListItem item={item} key={key} />
        ))}
      </List>

      <Col padding={24} auto>
        <Button label={t('Support')} onClick={() => setOppenedSupport(true)} wide />

        <Container marginTop={16}>
          <Button label={t('Logout')} wide onClick={Logout} />
        </Container>
      </Col>

      <SupportSidePanel opened={openedSupport} onClose={() => setOppenedSupport(false)} />
    </StickyWrapper>
  );
};

const AdminList = ({ Logout, config }) => {
  const { t } = useTranslation();
  return (
    <StyledBlock width={284}>
      <Row
        nowrap
        flexDirection={'column'}
        height={'100%'}
        paddingBottom={60}
        justifyContent={'space-between'}
      >
        <div>
          <Img src={logoImg} alt='' />

          <List>{config && config.map((item, key) => <ListItem item={item} key={key} />)}</List>
        </div>
        <Container paddingX={40} auto>
          <ANTDButton text={t('Logout')} wide onClick={Logout} />
        </Container>
      </Row>
    </StyledBlock>
  );
};

const ListItem = ({ item }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div>
      <Li onClick={() => history.push(item.href)} active={pathname === item.href}>
        <Typography.Text>{t(item.title)}</Typography.Text>
        {/* {item.icon && <Icon src={item.icon} />} */}
      </Li>
      <Container paddingLeft={28}>
        {item.subpages &&
          item.subpages.map((subpage, key) => (
            <Li
              onClick={() => history.push(subpage.href)}
              active={pathname === subpage.href}
              // justifyContent={'flex-end'}
              height={32}
              key={key}
            >
              {/*<Icon src={subpage.icon} />*/}
              <Typography.Text>{t(subpage.title)}</Typography.Text>
            </Li>
          ))}
      </Container>
    </div>
  );
};

const SideBarContainer = styled.div`
  height: 100%;
  background: #ffffff;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.05);
`;
const StickyWrapper = styled.div`
  height: calc(100vh - 65px);
  position: sticky;
  top: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 0 17px 0;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const Li = styled.div`
  min-height: ${(p) => (p.height ? `${p.height}px` : '40px')};
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'flex-start')};
  cursor: pointer;
  transition: 0.2s ease-out;

  ${({ active }) =>
    active
      ? `
      span {
      color: #006fff;
    }
  `
      : ``}
  &:hover {
    span {
      color: #006fff;
    }
  }
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const StyledBlock = styled(Block)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: #f6f9ff;
  height: 100vh;
`;

const Img = styled.img`
  margin: 24px 40px 60px;
  width: 204px;
  height: 62px;
`;
