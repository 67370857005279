import React from 'react';
import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';
import Icon from '@ant-design/icons';

const ArrowSvg = (props) => (
  <svg viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='material-symbols:keyboard-arrow-down-rounded'>
      <path
        id='Vector'
        d='M12 15.475C11.8667 15.475 11.7377 15.45 11.613 15.4C11.4877 15.35 11.3834 15.2834 11.3 15.2L6.70005 10.6C6.51672 10.4167 6.42505 10.1834 6.42505 9.90005C6.42505 9.61671 6.51672 9.38338 6.70005 9.20005C6.88338 9.01672 7.11672 8.92505 7.40005 8.92505C7.68338 8.92505 7.91672 9.01672 8.10005 9.20005L12 13.1L15.9 9.20005C16.0834 9.01672 16.3167 8.92505 16.6 8.92505C16.8834 8.92505 17.1167 9.01672 17.3 9.20005C17.4834 9.38338 17.575 9.61671 17.575 9.90005C17.575 10.1834 17.4834 10.4167 17.3 10.6L12.7 15.2C12.6 15.3 12.4917 15.3707 12.375 15.412C12.2584 15.454 12.1334 15.475 12 15.475Z'
        fill='#516EE0'
      />
    </g>
  </svg>
);

export const Collapse = ({ label = 'Name', children, ...props }) => {
  const items = [
    {
      key: '1',
      label: label,
      children: children
    }
  ];

  return (
    <StyledAntdCollapse
      ghost
      expandIconPosition='end'
      expandIcon={({ isActive }) => (
        <Icon
          rotate={isActive ? -180 : 0}
          style={{ width: '24px', height: '24px' }}
          component={ArrowSvg}
        />
      )}
      items={items}
    />
  );
};

const StyledAntdCollapse = styled(AntdCollapse)`
  .ant-collapse-content-box,
  .ant-collapse-header {
    padding: 0px !important;
    align-items: center !important;
  }
  .ant-collapse-header {
    width: min-content !important;
  }
  .ant-collapse-expand-icon {
    margin-inline: 0 !important;
    padding-inline-start: 8px !important;
  }
  .ant-collapse-header-text {
    margin-inline-end: unset !important;
    font-size: 18px;
    flex: none !important;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;
