import { history } from "../../../../routing";
import { DetailsButton } from "../../components";

export const columns = [
  {
    title: 'Client name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tier',
    dataIndex: 'tier',
    key: 'tier'
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Representatives',
    dataIndex: 'representatives',
    key: 'representatives'
  },
  {
    title: 'Client status',
    dataIndex: 'accountStatus',
    key: 'accountStatus'
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => <DetailsButton onClick={() => history.push(`/investor/${record.id}`)} />
  }
];
