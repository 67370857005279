import React from 'react';
import { Row } from '../../../../ui';
import { COLORS } from '../../../../utils/static';
import { Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export const ProfitLine = ({ profit, profitPercent, currency }) => {
  return (
    <Row alignItems={'center'}>
      {profit < 0 ? (
        <DownOutlined style={{ marginTop: '-2px', color: COLORS.red }} />
      ) : (
        <UpOutlined
          style={{ marginTop: '-2px', color: COLORS.mintGreen }}
        />
      )}
      {profit} {currency}&ensp;
      <Typography.Text color={COLORS.greyMedium}>({Number(profitPercent)}%)</Typography.Text>
    </Row>
  );
};
