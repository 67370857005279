import axios from 'axios';
import { API_URL } from '../../../utils/static/api';

export const getOrders = (params) => {
  return axios.get(`${API_URL}/api/v1/investor/orders`, { params: params });
};

export const cancelOrder = (id) => {
  return axios.put(`${API_URL}/api/v1/investor/assets/order/status`, {
    status: 'CANCELED',
    orderId: id
  });
};
