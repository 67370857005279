import React from 'react';
import { FormInput } from '../../components';

export const TextInput = ({ form, ...props }) => {
  return (
    <FormInput
      form={{
        ...form,
        rules: { required: 'Required' }
      }}
      label={form.name}
      {...props}
    />
  );
};
