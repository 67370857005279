import React, { useEffect, useMemo, useState } from 'react';
import { Select, Table, Text } from '../common';
import { BREAKPOINTS, Block, Container, Row } from '../../../ui';
import { useMutation, useQuery } from 'react-query';
import { adminKYCApi } from '../../../api/admin/kyc';
import { kycApi } from '../../../api/kyc';
import { ButtonSortQuestionnaries } from '../ButtonSortQuestionnaries';
import {
  STATUSES_IN_PROGRESS,
  STATUS_DRAFT,
  STATUS_NEW,
  STATUS_PASSED,
  STATUS_PROCESSING,
  STATUS_REJECTED
} from '../../../utils/static/kyc';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const TableDocument = ({ data, onClick, adminView = false, investorId }) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('new');
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const { data: sections } = useQuery(
    ['getDocumentsSections'],
    adminView ? () => adminKYCApi.getDocumentsSections(investorId) : kycApi.getDocumentsSections
  );
  const { data: types, mutate: fetchTypes } = useMutation(
    adminView ? adminKYCApi.getDocumentSectionTypes : kycApi.getDocumentSectionTypes
  );
  const docsSectionsList = useMemo(() => (sections ? sections.data : null), [sections]);
  const docsTypesList = useMemo(() => (types ? types.data : null), [types]);

  useEffect(async () => {
    try {
      await fetchTypes({
        sectionId: docsSectionsList.find((section) => section.id === selectedSection)?.id,
        investorId: investorId
      });
      setSelectedType(null);
    } catch (e) {
      console.error('FETCH_DOCS_TYPES', e);
    }
  }, [selectedSection]);

  const tableData = [];

  data.map((item) => tableData.push(item.docs));

  const docsStatusObj = [];

  tableData.flat().map((item) => {
    docsStatusObj.push(item);
  });

  return (
    <>
      <StyledFilterRow marginBottom={32} gap={24} nowrap>
        <Block maxWidth={328} wide>
          <Container marginBottom={8}>
            <Text fontSize={14} fontWeight={700}>
              {t('Section')}
            </Text>
          </Container>

          <Select
            allowClear
            placeholder={t('Select an item')}
            onChange={setSelectedSection}
            disabled={!docsSectionsList}
            value={selectedSection}
            options={
              docsSectionsList
                ? docsSectionsList.map((item) => {
                    return { value: item.id, label: String(item.name).toLocaleLowerCase() };
                  })
                : []
            }
          />
        </Block>

        <Block maxWidth={328} wide>
          <Container marginBottom={8}>
            <Text fontSize={14} fontWeight={700}>
              {t('Type')}
            </Text>
          </Container>

          <Select
            allowClear
            placeholder={t('Select an item')}
            value={selectedType}
            onChange={setSelectedType}
            disabled={!docsTypesList || docsTypesList.length === 0}
            options={
              docsTypesList
                ? docsTypesList.map((item) => {
                    return { value: item.id, label: String(item.name).toLocaleLowerCase() };
                  })
                : []
            }
          />
        </Block>
      </StyledFilterRow>

      <Table
        data={docsStatusObj}
        onClick={onClick}
      />
    </>
  );
};

const StyledFilterRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-wrap: wrap;
  }
`;
