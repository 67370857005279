import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './en';
import RU from './ru';
import LV from './lv';
import DE from './de';

const lang = localStorage.getItem('language') || 'en';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: EN },
    ru: { translation: RU },
    de: { translation: DE },
    lv: { translation: LV },
  },
  lng: lang,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
