import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCountries } from '../modules/kycModule';
import { entitiesActions } from '../store/entities';
import { useTranslation } from 'react-i18next';
import { investorLocalizationApi } from '../api';

export const useCountries = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const { countries } = useSelector((state) => state.entities);

  useEffect(() => {
    (async () => {
      try {
        const languages = await investorLocalizationApi.getAvailableLanguages();

        const currentLang = languages.data.find(i => i.code === i18n.language.toUpperCase());

        const resCountries = await getCountries(currentLang.id);
        dispatch(entitiesActions.setCountries(resCountries.data));
      } catch (e) {
        console.error('FETCH_COUNTRIES', e);
      }
    })();
  }, []);

  return countries;
};
