import React from 'react';
import {
  InvestorInfoInputBox,
  AddressInputBox,
  InvestorTaxInputBox,
  IdDocumentsInputBox
} from '../../../components';
import { changeObjectState } from '../../../utils/helpers';

export const EditProfileInfoView = ({
  investorInfo,
  setInvestorInfo,
  legalAddress,
  setLegalAddress,
  actualAddress,
  setActualAddress,
  idDocs,
  setIdDocs
}) => {
  return (
    <div>
      <InvestorInfoInputBox
        data={investorInfo}
        changeData={(key, value) => changeObjectState(setInvestorInfo, investorInfo, key, value)}
      />

      <AddressInputBox
        title={'Legal Address'}
        data={legalAddress}
        changeData={(key, value) => changeObjectState(setLegalAddress, legalAddress, key, value)}
      />

      <AddressInputBox
        title={'Actual Address'}
        data={actualAddress}
        changeData={(key, value) => changeObjectState(setActualAddress, actualAddress, key, value)}
      />

      <InvestorTaxInputBox
        data={investorInfo}
        changeData={(key, value) => changeObjectState(setInvestorInfo, investorInfo, key, value)}
      />

      <IdDocumentsInputBox
        data={idDocs}
        changeData={(key, value) => changeObjectState(setIdDocs, idDocs, key, value)}
        hideMartial
      />
    </div>
  );
};
