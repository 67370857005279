import React, { useState } from 'react';
import { PageContainer } from '../../../../../ui';
import { Tabs, InvestorsTableBox } from '../../../../../components';
import { INVESTOR_STATUS_TABS, LEGAL_STATUS_TABS } from '../../../../../utils/static';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

const TITLES = {
  PERSONAL: 'Individuals clients',
  COMPANY: 'Legal entities'
};
const TABS = {
  PERSONAL: INVESTOR_STATUS_TABS,
  COMPANY: LEGAL_STATUS_TABS
};
export const InvestorsView = ({ investors, fetchInvestors, INVESTORS_ON_PAGE, type }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const tabsList = TABS[type] || INVESTOR_STATUS_TABS;
  return (
    <PageContainer>
      <Typography.Title style={{ padding: '24px 0' }} level={3}>
        {t(TITLES[type]) || t('Investors')}
      </Typography.Title>

      <Tabs
        items={tabsList?.map((item, index) => {
          return { key: index, label: t(item) };
        })}
        selected={selectedTab}
        setTab={setSelectedTab}
      />

      <InvestorsTableBox
        investors={investors}
        updateSearchParams={fetchInvestors}
        INVESTORS_ON_PAGE={INVESTORS_ON_PAGE}
        selectedTab={selectedTab}
      />
    </PageContainer>
  );
};
