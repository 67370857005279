import React from 'react';
import styled from 'styled-components';
import { history } from '../../../routing';
import { withTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const FooterButtons = withTranslation()(
  ({
     t,
     onNext,
     onBack,
     NextButtonTitle = t('Next'),
     withoutArrow = false,
     nextDisabled = false,
     hideBack = false,
     hideNext = false,
     noMargin = false
   }) => {
    return (
      <ButtonsContainer noMargin={noMargin}>
        <Button
          onClick={() => {
            onBack ? onBack() : history.goBack();
          }}
          disabled={hideBack}
        >
          {t('Back')}
        </Button>
        {onNext && (
          <NextButton
            mode='strong'
            icon={withoutArrow ? null : <ArrowRightOutlined />}
            onClick={onNext}
            disabled={nextDisabled}
          >
            {NextButtonTitle}
          </NextButton>
        )}
      </ButtonsContainer>
    );
  }
);

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  ${(p) => (!p.noMargin ? 'margin-top: 34px;' : '')}
`;

const NextButton = styled(Button)`
  ${(p) => (p.hide ? 'visibility: hidden;' : '')}
  display: flex;
  flex-direction: row-reverse;
  padding: 0 12px 0 18px;
  & > span {
    margin: 0 0 0 8px;
  }
`;
