import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  orders: null,
  currentOrder: null,
  filters: null
};

const orders = createSlice({
  name: 'ordersReducer',
  initialState: initialState,
  reducers: {
    setOrders(state, action) {
      state.orders = action.payload;
    },
    setCurrentOrder(state, action) {
      state.currentOrder = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    }
  }
});

export const { setOrders, setCurrentOrder, setFilters } = orders.actions;

export default orders.reducer;
