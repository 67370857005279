import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { Button } from '../../../../ui';
import { Container, Row, Block } from '../../../../ui/layout';
import { history } from '../../../../routing';
import {
  ENABLED_QUESTS_TO_VIEW_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR,
  STATUS_DRAFT,
  STATUS_NEW,
  STATUS_PASSED,
  STATUS_REJECTED,
  STATUSES_IN_PROGRESS
} from '../../../../utils/static/kyc';
import { AddQuestionnaireModal } from '../../../../modules/Questionnaire/components/modals';
import { Typography } from 'antd';
import { Button } from '../../../design2.0';
import { TableQuestionnaires } from '../../../design2.0/TableQuestionnaires';
import { useTranslation } from 'react-i18next';

const isEnabled = (accountStatus, questStatus, adminView) => {
  if (accountStatus !== 'ACTIVE' && !adminView) return false;
  return adminView
    ? ENABLED_QUESTS_TO_VIEW_STATUSES_ADMIN[questStatus]
    : ENABLED_STATUSES_INVESTOR[questStatus];
};

export const ProfileStatusView = ({
  data,
  investor,
  adminView = false,
  rejectedQuestionnaires,
  sumSubStatusRes,
  refetchQuests
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [addQuestVisible, setAddQuestVisible] = useState(false);
  const [addVerifVisible, setAddVerifVisible] = useState(false);

  const sumSubData = useMemo(() => {
    if (sumSubStatusRes && sumSubStatusRes.data) {
      const dataSumSub = sumSubStatusRes.data;

      return {
        ...dataSumSub?.investorVerificationQuestionSection,
        ...(location.state &&
          location.state.verificationSubmitted &&
          dataSumSub?.investorVerificationQuestionSection.status === 'New' && {
            status: 'In process'
          }),
        reviewResult: data?.reviewResult,
        comment: dataSumSub?.comment
      };
    }

    return null;
  }, [sumSubStatusRes, location]);

  const questionnairesSorted = useMemo(() => {
    if (data) {
      try {
        const questNew = [];
        const questInProgress = [];
        const questPassed = [];
        const questDraft = [];
        const questReject = [];
        let questVerification = null;

        data.data.forEach((quest) => {
          const questStatus = quest.status;
          const error =
            questStatus === 'UNACCEPTED' && rejectedQuestionnaires
              ? rejectedQuestionnaires[quest.id]
              : null;
          const formattedQuest = {
            ...quest,
            error: error,
            enabled: isEnabled(investor.accountStatus, questStatus, adminView),
            onClick: (id) => {
              history.push(
                adminView ? `/investor/${investor.id}/questionnaire/${id}` : `/questionnaire/${id}`,
                {
                  prevPath: location.pathname,
                  nextState: { lastProfileTab: investor.lastProfileTab }
                }
              );
            }
          };

          if (questStatus === STATUS_NEW) questNew.push(formattedQuest);
          else if (questStatus === STATUS_REJECTED) questReject.push(formattedQuest);
          else if (questStatus === STATUS_DRAFT) questDraft.push(formattedQuest);
          else if (questStatus === STATUS_PASSED) questPassed.push(formattedQuest);
          else if (STATUSES_IN_PROGRESS[questStatus]) questInProgress.push(formattedQuest);
        });

        if (sumSubData) {
          questVerification = {
            ...sumSubData,
            questName: 'Verification',
            onClick: (id) => {
              history.push(
                adminView
                  ? `/investor/${investor.id}/sumsub-verification`
                  : '/sumsub-verification',
                { prevPath: location.pathname, nextState: { lastProfileTab: investor.lastProfileTab } }
              );
            }
          };
        }

        return {
          new: questNew,
          inProgress: questInProgress,
          passed: questPassed,
          draft: questDraft,
          rejected: questReject,
          verification: questVerification
        };
      } catch (e) {}
    }

    return null;
  }, [data, rejectedQuestionnaires, adminView, investor, location]);

  if (questionnairesSorted && investor) {
    return (
      <>
        <Container paddingY={25}>
          <Row alignCenter justifyContent='space-between' gap={20}>
            <Typography.Title level={2}>{t('Questionnaires')}</Typography.Title>

            <Block width={210}>
              <Button
                type='filled'
                size='large'
                text={t('Create questionnairy')}
                onClick={() => setAddQuestVisible(true)}
              />
            </Block>
          </Row>

          <Container marginTop={36}>
            <TableQuestionnaires data={questionnairesSorted} getFuncFromObj />
          </Container>

          <AddQuestionnaireModal
            visible={addQuestVisible}
            onClose={setAddQuestVisible}
            user={adminView ? 'admin' : 'investor'}
            onSuccess={refetchQuests}
          />
        </Container>

        {/* <Container style={{ position: 'relative' }}>
          {console.log(questionnairesSorted)}
          {questionnairesSorted.inProgress && questionnairesSorted.inProgress.length > 0 && (
            <QuestList
              title={'Questionnaires in progress'}
              list={questionnairesSorted.inProgress}
            />
          )}
          {questionnairesSorted.new && questionnairesSorted.new.length > 0 && (
            <QuestList title={'Questionnaires new'} list={questionnairesSorted.new} />
          )}
          {questionnairesSorted.passed && questionnairesSorted.passed.length > 0 && (
            <QuestList title={'Questionnaires passed'} list={questionnairesSorted.passed} />
          )} */}
        {/* <AddQuestContainer>
          <Button
            label={'+'}
            onClick={() => setAddQuestVisible(true)}
            paddingX={'20'}
            size={'small'}
            mode={'strong'}
            disabled={investor.accountStatus !== 'ACTIVE'}
          />
          <AddQuestionnaireModal
            visible={addQuestVisible}
            onClose={() => setAddQuestVisible(false)}
            user={adminView ? 'admin' : 'investor'}
            onSuccess={refetchQuests}
          />
        </AddQuestContainer> */}

        {/* {sumSubStatusRes && (
            <SumsubContainer>
              <Title4 marginBottom={12} color={COLORS.default}>
                Verification
              </Title4>
              <AddQuestContainer>
               <Button
                 label={'+'}
                 onClick={() => setAddVerifVisible(true)}
                 paddingX={'20'}
                 size={'small'}
                 mode={'strong'}
                 disabled={investor.accountStatus !== 'ACTIVE'}
               />
               <InitVerifSidePanel
                 opened={addVerifVisible}
                 onClose={() => setAddVerifVisible(false)}
               />
              </AddQuestContainer>

              <Row cols={3} margin={-8} colsMarginBottom={2 * GU}>
                <Col>
                  <SumSubBox data={sumSubData} investor={investor} adminView={adminView} />
                </Col>
              </Row>
            </SumsubContainer>
          )}
        </Container>*/}
      </>
    );
  }

  return null;
};

