import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { kycApi } from '../../../../../api/kyc';
import { Block } from '../../../../../ui/layout';
import { Body2Light } from '../../../../../ui/typography';
import { entitiesActions } from '../../../../../store/entities';
import { useDispatch } from 'react-redux';
import { adminKYCApi } from '../../../../../api/admin/kyc';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Select } from '../../../../../components';

export const AddQuestionnaireModal = ({ visible = false, onClose, user, onSuccess }) => {
  switch (user) {
    case 'investor': {
      return <AddModalInvestor visible={visible} onClose={onClose} onSuccess={onSuccess} />;
    }
    case 'admin': {
      return <AddModalAdmin visible={visible} onClose={onClose} onSuccess={onSuccess} />;
    }
    default:
      return null;
  }
};

const AddModalInvestor = ({ onSuccess, visible, ...props }) => {
  const { data, refetch } = useQuery(['getAllowedQuestsToAdd'], kycApi.getAllowedQuestsToAdd);
  const { mutateAsync } = useMutation(kycApi.requestQuest);

  useEffect(() => {
    if (visible) refetch();
  }, [visible]);

  const allowedQuestsList = useMemo(() => (data ? data.data : null), [data]);

  const onAdd = async (questId) => {
    try {
      await mutateAsync(questId);
      if (onSuccess) await onSuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AddModal allowedQuestsList={allowedQuestsList} onAdd={onAdd} visible={visible} {...props} />
  );
};

const AddModalAdmin = ({ onSuccess, visible, ...props }) => {
  const { id } = useParams();
  const { data, refetch } = useQuery(['getAllowedQuestsToAddAdmin'], () =>
    adminKYCApi.getAllowedQuestsToAdd({ investorId: id })
  );
  const { mutateAsync } = useMutation(adminKYCApi.requestQuest);

  useEffect(() => {
    if (visible) refetch();
  }, [visible]);

  const allowedQuestsList = useMemo(() => (data ? data.data : null), [data]);

  const onAdd = async (questId) => {
    try {
      await mutateAsync({ investorId: id, questId: questId });
      if (onSuccess) await onSuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AddModal allowedQuestsList={allowedQuestsList} onAdd={onAdd} visible={visible} {...props} />
  );
};

const AddModal = ({ allowedQuestsList, onAdd, visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const onAddQuest = async () => {
    try {
      // await onAdd(allowedQuestsList[selected].id);
      await onAdd(allowedQuestsList.find((quest) => quest.id === selected)?.id);
      setSelected(null);
      onClose(false);
      dispatch(entitiesActions.addToast(t('Successfully added')));
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast(t('Something went wrong')));
    }
  };

  return (
    <Modal isModalOpen={visible} setIsModalOpen={onClose}>
      {allowedQuestsList && allowedQuestsList.length > 0 && (
        <Select
          allowClear
          placeholder={t('Allowed to add questionnaires')}
          options={allowedQuestsList.map((item) => {
            return { label: String(item.name).toLocaleLowerCase(), value: item.id };
          })}
          value={selected}
          onChange={(value) => setSelected(value)}
        />
      )}
      {allowedQuestsList && allowedQuestsList.length === 0 && (
        <Body2Light>{t('Not allowed questionnaires to add')}</Body2Light>
      )}

      <Block marginTop={24} width={170}>
        <Button
          type='filled'
          size='large'
          text={t('Add')}
          onClick={onAddQuest}
          disabled={!selected}
        />
      </Block>
    </Modal>
  );
};
