import React, { useMemo, useState } from 'react';
import { InnerBox, UploadDocumentsBox } from '../../../../components';
import { TextArea, Input, Body1Light, Container, Button } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';

export const SupportSidePanel = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [docs, setDocs] = useState([]);

  const submitReport = () => {
    setMessage('');
    setEmail('');
    setDocs([]);
    onClose();
  };

  const disableSubmit = useMemo(() => !message || !email, [message, email]);

  return (
    <Drawer title={<Body1Light>{t('Support')}</Body1Light>} open={opened} onClose={onClose}>
      <InnerBox>
        <TextArea
          label={t('Message')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          height={'88'}
          wide
          marginBottom={3 * 8}
        />

        <Input
          label={t('E-Mail for response')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InnerBox>

      <InnerBox>
        <UploadDocumentsBox label={t('Additional Documents')} docs={docs} setDocs={setDocs} multiple />

        <Container marginTop={3 * 8} marginBottom={4 * 8}>
          <div>
            {t('You will receive feedback to the specified email address.')}
            <br />
            <br />
          </div>
        </Container>

        <Button
          label={t('Submit')}
          onClick={submitReport}
          disabled={disableSubmit}
          mode={'strong'}
          wide
        />
      </InnerBox>
    </Drawer>
  );
};
