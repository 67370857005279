import { IdentityBadge as IB, GU } from '@aragon/ui';
import styled from 'styled-components';

export const IdentityBadge = styled(IB)`
  ${(p) =>
    p.labelOnly
      ? `
    padding-left: ${GU}px;
    
    > div:first-child {
      display: none;
    }
  `
      : ''}

  > span {
    font-weight: 300;
    font-family: 'Overpass', sans-serif;
  }
`;
