import React, { useMemo, useState } from 'react';
import {
  Button,
  DocumentBox,
  ModalDocument,
  TableDocument,
  Text
} from '../../../../components';
import { Image, Typography } from 'antd';
import { Row, Col, Title4, Container, Block } from '../../../../ui';
import {
  ENABLED_DOC_TO_VIEW_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR,
} from '../../../../utils/static/kyc';
import styled from 'styled-components';
import { history } from '../../../../routing';
import { getUrlName } from '../../../../utils/helpers';
import { useSelector } from 'react-redux';
import noDocumentsImg from '../../../../assets/user/no-documents.png';
import { useTranslation } from 'react-i18next';

/**
 * @param documents (array of requested docs)
 * @param onDelete (func) - handle delete doc
 */
const enabledStatuses = {
  investor: ENABLED_STATUSES_INVESTOR,
  admin: ENABLED_DOC_TO_VIEW_STATUSES_ADMIN
};
export const ProfileDocuments = ({
  documents,
  questsDocs,
  refetchDocs,
  viewMode = 'investor',
  investorId
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { investor } = useSelector((state) => state);
  const [addDocVisible, setAddDocVisible] = useState(false);
  //array of sections
  const docsSections = useMemo(() => {
    try {
      const result = {};

      documents.forEach((doc) => {
        const sectionDocs = result[doc.kycTypeDocument.kycDocumentSectionId];
        const docStatus = doc.status || 'PENDING';
        const formattedDoc = {
          ...doc,
          enabled:
            (viewMode !== 'investor' || investor.accountStatus === 'ACTIVE') &&
            enabledStatuses[viewMode][docStatus]
        };

        sectionDocs
          ? result[doc.kycTypeDocument.kycDocumentSectionId].docs.push(formattedDoc)
          : (result[doc.kycTypeDocument.kycDocumentSectionId] = {
              name: doc.kycDocumentSection.name,
              docs: [formattedDoc]
            });
      });

      return Object.keys(result).map((key) => result[key]);
    } catch (e) {}

    return null;
  }, [documents]);
  const questsDocsFormatted = useMemo(() => {
    if (questsDocs) {
      return questsDocs.map((doc, index) => {
        const { status, created, updated, expiration, person } = doc.investorQuestionSectionTypes;
        const { name } = doc.questionSectionType;
        const questionName = doc.question?.question;

        return {
          ...doc,
          status,
          created,
          updated,
          expiration,
          person,
          name: name ? name : getUrlName(doc.documentURL, index + 1),
          description: questionName,
          enabled: true
        };
      });
    }

    return null;
  }, [questsDocs]);

  const onOpenDocument = ({ id, investorId }) => {
    switch (viewMode) {
      case 'investor': {
        history.push(`/documents/request/${id}`);
        break;
      }
      case 'admin': {
        history.push(`/investor/${investorId}/documents/request/${id}`);
        break;
      }
    }
  };

  return (
    <Container paddingY={25}>
      <Row justifyContent={'space-between'}>
        <Typography.Title level={2}>{t('Documents')}</Typography.Title>

        {!!docsSections && (
          <Block>
            <Button
              type='filled'
              size='large'
              text={t('Add document')}
              onClick={() => setIsModalOpen(true)}
            />
          </Block>
        )}
      </Row>

      {docsSections ? (
        <Container marginTop={40}>
          <TableDocument
            data={docsSections}
            onClick={onOpenDocument}
            adminView={viewMode === 'admin'}
            investorId={investorId}
          />
        </Container>
      ) : (
        <Row marginTop={171} flexDirection='column' alignCenter>
          <Image src={noDocumentsImg} width={80} height={80} preview={false} />

          <Container marginTop={40}>
            <Text fontWeight={600} fontSize={20}>
              No documents
            </Text>
          </Container>

          <Container marginTop={12}>
            <Text>Add a document to make it appear</Text>
          </Container>

          <Block marginTop={32} width={170}>
            <Button
              type='filled'
              size='large'
              text='Add document'
              onClick={() => setIsModalOpen(true)}
            />
          </Block>
        </Row>
      )}

      <ModalDocument
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        visible={addDocVisible}
        onClose={() => setAddDocVisible(false)}
        onSuccess={refetchDocs}
        adminView={viewMode === 'admin'}
        investorId={investorId}
      />
    </Container>

    // <Container style={{ position: 'relative' }}>
    //   {docsSections &&
    //     docsSections.map((section, key) => (
    //       <DocsList
    //         title={section.name}
    //         list={section.docs}
    //         openDocument={onOpenDocument}
    //         key={key}
    //       />
    //     ))}
    //   {questsDocsFormatted && questsDocsFormatted.length > 0 && (
    //     <DocsList
    //       title={'Documents from QUESTIONNAIRES'}
    //       list={questsDocsFormatted}
    //       openDocument={(doc) => window.open(getFileSrc(doc.documentURL))}
    //     />
    //   )}

    //   <AddDocContainer>
    //     <Button
    //       label={'+'}
    //       onClick={() => setAddDocVisible(true)}
    //       paddingX={'20'}
    //       size={'small'}
    //       mode={'strong'}
    //       disabled={investor.accountStatus !== 'ACTIVE'}
    //     />
    //     <AddDocModal
    //       visible={addDocVisible}
    //       onClose={() => setAddDocVisible(false)}
    //       onSuccess={refetchDocs}
    //       adminView={viewMode === 'admin'}
    //       investorId={investorId}
    //     />
    //   </AddDocContainer>
    // </Container>
  );
};

const DocsList = ({ title = '', list, openDocument }) => {
  if (list) {
    return (
      <DocsContainer>
        <Title4 marginBottom={12}>{title}</Title4>

        <Row cols={3} margin={-8} colsMarginBottom={16}>
          {list.map((item, index) => (
            <Col key={index}>
              <DocumentBox data={item} onClick={() => openDocument(item)} />
            </Col>
          ))}
        </Row>
      </DocsContainer>
    );
  }

  return null;
};

const DocsContainer = styled.div`
  /* margin-top: 50px; */
`;
