import RegContainer from './registrationComponents/RegContainer';
import StepsPanel from './registrationComponents/StepsPanel/StepsPanel';
import AssetsTable from './investorComponents/AssetsTable/index';
import AssetsAvailableAssetsTable from './investorComponents/AssetsAvailableAssetsTable/index';
import MoreBtn from './investorComponents/MoreBtn';
import ImageUpload from './registrationComponents/ImageUpload';
import FileUpload from './registrationComponents/FileUpload';
import CodeCountdown from './registrationComponents/CodeCountdown';
import { DocumentUpload } from './investorComponents/DocumentUpload';
export { FooterButtons } from './registrationComponents/FooterButtons';
export * from './customAragon';
export * from './common';
export * from './investor';
export * from './admin';
export * from './design2.0';

export {
  RegContainer,
  StepsPanel,
  AssetsTable,
  AssetsAvailableAssetsTable,
  MoreBtn,
  ImageUpload,
  CodeCountdown,
  DocumentUpload,
  FileUpload
};
