import React from 'react';
import { Text, IconArrowDown, IconArrowUp } from '@aragon/ui';
import { Row } from '../../../../ui';
import { COLORS } from '../../../../utils/static';

export const ProfitLine = ({ profit, profitPercent, currency }) => {
  return (
    <Row alignItems={'center'}>
      {profit < 0 ? (
        <IconArrowDown color={COLORS.red} style={{ marginTop: '-2px' }} />
      ) : (
        <IconArrowUp color={COLORS.mintGreen} style={{ marginTop: '-2px' }} />
      )}
      {profit} {currency}&ensp;
      <Text color={COLORS.greyMedium}>({Number(profitPercent)}%)</Text>
    </Row>
  );
};
