import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, LogOutButton, Progress, Text } from '../../../components';
import { BREAKPOINTS, Block, Col, Container, Row } from '../../../ui/layout';
import { Body1Light, Title1, Title2 } from '../../../ui/typography';
import { Section } from '../components/Section';
import styled from 'styled-components';
import {
  SHOWED_SECTIONS_COUNT,
  STATUSES_WITH_ANSWERS,
  STATUSES_WITH_DISABLED
} from '../../../utils/static/kyc';
import { kycHelpers } from '../../../utils/helpers/kyc';
import { history } from '../../../routing';
import { useDispatch } from 'react-redux';
import { entitiesActions } from '../../../store/entities';
import { Form, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const QuestionnaireView = ({
  questionnaire,
  currentSection,
  onNext,
  onBack,
  adminView = false,
  rejectQuestionnaire,
  acceptQuestionnaire,
  rejectedQuestions,
  setCurrentSection,
  disabled = false,
  stepsCount = 0,
  statusSettings,
  ...props
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [preFilledQuestions, setPreFilledQuestions] = useState({});
  const defaultValues = useMemo(() => {
    try {
      if (questionnaire && (STATUSES_WITH_ANSWERS[questionnaire.status] || adminView)) {
        const { values, lastAnsweredSection, preFilledQuestions } =
          kycHelpers.collectAnswers(questionnaire);
        if (
          !adminView &&
          (questionnaire.status === 'DRAFT' || questionnaire.status === 'UNACCEPTED')
        ) {
          setCurrentSection(
            Math.min(questionnaire.questionSections.length - 1, lastAnsweredSection + 1)
          );
        }

        if (Object.keys(preFilledQuestions).length > 0) {
          setPreFilledQuestions(preFilledQuestions);
        }
        console.log({ preFilledQuestions });

        return values;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }, [questionnaire, adminView]);
  const {
    control,
    handleSubmit,
    trigger,
    watch,
    reset,
    formState: { errors, submitCount }
  } = useForm({ defaultValues: { ...defaultValues } });
  const [insertedQuestions, setInsertedQuestions] = useState({});

  useEffect(() => {
    if (Object.keys(errors).length > 0)
      dispatch(entitiesActions.addToast('Some fields are not valid'));
  }, [submitCount]);

  const toggleAutoInsertQuestion = useCallback((checked, questionId, autoInsertId) => {
    const clone = { ...insertedQuestions };
    clone[questionId] = checked;
    setInsertedQuestions(clone);
  }, []);

  // useEffect(() => {
  //   reset();
  // }, [currentSection]);
  const isRejected = useMemo(() => questionnaire?.status === 'UNACCEPTED', [questionnaire]);
  const isApproved = useMemo(() => questionnaire?.status === 'ACCEPTED', [questionnaire]);

  const backToProfile = () => {
    state && state.prevPath ? history.push(state.prevPath, state.nextState) : history.goBack();
  };
  const startSlicePos = currentSection * SHOWED_SECTIONS_COUNT;

  const clearFields = () => {
    reset();
  };

  return (
    <>
      <HeaderRow
        paddingY={24}
        paddingRight={44}
        paddingLeft={48}
        justifyContent='space-between'
        nowrap
      >
        <Container mdHidden>
          <Block maxWidth={238} wide>
            <Button
              type='text'
              size='large'
              text={t('Back to questionnaires')}
              icon={<ArrowLeftOutlined />}
              onClick={backToProfile}
            />
          </Block>
        </Container>

        <Container mdVisible hidden>
          <Button type='text' size='large' icon={<ArrowLeftOutlined />} onClick={backToProfile} />
        </Container>
        <LogOutButton />
      </HeaderRow>

      <Row flexDirection='column' alignCenter paddingY={60} paddingX={16}>
        {!disabled && questionnaire.questionSections[currentSection].section !== 'User verification' && <Typography.Title level={2}>{t(`${questionnaire.name}`)}</Typography.Title>}

        <Container marginTop={40}>
          <Text fontSize={20} fontWeight={600}>
            {t(`${questionnaire.questionSections[currentSection].section}`)}
          </Text>
        </Container>
        <Block wide maxWidth={490} marginTop={16}>
          <Progress
            steps={questionnaire.questionSections.length}
            currentStep={currentSection + 1}
          />
          {/* <Row wide justifyContent='flex-end' marginTop={36}>
            <Block width={146} marginRight={'-20'}>
              <Button
                type='text'
                size='large'
                text='Сlear fields'
                icon={deleteIcon}
                onClick={clearFields}
              />
            </Block>
          </Row> */}
          <Container marginTop={36}>
            <Row justifyContent={'flex-end'} alignCenter>
              {adminView && rejectQuestionnaire && statusSettings && (
                <Col auto>
                  <Button
                    text={statusSettings.isRejected ? t('Rejected') : t('Reject')}
                    onClick={rejectQuestionnaire}
                    disabled={!statusSettings.allowReject}
                  />
                </Col>
              )}
              {adminView && acceptQuestionnaire && statusSettings && (
                <Col auto>
                  <Button
                    text={statusSettings.isApproved ? t('Approved') : t('Approve')}
                    onClick={acceptQuestionnaire}
                    disabled={!statusSettings.allowApprove}
                    paddingX={'18'}
                  />
                </Col>
              )}
            </Row>

            {disabled && <Title1 textAlign={'center'}>{t('Access denied')}</Title1>}

            <Form layout='vertical' form={form} name='form'>
              {!disabled &&
                questionnaire.questionSections &&
                questionnaire.questionSections
                  .slice(startSlicePos, startSlicePos + SHOWED_SECTIONS_COUNT)
                  .map((sectionObj) => (
                    <div key={sectionObj.id}>
                      <Section
                        questionnaireId={questionnaire?.id}
                        questionnaireStatus={questionnaire?.status}
                        section={sectionObj}
                        control={control}
                        watch={watch}
                        submitCount={submitCount}
                        trigger={trigger}
                        adminView={adminView}
                        disableAll={adminView || STATUSES_WITH_DISABLED[questionnaire?.status]}
                        rejectedQuestions={rejectedQuestions}
                        insertedQuestions={insertedQuestions}
                        toggleAutoInsertQuestion={toggleAutoInsertQuestion}
                        preFilledQuestions={preFilledQuestions}
                        {...props}
                      />
                    </div>
                  ))}
            </Form>
          </Container>

          {!disabled && questionnaire.questionSections && (
            <Row marginTop={40} gap={24}>
              <Block width={124}>
                <Button
                  type='filled'
                  size='large'
                  text={t('Next')}
                  onClick={adminView ? onNext : () => handleSubmit(onNext)(insertedQuestions)}
                  disabled={disabled}
                />
              </Block>
            </Row>
          )}
        </Block>
      </Row>
    </>
  );
};

const HeaderRow = styled(Row)`
  background: #f6f9ff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
