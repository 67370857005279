import React, { useMemo } from 'react';
import { transformData } from './utils';
import { columns } from './constants';
import { Table } from 'antd';

export const InvestorsTable = ({ data = [], onChange, count }) => {
  const resultData = useMemo(() => {
    return transformData(data);
  }, [data]);

  return (
    <Table
      columns={columns}
      dataSource={resultData}
      size='small'
      pagination={{
        position: ['bottomCenter'],
        defaultPageSize: 5,
        onChange: (page) => onChange(page - 1),
        total: count
      }}
    />
  );
};
