import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Image, Typography } from 'antd';
import { Button, Text, Collapse, TierProgressList } from '../../../../../components';
import { BREAKPOINTS, Block, Container, Row } from '../../../../../ui';
import { DeepCheckModule, EditProfileInfo } from '../../../../../modules';
import { history } from '../../../../../routing';
import { ENABLED_STATUSES_INVESTOR } from '../../../../../utils/static/kyc';
import { COLORS } from '../../../../../utils/static/colors';
import { dateString } from '../../../../../utils/helpers/common';
import { DISPLAY_INVESTOR_ACCOUNT_STATUS } from '../../../../../utils/static/profile';
import emptyAvatarImg from '../../../../../assets/user/empty-avatar-photo.png';
import { useTranslation } from 'react-i18next';

const additionalInfoLabels = [
  'Name',
  'Home and mail address',
  'Contacts',
  'Citizenship',
  'Residence',
  'In documents',
  'Tax residency',
  'Marital status',
  'Representatives data',
  'UBO data'
];

const CollapseAdditionalInfo = ({ investor, label, firstIndex, lastIndex }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      label={t(`${label}`)}
      children={
        <DropdownMenu>
          {investor.answers.map((item) => (
            <>
              <Text color={COLORS.greyText2} fontSize={14} maxWidth={150}>
                {t(`${item.title}`)}
              </Text>
              <Text maxWidth={150}>{item.answer}</Text>
            </>
          ))}
        </DropdownMenu>
      }
    />
  );
};

export const ProfileInfoView = ({
  investor,
  isEdit,
  saveTab,
  saveCallback,
  viewMode = 'investor'
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  if (investor) {
    const {
      id,
      avatar,
      firstName,
      lastName,
      citizenship,
      phone,
      email,
      tier,
      accountStatus,
      createdAt,
      lastSession,
      scoringPoints,
      referralCode
    } = investor;

    if (!isEdit) {
      return (
        <>
            <Container paddingY={25}>
              <Row alignCenter justifyContent={'space-between'} wrap={'wrap'}>
                <Typography.Title level={2}>{t('Personal data')}</Typography.Title>

                <Block>
                  <Button
                    type='filled'
                    size='large'
                    text={t('Edit personal data')}
                    onClick={() => {
                      history.push(`/questionnaire/${investor?.additionalInfo?.id}`, {
                        prevPath: location.pathname,
                        nextState: { lastProfileTab: investor.lastProfileTab }
                      });
                    }}
                    disabled={accountStatus !== 'ACTIVE'}
                  />
                </Block>
              </Row>

              <Row alignItems='flex-end' justifyContent={'space-between'} marginTop={36} gap={20}>
                <Row gap={21}>
                  <StyledImage
                    src={avatar ? avatar : emptyAvatarImg}
                    width={167}
                    height={200}
                    preview={false}
                  />

                  <Row flexDirection='column' nowrap justifyContent='space-between'>
                    <StyledTitle level={3}>
                      {firstName} {lastName}
                    </StyledTitle>

                    <BlockContacts>
                      <Text color={COLORS.greyText2} fontSize={14}>
                        {t('Phone number')}
                      </Text>

                      <Text>{phone}</Text>

                      <Text color={COLORS.greyText2} fontSize={14}>
                        {t('E-mail')}
                      </Text>

                      <Text>{email}</Text>

                      <Text color={COLORS.greyText2} fontSize={14}>
                        {t('Citizenship')}
                      </Text>

                      <Text>{citizenship.country}</Text>
                    </BlockContacts>
                  </Row>
                </Row>

                <Block maxWidth={379} wide>
                  <TierProgressList tier={tier} />
                </Block>
              </Row>

              <Container marginTop={40}>
                <Text fontSize={20} fontWeight={600} color={COLORS.greyText2}>
                  {t('Additional information')}
                </Text>

                <BlockAdditionalInfo marginTop={32}>
                  {investor.additionalInfo &&
                    investor.additionalInfo.answers.map((item, index) => (
                      <CollapseAdditionalInfo
                        key={index}
                        label={additionalInfoLabels[index]}
                        investor={item}
                      />
                    ))}
                </BlockAdditionalInfo>
              </Container>
            </Container>
          </>
      );
    } else {
      return <EditProfileInfo investor={investor} saveTab={saveTab} saveCallback={saveCallback} />;
    }
  }

  return null;
};

const StyledImage = styled(Image)`
  border-radius: 20px;
  border: 1px solid #ebebeb;
`;

const StyledTitle = styled(Typography.Title)`
  font-weight: 600 !important;
`;

const BlockContacts = styled.div`
  padding-bottom: 5px;
  display: grid;
  flex-direction: row;
  grid-template-columns: max-content max-content;
  gap: 20px;
`;

const BlockAdditionalInfo = styled(Block)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  justify-content: space-between;
  row-gap: 32px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
`;

const DropdownMenu = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: max-content max-content;
  column-gap: 12px;
  row-gap: 14px;
  margin-top: 16px;
  visibility: visible;
  height: auto;
  max-height: max-content;
`;
const ProgressBarContainer = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px;
`;
const ProgressContainer = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
`;
const Bullet = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: ${(p) => (p.active ? COLORS.mintGreen : COLORS.darkBabyBlue)};
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
const Bullet1 = styled(Bullet)``;
const Bullet2 = styled(Bullet)`
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Bullet3 = styled(Bullet)`
  left: 100%;
  transform: translate(-100%, -50%);
`;
