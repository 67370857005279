import React from 'react';
import { useController } from 'react-hook-form';
import { Row, Col, Body3Light } from '../../../ui';
import { COLORS } from '../../../utils/static';
import { Radio } from '../../design2.0';
import { Radio as ANTDRadio } from 'antd';

const defaultRowProps = { margin: -31, colsMarginBottom: 8, flexDirection: 'column' };

export const RadioGroupForm = ({
  form,
  items,
  ids,
  rowProps = defaultRowProps,
  disabled,
  ...props
}) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });
  const { onChange, value, ...otherField } = field;

  const handleChange = (newValue) => {
    onChange(newValue === value ? '' : newValue);
  };

  return (
    <div>
      <Row {...rowProps}>
        <ANTDRadio.Group {...props} {...otherField} value={value} onChange={handleChange} disabled={disabled}>
          {items?.map((label, key) => {
            const radioId = (ids && ids[key]) || label.toLowerCase();

            return (
              <Col auto key={key} marginBottom={5}>
                <Radio value={radioId} disabled={disabled}>
                  {label}
                </Radio>
              </Col>
            );
          })}
        </ANTDRadio.Group>
      </Row>

      {error && (
        <Body3Light color={COLORS.red} marginTop={12}>
          {error.message}
        </Body3Light>
      )}
    </div>
  );
};
