import React from 'react';
import { Checkbox, GU } from '@aragon/ui';
import { ExternalLink, Link } from '../../../../ui';
import styled from 'styled-components';
import { useController } from 'react-hook-form';
import { API_URL } from '../../../../utils/static/api';

//ToDo: rename to PrivacyCheckbox
export const TermsCheckbox = ({ form }) => {
  const {
    field: { value, ...otherField }
  } = useController({
    ...form,
    rules: { required: 'Required' },
    defaultValue: false
  });

  return (
    <CheckboxWrapper>
      <Checkbox checked={value} {...otherField} />
      <span>
        I agree with{' '}
        <ExternalLink href={`${API_URL}/api/v1/statics/privacy_policy.pdf`}>
          Privacy Policy
        </ExternalLink>
      </span>
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.label`
  display: flex;
  margin-bottom: 34px;
  align-items: center;

  & > span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
    margin-left: ${GU}px;
  }
`;
