import React from 'react';
import styled from 'styled-components';
import { Checkbox as AntdCheckbox } from 'antd';

export const Checkbox = ({ children, onChange, checked, disabled, ...props }) => {
  return (
    <StyledAntdCheckbox checked={checked} disabled={disabled} onChange={onChange} {...props}>
      {children}
    </StyledAntdCheckbox>
  );
};

const StyledAntdCheckbox = styled(AntdCheckbox)`
  .ant-checkbox {
    width: 20px;
    height: 20px;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: #4797ff;
      border-width: 2px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-width: 1px;
      background-color: #4797ff;
      border-color: #4797ff;
    }
  }
  .ant-checkbox-inner {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border-color: #c3c6c9;
  }
  .ant-checkbox-inner::after {
    width: 6.714286px;
    height: 10.142857px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #006fff;
    border-color: #006fff;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #c2c2c2;
    background-color: #f5f5f6;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c2c2c2;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
  .ant-checkbox + span {
    padding-inline-start: 10px !important;
  }
`;
