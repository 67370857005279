import React from 'react';
import { COLORS } from '../../../../utils/static';
import styled from 'styled-components';

export const Badge = ({ title = '', color }) => {
  return <BadgeWrapper color={color}>{title}</BadgeWrapper>;
};

const BadgeWrapper = styled.div`
  height: 22px;
  background: ${(p) => (p.color ? p.color : COLORS.aragonLightTurquoise)};
  border-radius: 4px;
  padding: 0 4px;
`;
