import React, { useMemo, useState } from 'react';
import { SidePanel, GU } from '@aragon/ui';
import { Info, InnerBox, UploadDocumentsBox } from '../../../../components';
import { TextArea, Input, Body1Light, Container, Button } from '../../../../ui';
import { useTranslation } from 'react-i18next';

export const SupportSidePanel = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [docs, setDocs] = useState([]);

  const submitReport = () => {
    setMessage('');
    setEmail('');
    setDocs([]);
    onClose();
  };

  const disableSubmit = useMemo(() => !message || !email, [message, email]);

  return (
    <SidePanel title={<Body1Light>{t('Support')}</Body1Light>} opened={opened} onClose={onClose}>
      <InnerBox>
        <TextArea
          label={t('Message')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          height={'88'}
          wide
          marginBottom={3 * GU}
        />

        <Input
          label={t('E-Mail for response')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InnerBox>

      <InnerBox>
        <UploadDocumentsBox label={t('Additional Documents')} docs={docs} setDocs={setDocs} multiple />

        <Container marginTop={3 * GU} marginBottom={4 * GU}>
          <Info>
            {t('You will receive feedback to the specified email address.')}
            <br />
            <br />
          </Info>
        </Container>

        <Button
          label={t('Submit')}
          onClick={submitReport}
          disabled={disableSubmit}
          mode={'strong'}
          wide
        />
      </InnerBox>
    </SidePanel>
  );
};
