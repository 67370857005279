import axios from 'axios';
import { API_URL } from '../../../utils/static/api';

const getAvailableLanguages = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/language`);
};
const setLanguage = ({ languageId }) => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/language`, { languageId });
};


export const investorLocalizationApi = {
  getAvailableLanguages,
  setLanguage
};

export const investorLocalizationKeys = {
  getAvailableLanguages: 'investorLocalizationApi.getAvailableLanguages',
  setLanguage: 'investorLocalizationApi.setLanguage',
};
