import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';

export const BackButton = (props) => {
  const { t } = useTranslation();

  return <StyledBackButton icon={<LeftOutlined />} {...props} >{t('Back')}</StyledBackButton>;
};

const StyledBackButton = styled(Button)`
  min-width: 96px;
  height: 40px;
  border: 1px solid #dde4e9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  padding: 0 12px;
  justify-content: center;
  ${(p) => (p.hide ? 'visibility: hidden;' : '')}
  & > span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
  }
`;
