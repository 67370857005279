import React from 'react';
import { Box } from '../../../../components';
import { DatePicker, Input, Row, Col, Body1Light } from '../../../../ui';

export const RepresentativeInfoInputBox = ({ data = {}, changeData }) => {
  return (
    <Box paddingBottom={24}>
      <Body1Light>Representative’ personal information</Body1Light>

      <Row
        cols={2}
        margin={-8}
        marginTop={24}
        colsMarginBottom={24}
        alignItems={'flex-end'}
      >
        <Input
          label={'position in the company'}
          value={data.positionInTheCompany}
          onChange={(e) => changeData('positionInTheCompany', e.target.value)}
          required
        />

        <Col />

        <Input
          label={'First Name'}
          value={data.firstName}
          onChange={(e) => changeData('firstName', e.target.value)}
        />

        <Input
          label={'Last Name'}
          value={data.lastName}
          onChange={(e) => changeData('lastName', e.target.value)}
          required
        />

        <DatePicker
          label={'Birth date'}
          selected={data.dateOfBirth}
          onChange={(date) => changeData('dateOfBirth', date)}
          required
        />

        <Input
          label={'Mobile phone'}
          value={data.phone}
          onChange={(e) => changeData('phone', e.target.value)}
          required
        />
      </Row>
    </Box>
  );
};
