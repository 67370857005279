import React from 'react';
import { Row, Body4 } from '../../../../ui';
import styled from 'styled-components';
import { COLORS } from '../../../../utils/static';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, ClockCircleOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';

/**
 * @param statusTypes - type template - [type]: {[title]: 'string', [color]: 'string', [icon]: 'component }
 * @param type - one of statusTypes keys
 * @param title - title string
 * @param Icon - link to icon component
 * @param color - applied to icon and title (if colored)
 * @param colored - true ? colored title : title with default color
 * @param TitleStyle - title style component
 * @param titleMargin - margin between icon and title
 * @param props - Row component props
 */

export const StatusLine = ({
  statusTypes = DEFAULT_PROPS,
  type = 'processing',
  title = statusTypes ? statusTypes[type]?.title : '',
  color = statusTypes[type]?.color || null,
  Icon = statusTypes[type]?.icon || Body4,
  colored = false,
  TitleStyle = Body4,
  titleMargin = 0.5 * 8,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledRow nowrap alignItems={'center'} color={color} colored={colored} {...props}>
      <IconWrapper>
        <Icon size={'small'} />
      </IconWrapper>
      <TitleWrapper titleMargin={titleMargin}>
        <TitleStyle>{t(title)}</TitleStyle>
      </TitleWrapper>
    </StyledRow>
  );
};

const TitleWrapper = styled.div`
  ${(p) => (p.titleMargin ? `margin-left: ${p.titleMargin}px;` : '')}
`;
const IconWrapper = styled.div`
  display: flex;
  margin-top: -2px;
`;
const StyledRow = styled(Row)`
  color: ${({ color }) => (color ? color : 'inherit')};
  white-space: nowrap;

  ${TitleWrapper} {
    > * {
      color: ${(p) => (p.colored && p.color ? p.color : COLORS.default)};
    }
  }
`;

const DEFAULT_PROPS = {
  accepted: { title: 'Accepted', color: '#2CC68F', icon: CheckCircleOutlined },
  processing: { title: 'Processing...', color: '#8FA4B5', icon: ClockCircleOutlined },
  requested: { title: 'Requested', color: '#8FA4B5', icon: WarningOutlined },
  rejected: { title: 'Rejected', color: '#F5A623', icon: StopOutlined }
};
