import React, { useState } from 'react';
import { Modal, Input, Button, Select } from 'antd';
import { Container } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import { QUESTIONS_TYPE } from '../../../../utils/static/questionnaire';

export const AddQuestionModal = ({ open, onClose, questionGroupId, addQuestion }) => {
  const { t } = useTranslation();
  const [selectedQuestionType, setSelectedQuestionType] = useState('');
  const [title, setTitle] = useState('');

  return (
    <Modal open={open} onCancel={onClose} title={t('Add question')} okText={t('Add')}
           footer={[
             <Button key="back" onClick={onClose}>
               {t("Cancel")}
             </Button>,
             <Button type="primary" onClick={() => addQuestion({
               questionGroupId: questionGroupId,
               title: title,
               type: selectedQuestionType
             })}>
               {t('Add')}
             </Button>
           ]}>
      <Container marginBottom={15} marginTop={15}>
        <Select
          placeholder={'Choose question type'}
          onChange={(val) => setSelectedQuestionType(val)}
          options={QUESTIONS_TYPE}
          style={{ width: '100%' }}
        />
      </Container>
      {selectedQuestionType && <Container marginBottom={15} marginTop={15}>
        {(selectedQuestionType === "INPUT" || selectedQuestionType === "COUNTRY" || selectedQuestionType === "DATE") && <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={t('Enter question title')}
        />}
      </Container>}
    </Modal>
  );
};
