import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  deals: null,
  currentDeal: null,
  filters: null
};

const deals = createSlice({
  name: 'dealsReducer',
  initialState: initialState,
  reducers: {
    setDeals(state, action) {
      state.deals = action.payload;
    },
    setCurrentDeal(state, action) {
      state.currentDeal = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    }
  }
});

export const { setDeals, setCurrentDeal, setFilters } = deals.actions;

export default deals.reducer;
