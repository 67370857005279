import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../design2.0';

export const DetailsButton = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Button text={t('Details')} size={'small'} width={'75'} {...props} style={{ marginLeft: 'auto' }} />
  )
};
