import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GlobalOutlined from '@ant-design/icons/lib/icons/GlobalOutlined';
import { Dropdown } from 'antd';
import { useQuery } from 'react-query';
import {
  investorLocalizationApi,
  investorLocalizationKeys,
  adminLocalizationApi,
  adminLocalizationKeys
} from '../../../../api';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import { queryClient } from '../../../../app/QueryClientProvider';
import { investorActions } from '../../../../store/investor';

export const LanguageInvestor = ({ languageId }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data: languages } = useQuery({
    queryKey: investorLocalizationKeys.getAvailableLanguages,
    queryFn: investorLocalizationApi.getAvailableLanguages,
    select: (data) => data.data
  });

  useEffect(() => {
    try {
      if (languages) {
        const currentLang = languages?.find((i) => i.code === i18n.language.toUpperCase());

        if (currentLang.id !== languageId) {
          setLanguage({ key: currentLang.id });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [languages]);

  const setLanguage = async (value) => {
    try {
      await investorLocalizationApi.setLanguage({ languageId: value.key });

      const newLang = languages.find((i) => i.id === value.key)?.code?.toLowerCase();
      i18n.changeLanguage(newLang);

      localStorage.setItem('language', newLang);
      queryClient.refetchQueries();
      dispatch(investorActions.getInvestor(true));
      dispatch(investorActions.getAdditionalInfo());
    } catch (e) {}
  };

  if (!languages) return null;
  return (
    <Dropdown
      menu={{
        items: languages.map((item) => ({
          key: item.id,
          label: item.language,
          icon: i18n.language === item.code.toLowerCase() ? <CheckOutlined /> : null
        })),
        onClick: setLanguage
      }}
      trigger={'click'}
    >
      <div>
        <GlobalOutlined />
      </div>
    </Dropdown>
  );
};

const LanguageAdmin = ({ languageId }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data: languages } = useQuery({
    queryKey: adminLocalizationKeys.getAvailableLanguages,
    queryFn: adminLocalizationApi.getAvailableLanguages,
    select: (data) => data.data
  });

  useEffect(() => {
    try {
      if (languages) {
        const currentLang = languages?.find((i) => i.code === i18n.language.toUpperCase());

        if (currentLang.id !== languageId) {
          setLanguage({ key: currentLang.id });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [languages]);

  const setLanguage = async (value) => {
    try {
      await adminLocalizationApi.setLanguage({ languageId: value.key });

      const newLang = languages.find((i) => i.id === value.key)?.code?.toLowerCase();
      i18n.changeLanguage(newLang);

      localStorage.setItem('language', newLang);
      queryClient.refetchQueries();
    } catch (e) {}
  };

  if (!languages) return null;
  return (
    <Dropdown
      menu={{
        items: languages.map((item) => ({
          key: item.id,
          label: item.language,
          icon: i18n.language === item.code.toLowerCase() ? <CheckOutlined /> : null
        })),
        onClick: setLanguage
      }}
      trigger={'click'}
    >
      <GlobalOutlined />
    </Dropdown>
  );
};

