import React from 'react';
import {
  EmailInput,
  PhoneInput,
  PasswordInput,
  RepeatPasswordInput,
  TermsCheckbox,
  VerifyEmail,
  VerifyPhone,
  TextInput,
  AddressBox,
  CitizenshipDocBox,
  ResidencyInput,
  CitizenshipInput,
  UtilityBill,
  ProofOfFunds
} from './Fields';
import { FilePicker } from './components';

export const RenderField = ({ form, otherData }) => {
  const RenderedComponent = componentByType[form.name];

  if (RenderedComponent) {
    return <RenderedComponent form={form} otherData={otherData} label={form.name} />;
  }

  return null;
};

const componentByType = {
  email: EmailInput,
  phone: PhoneInput,
  password: PasswordInput,
  repeatPassword: RepeatPasswordInput,
  terms: TermsCheckbox,
  verifyEmail: VerifyEmail,
  verifyPhone: VerifyPhone,
  firstName: TextInput,
  lastName: TextInput,
  patronymic: TextInput,
  residency: ResidencyInput,
  citizenship: CitizenshipInput,
  homeAddress: AddressBox,
  mailAddress: AddressBox,
  citizenshipDocument: CitizenshipDocBox,
  idDocument: FilePicker,
  temporaryResidentship: TextInput,
  utilityBill: UtilityBill,
  proofOfFunds: ProofOfFunds
};
