import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { PhoneInput as _PhoneInput } from '../../../../ui/atoms';
import { PHONE_CODES } from '../../../../utils/static';
import { useTranslation } from 'react-i18next';

const DIVIDE_SYMBOL = '/';
export const PhoneInput = ({ form, label, required, ...props }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState();
  const {
    field: { ref, onChange, value, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });

  return (
    <_PhoneInput
      {...field}
      {...props}
      required={required}
      value={phone}
      setValue={setPhone}
      name={form.name}
      control={form.control}
      label={label}
      // codeError={error && error.type === 'isCode' && error.message}
      error={error}
      innerRef={ref}
    />
  );
};
