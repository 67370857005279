import { combineReducers } from '@reduxjs/toolkit';

import userReducer from './user/reducers';
import investorReducer from './investor/reducers';
import assetsReducer from './assets/reducers';
import checkoutReducer from './checkout/reducers';
import ordersReducer from './orders/reducers';
import dealsReducer from './deals/reducers';
import walletReducer from './wallet/reducers';
import entitiesReducer from './entities/reducers';

export const rootReducer = combineReducers({
  user: userReducer,
  investor: investorReducer,
  assets: assetsReducer,
  checkout: checkoutReducer,
  orders: ordersReducer,
  deals: dealsReducer,
  wallet: walletReducer,
  entities: entitiesReducer
});
