import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { history } from './routing';
import { GlobalStyles } from './globalStyles';
import { QueryClientProvider } from './app/QueryClientProvider';
import { MessageProvider } from './app/MessageProvider';
import './app/localization';
import { VenomProvider } from './app/venomAuth/VenomProvider';
import { ThemeProvider } from './app/ThemeProvider';

ReactDOM.render(
  <QueryClientProvider>
    <Provider store={store}>
      <Router history={history}>
        <VenomProvider>
          <ThemeProvider>
            <MessageProvider>
              <App />
              <GlobalStyles />
            </MessageProvider>
          </ThemeProvider>
        </VenomProvider>
      </Router>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
