import React from 'react';
import styled from 'styled-components';
import { DropDown as AragonDD, Text } from '@aragon/ui';
import { Label2 as InputLabel } from '../../../ui';
import { COLORS } from '../../../utils/static';
import { useTranslation } from 'react-i18next';

export const DropDown = ({
  label,
  icon,
  marginBottom,
  marginTop,
  minWidth,
  required,
  items = [],
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <InputWrapper marginTop={marginTop} marginBottom={marginBottom}>
      {label && (
        <InputLabel>
          {label}
          {required && <Text color={COLORS.aragonBlue}>&nbsp;*</Text>}
        </InputLabel>
      )}
      <InputContainer minWidth={minWidth}>
        <Select placeholder={label === t('Section') ? t('Select a section') : t('Select a type')} items={items} {...props} />

        {icon}
      </InputContainer>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  text-align: left;
  margin-top: ${(p) => (p.marginTop ? `${p.marginTop}px` : `0`)};
  margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : `0`)};
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: ${(p) => (p.minWidth ? p.minWidth : 'unset')};
  & > button {
    min-width: unset;
    width: 100%;
  }
`;
const Select = styled(AragonDD)`
  font-weight: 300;

  ~ * button {
    font-weight: 300;
  }
`;
