import React from 'react';
import { Box, ImageUploadBox, Info, InnerBox } from '../../../../components';
import { DatePicker, Input, Row, Col, Body1Light } from '../../../../ui';

export const IdDocumentsInputBox = ({ data, changeData, hideMartial = false }) => {
  return (
    <Box padding={0} marginBottom={24}>
      <InnerBox paddingRight={20}>
        <Body1Light>ID documents</Body1Light>
        <Row cols={2} margin={-10} marginTop={24}>
          <Col>
            <ImageUploadBox
              label={'Your ID document photo'}
              value={data.idDocumentPhoto}
              preview={data.idDocumentPhotoUrl}
              onChange={(file) => changeData('idDocumentPhoto', file)}
              required
            />
          </Col>

          <Col>
            <ImageUploadBox
              label={'Your selfie with ID'}
              value={data.selfieWithIdPhoto}
              preview={data.selfieWithIdPhotoUrl}
              onChange={(file) => changeData('selfieWithIdPhoto', file)}
              required
            />
          </Col>
        </Row>
      </InnerBox>

      <InnerBox paddingRight={20} paddingBottom={24}>
        <Row cols={2} margin={-10} alignItems={'flex-end'} colsMarginBottom={16}>
          <Input
            label={'ID'}
            value={data.idDocument}
            onChange={(e) => changeData('idDocument', e.target.value)}
            required
          />

          <Input
            label={'Document number'}
            value={data.documentNumber}
            onChange={(e) => changeData('documentNumber', e.target.value)}
            required
          />

          <Input
            label={'Personal code'}
            value={data.personalCode}
            onChange={(e) => changeData('personalCode', e.target.value)}
          />

          <DatePicker
            label={'Birth date'}
            selected={data.birthDate}
            onChange={(date) => changeData('birthDate', date)}
            required
          />

          <Input
            label={'issuing state'}
            value={data.issuingState}
            onChange={(e) => changeData('issuingState', e.target.value)}
            required
          />

          <Input
            label={'issuing organization'}
            value={data.issuingOrganization}
            onChange={(e) => changeData('issuingOrganization', e.target.value)}
            required
          />

          <DatePicker
            label={'date of issuance'}
            selected={data.dateOfIssuance}
            onChange={(date) => changeData('dateOfIssuance', date)}
            required
          />

          <DatePicker
            label={'date of expiry'}
            selected={data.dateOfExpiry}
            onChange={(date) => changeData('dateOfExpiry', date)}
            required
          />
        </Row>
      </InnerBox>

      {!hideMartial && (
        <InnerBox paddingX={28}>
          <Row margin={-8}>
            <Col padding={8} width={'25%'}>
              <Input
                label={'Marital status'}
                marginBottom={16}
                value={data.maritalStatus}
                onChange={(e) => changeData('maritalStatus', e.target.value)}
                required
              />

              <Info>Spouse's consent is required to open an account</Info>
            </Col>

            <Col padding={8} width={'50%'}>
              <Input
                label={'Name Surname of spouse'}
                value={data.nameSurnameOfSpouse}
                onChange={(e) => changeData('nameSurnameOfSpouse', e.target.value)}
              />
            </Col>
          </Row>
        </InnerBox>
      )}
    </Box>
  );
};
