import React from 'react';
import { OptionCard, Button, Tooltip } from '../../../../components';
import { Link, Row, Title2, Container, Block, BREAKPOINTS } from '../../../../ui';
import { COLORS } from '../../../../utils/static';
import CreatePersonalIcon from '../../../../assets/entry-pages/create-personal.svg';
import { useTranslation } from 'react-i18next';
import arrowBackIcon from '../../../../assets/icons/arrow_back.svg';
import infoIcon from '../../../../assets/icons/info.svg';
import styled from 'styled-components';
import { Typography } from 'antd';

export const AssistantRegStart = ({ history }) => {
  const { t } = useTranslation();

  return (
    <StyledMainRow
      flexDirection={'column'}
      paddingY={120}
      alignCenter
      justifyContent={'center'}
      paddingX={16}
    >
      <StyledContainer>
        <Button
          size='large'
          type='text'
          text={t('Back')}
          icon={arrowBackIcon}
          onClick={() => history.push('/')}
        />
      </StyledContainer>

      <Row alignCenter gap={8} marginBottom={37}>
        <Container marginTop={'-5'}>
          <StyledTitle2>{t('Create your own assistant account in a few minutes!')}</StyledTitle2>
        </Container>
        <Tooltip
          placement='topLeft'
          arrow={{ pointAtCenter: true }}
          title={t('The Service is generally available only to persons of legal age and legal capacity under local law.')}
        >
          <p>
            <Img src={infoIcon} />
          </p>
        </Tooltip>
      </Row>

      <Row gap={64} marginBottom={48} justifyContent={'center'}>
        <OptionCard
          title={t('Create assistant \naccount')}
          className={'col-auto'}
          onClick={() => {
            history.push('/registration/assistant/1', { account: 'RECOMMENDER' });
          }}
          icon={CreatePersonalIcon}
        />
      </Row>

      <Row alignCenter nowrap marginBottom={4} marginLeft={14}>
        <Container marginRight={'-7'}>
          <Text>{t('Already have an account?')}</Text>
        </Container>
        <Block>
          <Link to={'/login'}>
            <Button type='text' size='large' text={t('Log in')} />
          </Link>
        </Block>
      </Row>

      <SmallText>
        {t('If you have any questions, please contact us')} —{' '}
        <a href='mailto:compliance@casecapital.co.il'>compliance@casecapital.co.il</a>
      </SmallText>
    </StyledMainRow>
  );
};

const StyledMainRow = styled(Row)`
    min-height: 100vh;
    min-height: 100dvh;

    @media (max-width: ${BREAKPOINTS.smd}px) {
        padding-top: 70px;
    }
`;

const StyledContainer = styled(Container)`
  position: absolute;
  top: 60px;
  left: 60px;
  width: min-content;
  @media (max-width: ${BREAKPOINTS.md}px) {
    top: 15px;
    left: 15px;
  }
`;

const Img = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledTitle2 = styled(Title2)`
  line-height: normal;
`;

const Text = styled(Typography.Text)`
  font-size: 18px;
  color: ${COLORS.greyMedium};
`;

const SmallText = styled(Typography.Text)`
  font-size: 12px;
  color: ${COLORS.greyMedium};
`;
