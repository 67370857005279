import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Link, Row, Title2, Container, Block, BREAKPOINTS } from '../../../ui';
import { Button, Tooltip, OptionCard } from '../../../components';
import { COLORS } from '../../../utils/static';
import arrowBackIcon from '../../../assets/icons/arrow_back.svg';
import infoIcon from '../../../assets/icons/info.svg';
import personalAccountIcon from '../../../assets/entry-pages/icon-personal-account.svg';
import companyAccountIcon from '../../../assets/entry-pages/icon-company-account.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';

const InvestorRegStart = ({ history }) => {
  const { t } = useTranslation();

  return (
    <StyledMainRow
      flexDirection={'column'}
      paddingY={120}
      alignCenter
      justifyContent={'center'}
      paddingX={16}
    >
      <StyledContainer>
        <Button
          size='large'
          type='text'
          text={t('Back')}
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push('/')}
        />
      </StyledContainer>

      <Row alignCenter gap={8} marginBottom={37}>
        <Container marginTop={'-5'}>
          <StyledTitle2>{t('Create your own account in a few minutes!')}</StyledTitle2>
        </Container>
        <Tooltip
          placement='topLeft'
          arrow={{ pointAtCenter: true }}
          title={t(
            'The Service is generally available only to persons of legal age and legal capacity under local law.'
          )}
        >
          <p>
            <Img src={infoIcon} />
          </p>
        </Tooltip>
      </Row>

      <Row gap={64} marginBottom={48} justifyContent={'center'}>
        <OptionCard
          title={t('Create personal \naccount')}
          maxWidth={264}
          iconSize={90}
          gap={36}
          onClick={() => {
            history.push('/registration/investor/1', { account: 'PERSONAL' });
          }}
          icon={personalAccountIcon}
        />
        <OptionCard
          title={t('Create \ncompany account')}
          maxWidth={264}
          iconSize={90}
          gap={36}
          onClick={() => {
            history.push('/registration/investor/1', { account: 'COMPANY' });
          }}
          icon={companyAccountIcon}
        />
      </Row>

      <Row alignCenter nowrap marginBottom={4} marginLeft={14}>
        <Container marginRight={'-7'}>
          <Text>{t('Already have an account?')}</Text>
        </Container>
        <Block>
          <Link to={'/login'}>
            <Button type='text' size='large' text={t('Log in')} />
          </Link>
        </Block>
      </Row>

      <SmallText>
        {t('If you have any questions, please contact us')} —{' '}
        <a href='mailto:compliance@casecapital.co.il'>compliance@casecapital.co.il</a>
      </SmallText>
    </StyledMainRow>
  );
};

export default InvestorRegStart;

const StyledMainRow = styled(Row)`
  min-height: 100vh;
  min-height: 100dvh;

  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 70px;
  }
`;

const StyledContainer = styled(Container)`
  position: absolute;
  top: 60px;
  left: 60px;
  width: min-content;
  @media (max-width: ${BREAKPOINTS.md}px) {
    top: 15px;
    left: 15px;
  }
`;

const Img = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledTitle2 = styled(Title2)`
  line-height: normal;
`;

const Text = styled(Typography.Text)`
  font-size: 18px;
  color: ${COLORS.greyMedium};
`;

const SmallText = styled(Typography.Text)`
  font-size: 12px;
  color: ${COLORS.greyMedium};
`;
