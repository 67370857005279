import React from 'react';
import { Tabs as AntdTabs } from 'antd';
import { Container } from '../../../ui';
import styled from 'styled-components';

export const Tabs = ({ containerProps, ...props }) => {
  const handleTabChange = (key) => {
    props.setTab(key)
  }
  return (
    <StyledContainer {...containerProps}>
      <AntdTabs {...props} onTabClick={(key)=>handleTabChange(key)}/>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  > div {
    border-radius: 12px;
  }
  button {
    font-weight: 300;
  }
`;
