import React from 'react';
import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';

export const Select = ({ placeholder, placement, options, ...props }) => {
  return (
    <StyledAntdSelect
      size='large'
      placement={placement}
      placeholder={placeholder}
      suffixIcon={<ArrowDown width={19} height={19} />}
      options={options}
      {...props}
    ></StyledAntdSelect>
  );
};

const StyledAntdSelect = styled(AntdSelect)`
  width: 100%;
  .ant-select-selector {
    padding: 0 8px 0 16px !important;
    border-radius: 4px !important;
  }
`;
