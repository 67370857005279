import React from 'react';
import { Form } from 'antd';
import styled from 'styled-components';

export const Label = ({ label, required, marginTop, children, onChange }) => {
  return (
    <StyledLabel label={label} required={required} marginTop={marginTop} onChange={onChange}>
      {children}
    </StyledLabel>
  );
};

const StyledLabel = styled(Form.Item)`
  ${({ marginTop }) => marginTop && `margin-top:${marginTop}px;`}
  margin-bottom: unset;
  label {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 150% !important;
    color: #27272a !important;
    position: relative !important;
  }
  label.ant-form-item-required::before {
    margin-inline-end: unset !important;
    position: absolute !important;
    right: 0 !important;
    /* right: -15px !important; */
  }
`;
