import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS, Col, Container, Row } from '../../../../ui';
import { Text } from '../Text';
import { COLORS } from '../../../../utils/static';
import { STATUS_COLOR, STATUS_DISPLAY, STATUS_VERIFICATION_DISPLAY } from '../../../../utils/static/kyc';
import { dateString, displayFullDate } from '../../../../utils/helpers';
import { ReactComponent as NewStatusIcon } from '../../../../assets/icons/status/new.svg';
import { ReactComponent as PassedStatusIcon } from '../../../../assets/icons/status/passed.svg';
import { ReactComponent as InReviewStatusIcon } from '../../../../assets/icons/status/in-review.svg';
import { ReactComponent as InProcessStatusIcon } from '../../../../assets/icons/status/in-process.svg';
import { ReactComponent as RejectedStatusIcon } from '../../../../assets/icons/status/rejected.svg';
import { ReactComponent as DraftStatusIcon } from '../../../../assets/icons/status/draft.svg';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const HeaderTable = ({ isVerification }) => {
  const { t } = useTranslation();

  return (
    <Row nowrap gap={24} justifyContent='space-between'>
      <Col width={'111px'}>
        <Text fontSize={14} color={COLORS.greyText2}>
          {t('Status')}
        </Text>
      </Col>

      <Col width={'258px'}>
        <Text fontSize={14} color={COLORS.greyText2}>
          {t('Name')}
        </Text>
      </Col>

      <StyledCol width={'196px'}>
        <Text fontSize={14} color={COLORS.greyText2}>
          {t('Created')}
        </Text>
      </StyledCol>

      <Col width={'201px'}>
        <Text fontSize={14} color={COLORS.greyText2}>
          {t('Updated')}
        </Text>
      </Col>

      <Col width={'201px'}>
        <Text fontSize={14} color={COLORS.greyText2}>
          {t('Person / Company')}
        </Text>
      </Col>

      {isVerification &&
        <Col width={'201px'}>
          <Text fontSize={14} color={COLORS.greyText2}>
            {t('Comment')}
          </Text>
        </Col>
      }

      <StyledCol width={'100px'}>
        <Text fontSize={14} color={COLORS.greyText2}>
          {t('Expiration date')}
        </Text>
      </StyledCol>
    </Row>
  );
};

const Item = ({ data, onClick }) => {
  const { investor } = useSelector((state) => state);
  const { t } = useTranslation();

  const boxTitle = data?.name || data?.kycTypeDocument?.name || data?.questName;
  const boxStatus = data?.status;

  const STATUS_ICON = {
    PENDING: <NewStatusIcon width={16} height={16} />,
    DRAFT: <DraftStatusIcon width={16} height={16} />,
    PROCESSING: <InReviewStatusIcon width={16} height={16} />,
    QUESTION_UNACCEPTED: <InProcessStatusIcon width={16} height={16} />,
    UNACCEPTED: <RejectedStatusIcon width={16} height={16} />,
    'New': <NewStatusIcon width={16} height={16} />,
    'In process': <InReviewStatusIcon width={16} height={16} />,
    'Rejected': <RejectedStatusIcon width={16} height={16} />,
    'Approved': <PassedStatusIcon width={16} height={16} />
  };

  return (
    <>
      <StyledRow clickable={boxStatus !== 'PROCESSING'} nowrap gap={24} paddingY={30} justifyContent='space-between' onClick={boxStatus !== 'PROCESSING' ? onClick : () => {}}>
        <Col width={'111px'}>
          <Row nowrap alignCenter gap={8}>
            {STATUS_ICON[boxStatus]}

            <Text fontWeight={600} color={STATUS_COLOR[boxStatus]}>
              {data && data.questName === 'Verification' ? t(`${STATUS_VERIFICATION_DISPLAY[boxStatus]}`) : t(`${STATUS_DISPLAY[boxStatus]}`).toLocaleLowerCase()}
            </Text>
          </Row>
        </Col>

        <Col width={'258px'}>
          <Text color={COLORS.blueAntd}>{t(`${boxTitle}`)}</Text>
        </Col>

        <StyledCol width={'196px'}>
          <Text>{data?.created ? displayFullDate(new Date(data?.created)) : '-'}</Text>
        </StyledCol>

        <Col width={'201px'}>
          <Text>{data?.updated ? displayFullDate(new Date(data?.updated)) : '-'}</Text>
        </Col>

        <Col width={'201px'}>
          <Text>{data?.person ?? data?.company ?? '-'}</Text>
        </Col>

        {data.questName === "Verification" && data.comment && <Col width={'201px'}>
          <Text>{data?.comment ?? '-'}</Text>
        </Col>}

        <StyledCol width={'100px'}>
          <Text> {data?.expiration ? dateString(new Date(data?.expiration)) : '-'}</Text>
        </StyledCol>
      </StyledRow>

      <Line />
    </>
  );
};

export const Table = ({ data, onClick, isVerification, getFuncFromObj = false }) => {
  const { t } = useTranslation();

  const onClickFunc = (item) => {
    return onClick ? onClick(item) : getFuncFromObj ? item.onClick(item.id) : () => {};
  };

  return (
    <>
      <HeaderTable isVerification={isVerification} />

      {isVerification ?
        <Item data={data} onClick={() => onClickFunc(data)} />
        : <>
          {data.length !== 0 ? (
            data.map((item, index) => (
              <Item key={index} data={item} onClick={() => onClickFunc(item)} />
            ))
          ) : (
            <Empty description={<span>{t('No data')}</span>} />
          )}
        </>
      }
    </>
  );
};

const StyledRow = styled(Row)`
    ${p => p.clickable ? `
        cursor: pointer;
  ` : 'pointer-events: none;'}
    
    &:hover {
      opacity: 0.6;
    }
`;

const Line = styled.div`
  height: 1px;
  background-color: #e4e4e4;
`;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    display: none;
  }
`;
