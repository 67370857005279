import React, { useState } from 'react';
import {
  InvestorActivity,
  InvestorDocuments,
  InvestorReferralRequests
} from '../../../../../containers';
import { Tabs, ProfileInfoView, BackBar, StatusLine, ProfileStatus } from '../../../../../components';
import { Button, PageContainer, Row, Col, Title2, Body3Light } from '../../../../../ui';
import { INVESTOR_TYPES_CONFIG } from '../../../../../utils/static';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const InvestorDetailsView = ({ investorData, approveInvestor, rejectInvestor }) => {
  const { t } = useTranslation();
  const { userRole } = useSelector((state) => state.user);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isEditSection, setIsEditSection] = useState(false);
  const [saveTab, setSaveTab] = useState('');

  const handleSave = () => {
    setSaveTab('ProfileInfo');
  };

  const saveCallback = () => {
    setIsEditSection(false);
    setSaveTab('');
  };

  return (
    <PageContainer>
      <Row justifyContent={'space-between'} nowrap gap={16}>
        <Row margin={-8} alignItems={'center'}>
          <Title2>{t('Investor for approval')}</Title2>
          {investorData.account && (
            <StatusLine
              statusTypes={INVESTOR_TYPES_CONFIG}
              type={investorData.account}
              TitleStyle={Body3Light}
              titleMargin={8}
            />
          )}
        </Row>

        <Row margin={-12}>
          <Col>
            <Button
              onClick={rejectInvestor}
              disabled={investorData.accountStatus === 'NOT_ACTIVE'}
              paddingX={'26'}
            >
              {investorData.accountStatus === 'NOT_ACTIVE' ? t('Blocked') : t('Block')}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={approveInvestor}
              disabled={investorData.accountStatus === 'ACTIVE'}
              mode={'strong'}
              paddingX={'18'}
            >
              {t('Active')}
            </Button>
          </Col>
        </Row>
      </Row>

      <BackBar url={'/investors/PERSONAL'} />

      <Tabs
        items={[
          {
            key: 0,
            label: t('Personal data'),
          },
          {
            key: 1,
            label: t('Questionnaires'),
          },
          {
            key: 2,
            label: t('Documents'),
          },
          {
            key: 3,
            label: t('Referral requests'),
          },
          {
            key: 4,
            label: t('Activity'),
          }
        ]}
        activeKey={selectedTab}
        setTab={setSelectedTab}
        containerProps={{ marginTop: 24 }}
      />

      {/*<Container marginBottom={2*8}>*/}
      {/*  {!isEditSection && <Button*/}
      {/*    onClick={() => setIsEditSection(true)}*/}
      {/*    label={'Edit section'}*/}
      {/*    paddingX={2*8}*/}
      {/*    disabled={!investorData.registrationFinished}*/}
      {/*  />}*/}

      {/*  {isEditSection && <Row margin={-8}>*/}
      {/*    <Col auto>*/}
      {/*      <Button*/}
      {/*        label={'Cancel'}*/}
      {/*        onClick={() => setIsEditSection(false)}*/}
      {/*        paddingX={3*8}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*    <Col auto>*/}
      {/*      <Button*/}
      {/*        label={'Save'}*/}
      {/*        onClick={handleSave}*/}
      {/*        mode={'strong'}*/}
      {/*        paddingX={3*8}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>}*/}
      {/*</Container>*/}

      {/*<ProfileInfo/>*/}
      {selectedTab === 0 && investorData && (
        <ProfileInfoView
          investor={investorData}
          isEdit={isEditSection}
          saveTab={saveTab}
          saveCallback={saveCallback}
          viewMode={userRole}
        />
      )}

      {selectedTab === 1 && investorData?.id && (
        <ProfileStatus investorData={investorData} adminView />
      )}

      {/*Documents*/}
      {selectedTab === 2 && <InvestorDocuments />}

      {/*Referral requests*/}
      {selectedTab === 3 && <InvestorReferralRequests />}

      {/*Activity*/}
      {selectedTab === 4 && <InvestorActivity />}
    </PageContainer>
  );
};
