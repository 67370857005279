import React from 'react';
import { UploadDocumentsBox } from '../../../../components';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { DOCS_SUBTYPES } from '../../../../utils/static/kyc';

export const ProofOfFunds = ({ form, label, ...props }) => {
  const {
    field: { onChange, value, name },
    fieldState: { error }
  } = useController({
    ...form,
    rules: { required: 'Required' },
    defaultValue: []
  });

  const handleChange = (nextValue) => {
    const clone = nextValue.map((item) => ({ file: item }));
    onChange(clone);
  };

  const setSubtype = (nextType, index) => {
    const clone = [...value];
    clone[index] = { ...clone[index], subtype: nextType };
    onChange(clone);
  };

  if (error) console.log({ error });

  return (
    <Container>
      <UploadDocumentsBox
        form={form}
        label={'Proof of funds'}
        docs={value ? value.map((item) => item.file) : null}
        setDocs={handleChange}
        types={DOCS_SUBTYPES}
        setType={setSubtype}
        multiple
        {...props}
      />
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
`;
