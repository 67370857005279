import { SearchInput as SI, Pagination as PG } from '@aragon/ui';
import styled from 'styled-components';

export * from './Box';
export * from './IdentityBadge';
export * from './Tabs';
export * from './DateRangePicker';
export * from './RadioGroup';

export const SearchInput = styled(SI)`
  font-weight: 300;
  ${(p) => (p.minWidth ? `min-width: ${p.minWidth}px;` : '')}
`;

export const Pagination = styled(PG)`
  button {
    font-weight: 300;
  }
`;
