import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link, GU } from '@aragon/ui';
import { Row, Body3Light as Title } from '../../../../ui';
import { COLORS } from '../../../../utils/static';
import AttachedIcon from '../../../../assets/icons/attachment.svg';
import { downloadFileByLink, getFilenameFromUrl } from '../../../../utils/helpers';
import { MessageContext } from '../../../../app/MessageProvider';
import { useTranslation } from 'react-i18next';

/**
 * @param file
 * @param title
 * @param url
 * @param props
 */
export const AttachedFile = ({ file = null, title = '', url = '', ...props }) => {
  const { t } = useTranslation();
  const messageApi = useContext(MessageContext);
  let formatTitle = title || getFilenameFromUrl(url);

  //format long title
  if (formatTitle.length > 20) {
    let titleArray = formatTitle.split('.');

    let extension = titleArray[titleArray.length - 1];

    titleArray.pop();

    let titleString = titleArray.join();

    titleString = titleString.substr(0, 17);
    titleString += '...';

    formatTitle = titleString + extension;
  }

  const onDownload = async () => {
    try {
      await downloadFileByLink(formatTitle, url);
    } catch (e) {
      messageApi.error(t('Something went wrong'));
    }
  };

  return (
    <Row alignItems={'center'} {...props}>
      <Icon src={AttachedIcon} />
      <Link onClick={onDownload}>
        <Title color={COLORS.blueAntd}>{formatTitle}</Title>
      </Link>
    </Row>
  );
};

const Icon = styled.img`
  margin-right: ${GU}px;
  margin-top: -1px;
`;
