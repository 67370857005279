import Web3 from 'web3';

export const signMessage = async (message, wallet) => {
  let web3 = new Web3(window.ethereum);
  let userWallet = wallet;

  if (!userWallet) userWallet = (await web3.eth.getAccounts())[0];

  return web3.eth.personal.sign(message, userWallet);
};

export const web3Api = {
  signMessage
};
