import React from 'react';
import styled from 'styled-components';
import { GU, BackButton as ButtonBack, Button, IconArrowRight } from '@aragon/ui';
import { history } from '../../../routing';
import { withTranslation } from 'react-i18next';

export const FooterButtons = withTranslation()(
  ({
     t,
     onNext,
     onBack,
     NextButtonTitle = t('Next'),
     withoutArrow = false,
     nextDisabled = false,
     hideBack = false,
     hideNext = false,
     noMargin = false
   }) => {
    return (
      <ButtonsContainer noMargin={noMargin}>
        <BackButton
          label={t('Back')}
          onClick={() => {
            onBack ? onBack() : history.goBack();
          }}
          hide={hideBack}
        />
        {onNext && (
          <NextButton
            label={NextButtonTitle}
            mode='strong'
            icon={withoutArrow ? null : <IconArrowRight />}
            onClick={onNext}
            disabled={nextDisabled}
            hide={hideNext}
          />
        )}
      </ButtonsContainer>
    );
  }
);

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  ${(p) => (!p.noMargin ? 'margin-top: 34px;' : '')}
`;
const BackButton = styled(ButtonBack)`
  // margin-top: ${4 * GU}px;
  width: ${12 * GU}px;
  height: ${5 * GU}px;
  border: 1px solid #dde4e9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  padding: 0;
  justify-content: center;
  ${(p) => (p.hide ? 'visibility: hidden;' : '')}
  & > span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
  }
`;
const NextButton = styled(Button)`
  ${(p) => (p.hide ? 'visibility: hidden;' : '')}
  display: flex;
  flex-direction: row-reverse;
  padding: 0 12px 0 18px;
  & > span {
    margin: 0 0 0 8px;
  }
`;
