import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { investorApi, kycApi } from '../../../../api';
import { investorActions } from '../../../../store/investor';
import { useDispatch } from 'react-redux';
import { Button, Text, Checkbox } from '../../../../components';
import { Block, Row } from '../../../../ui';
import { CountrySelect, PhoneInput } from '../../../../modules/kycModule/Fields';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../utils/static';
import { entitiesActions } from '../../../../store/entities';
import { history } from '../../../../routing';
import { useCountries } from '../../../../hooks';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../../modules/kycModule/components';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { parsePhoneNumber } from 'react-phone-number-input';

export const Step1 = ({ stepNumber, goNext }) => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const COUNTRIES = useCountries();
  const { control, handleSubmit, getValues, setError } = useForm();
  const [isEmailValid, setEmailValid] = useState(null);
  const [isTermsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    if (!state || !state.account) history.push('/registration/investor');
  }, []);

  const checkValidation = (email, phone) => {
    return new Promise((resolve, reject) => {
      if (email || phone) {
        investorApi
          .phoneEmailValidation(phone, email.toLowerCase())
          .then((res) => {
            if (res.status === 200) {
              const { data } = res;

              setEmailValid(data.validEmail);
              if (!data.validEmail || !data.validPhone) {
                reject('UNIQUE_FAILED');
              }

              resolve();
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      }
    });
  };

  const getCitizenshipObj = (citizenship) => {
    return COUNTRIES.filter((item) => item.id === citizenship)[0];
  };

  const handleNext = async (data) => {
    try {
      dispatch(entitiesActions.showLoader(t('Processing') + '...'));

      const { email, phone, password, firstName, lastName, avatar, citizenship } = data;
      const phoneObj = parsePhoneNumber(phone);

      await checkValidation(email, `${phoneObj.countryCallingCode}${phoneObj.nationalNumber}`);

      const citizenshipId = getCitizenshipObj(citizenship);
      if (!citizenshipId || !citizenshipId.id) {
        setError('citizenship', {
          type: 'custom',
          message: t('Citizenship must be selected from the list')
        });
        throw new Error('Citizenship');
      }

      const res = await investorApi.createInvestor({
        email: email.toLowerCase(),
        numberOfSymbolsInCode: phoneObj.countryCallingCode.length,
        phone: `${phoneObj.countryCallingCode}${phoneObj.nationalNumber}`,
        password: password,
        account: state.account,
        firstName: firstName,
        lastName: lastName
      });

      if (res.status === 201) {
        const { accessToken, role, ...investor } = res.data;
        localStorage.setItem('token', accessToken);
        localStorage.setItem('userRole', role);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        dispatch(investorActions.setInvestor(investor));

        goNext();

        await investorApi.setCitizenship(citizenshipId.id);
        if (avatar) await investorApi.uploadAvatar(avatar);
      }
    } catch (e) {
      if (e === 'UNIQUE_FAILED') {
        dispatch(entitiesActions.addToast(t('Email or phone is already taken')));
      } else {
        dispatch(entitiesActions.addToast(t('Something went wrong')));
      }
      // checkValidation();
    } finally {
      dispatch(entitiesActions.hideLoader());
      kycApi.generateQuestionnaires().then().catch();
    }
  };

  console.log(isEmailValid);
  return (
    <>
      <Block maxWidth={490} wide marginTop={24}>
        <Form layout='vertical'>
          <FormInput
            form={{ control, name: 'firstName', rules: { required: t('Required') } }}
            label={t('First name')}
            maxLength={20}
            required
          />

          <FormInput
            form={{ control, name: 'lastName', rules: { required: t('Required') } }}
            label={t('Last name')}
            maxLength={20}
            required
            marginTopLabel={24}
          />

          <CountrySelect
            style={{ width: '100%' }}
            label={t('Citizenship')}
            form={{ control, name: 'citizenship', rules: { required: t('Required') } }}
            required
            marginTopLabel={24}
            block
          />

          <FormInput
            label={t('E-mail')}
            form={{
              control,
              name: 'email',
              rules: {
                required: t('Required'),
                validate: (v) => EMAIL_REGEX.test(v.toLowerCase()) || t('Invalid e-mail address')
              }
            }}
            marginTopLabel={24}
            textTransform={'lowercase'}
            required
          />

          <PhoneInput
            form={{ control, name: 'phone', rules: { required: t('Required') } }}
            marginTopLabel={24}
            required
          />

          <FormInput
            form={{
              control,
              name: 'password',
              rules: {
                required: t('Required'),
                pattern: {
                  value: PASSWORD_REGEX,
                  message: t(
                    'At least 8 characters, both numbers and latin symbols in upper- and lowercase.'
                  )
                },
                deps: ['repeatPassword']
              }
            }}
            marginTopLabel={24}
            label={t(
              'Password (At least 8 characters, both numbers and latin symbols in upper- and lowercase)'
            )}
            required
            password
          />

          <FormInput
            form={{
              control,
              name: 'repeatPassword',
              rules: {
                required: t('Required'),
                validate: (v) => getValues('password') === v || t('Passwords must match')
              }
            }}
            marginTopLabel={24}
            label={t('Repeat password')}
            required
            password
          />

          <Row marginTop={12} paddingLeft={2} alignCenter>
            <Checkbox onChange={() => setTermsChecked(!isTermsChecked)} checked={isTermsChecked}>
              <Text>{t('I agree with')}</Text>
            </Checkbox>

            <Block marginLeft={'-16'}>
              <Button type='text' size='large' text={t('Privacy Policy')} />
            </Block>
          </Row>

          <Row marginTop={28}>
            <Block width={140}>
              <Button
                type='filled'
                size='large'
                text={t('Next')}
                onClick={handleSubmit(handleNext)}
                disabled={!isTermsChecked}
              />
            </Block>
          </Row>
        </Form>
      </Block>
    </>
  );
};
