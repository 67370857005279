import React, { useState } from 'react';
import { IconView } from '../../../../ui';
import { FormInput } from '../../components';
import { PASSWORD_REGEX } from '../../../../utils/static';
import { useWatch } from 'react-hook-form';

export const PasswordInput = ({ form, ...props }) => {
  const [isShowPassword, setShowPassword] = useState(false);

  return (
    <FormInput
      form={{
        ...form,
        rules: passwordRules
      }}
      label={'Password'}
      type={isShowPassword ? 'text' : 'password'}
      icon={<IconView onClick={() => setShowPassword(!isShowPassword)} />}
      {...props}
    />
  );
};

export const RepeatPasswordInput = ({ form, ...props }) => {
  const [isShowPassword, setShowPassword] = useState(false);
  const passwordValue = useWatch({
    control: form.control,
    name: 'password'
  });

  return (
    <FormInput
      form={{
        ...form,
        rules: repeatPasswordRules(passwordValue)
      }}
      label={'repeat password'}
      type={isShowPassword ? 'text' : 'password'}
      icon={<IconView onClick={() => setShowPassword(!isShowPassword)} />}
      {...props}
    />
  );
};

const passwordRules = {
  required: 'Required',
  pattern: {
    value: PASSWORD_REGEX,
    message: 'At least 8 characters, both numbers and latin symbols in upper- and lowercase.'
  }
};
const repeatPasswordRules = (passwordValue) => {
  return {
    required: 'Required',
    validate: (value) => value === passwordValue || 'Passwords must match'
  };
};
