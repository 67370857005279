import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { COLORS } from '../../../../utils/static';

export const OptionCard = ({
  icon,
  title = '',
  onClick,
  iconSize = 98,
  maxWidth = 264,
  gap = 25
}) => {
  const styleTypography = {
    color: COLORS.default,
    fontWeight: 400,
    textAlign: 'center'
  };
  return (
    <Container onClick={onClick} maxWidth={maxWidth} gap={gap}>
      <Icon src={icon} alt='' width={iconSize} height={iconSize} />

      <Typography.Title level={4} style={styleTypography}>
        {title}
      </Typography.Title>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 55px;
  width: 100%;
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
  height: 262px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ gap }) => `${gap}px`};
  background: ${COLORS.white};
  box-shadow: 0px 12px 24px 0px rgba(0, 111, 255, 0.2);
  cursor: pointer;
  box-sizing: border-box;
`;

const Icon = styled.img`
  ${({ width }) => `width:${width}px`};
  ${({ height }) => `height:${height}px`};
`;
