import React from 'react';
import { Box, ImageUploadBox } from '../../../../components';
import { Input, Row } from '../../../../ui';

export const CompanyInfoInputBox = ({ data, changeData, required = false }) => {
  return (
    <Box paddingBottom={24}>
      <ImageUploadBox
        label={'Company name'}
        value={data.logo}
        onChange={(file) => changeData('logo', file)}
        required={required}
        labelMarginBottom={12}
      />

      <Row
        cols={2}
        margin={-8}
        marginTop={24}
        colsMarginBottom={16}
        alignItems={'flex-end'}
      >
        <Input
          label={'Company name'}
          value={data.name}
          onChange={(e) => changeData('name', e.target.value)}
          required
        />

        <Input
          label={'Company type'}
          value={data.type}
          onChange={(e) => changeData('type', e.target.value)}
          required
        />

        <Input
          label={'E-mail'}
          value={data.email}
          onChange={(e) => changeData('email', e.target.value)}
          required
        />

        <Input
          label={'Website'}
          value={data.website}
          onChange={(e) => changeData('website', e.target.value)}
          required
        />

        <Input
          label={'Rating'}
          value={data.rating}
          onChange={(e) => changeData('rating', e.target.value)}
          required
        />
      </Row>
    </Box>
  );
};
