import React from 'react';
import { Checkbox } from '@aragon/ui';
import { Container, Body2Light, ExternalLink } from '../../../../ui';
import styled from 'styled-components';
import { API_URL } from '../../../../utils/static';

//ToDo: rename to PrivacyCheckbox
export const TermsCheckbox = ({ checked, setChecked, ...props }) => {
  return (
    <Container {...props}>
      <CheckboxWrapper alignItems={'center'}>
        <Checkbox checked={checked} onChange={(checked) => setChecked(checked)} />

        <Body2Light marginLeft={8}>
          I agree with&nbsp;
          <ExternalLink href={`${API_URL}/api/v1/statics/privacy_policy.pdf`}>
            Privacy Policy
          </ExternalLink>
        </Body2Light>
      </CheckboxWrapper>
    </Container>
  );
};

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
