import styled from 'styled-components';
import { COLORS } from '../../utils/static/colors';

export const BREAKPOINTS = {
  xxl: 1600,
  xl: 1400,
  lg: 1100,
  md: 850,
  smd: 650,
  sm: 460,
  xs: 360
};

export const Container = styled.div`
  ${(p) => (p.padding ? `padding: ${p.padding}px` : '')};
  ${(p) => (p.paddingTop || p.paddingY ? `padding-top: ${p.paddingTop || p.paddingY}px` : '')};
  ${(p) =>
    p.paddingBottom || p.paddingY ? `padding-bottom: ${p.paddingBottom || p.paddingY}px` : ''};
  ${(p) => (p.paddingLeft || p.paddingX ? `padding-left: ${p.paddingLeft || p.paddingX}px` : '')};
  ${(p) =>
    p.paddingRight || p.paddingX ? `padding-right: ${p.paddingRight || p.paddingX}px` : ''};
  ${(p) => (p.marginTop || p.marginY ? `margin-top: ${p.marginTop || p.marginY}px` : '')};
  ${(p) => (p.marginBottom || p.marginY ? `margin-bottom: ${p.marginBottom || p.marginY}px` : '')};
  ${(p) => (p.marginLeft || p.marginX ? `margin-left: ${p.marginLeft || p.marginX}px` : '')};
  ${(p) => (p.marginRight || p.marginX ? `margin-right: ${p.marginRight || p.marginX}px` : '')};
  ${(p) => (p.relative ? `position: relative;` : '')}

  ${(p) => `
  ${p.hidden ? 'display: none;' : ''}
    @media (max-width: ${BREAKPOINTS.xl}px) {
    ${p.xlHidden ? 'display: none;' : ''}
    ${p.xlVisible ? 'display: block;' : ''}
      ${p.xlMaxWidth ? `max-width: ${p.xlMaxWidth};` : ''}
      ${p.xlMinHeight ? `min-height: ${p.xlMinHeight};` : ''}
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    ${p.lgHidden ? 'display: none;' : ''}
    ${p.lgVisible ? 'display: block;' : ''}
      ${p.lgTextCenter ? `text-align: center;` : ''}
      ${p.lgMaxWidth ? `max-width: ${p.lgMaxWidth}px;` : ''}
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${p.mdHidden ? 'display: none;' : ''}
    ${p.mdVisible ? 'display: block;' : ''}
      ${p.mdMinHeight ? `min-height: ${p.mdMinHeight};` : ''}
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${p.smdHidden ? 'display: none;' : ''}
    ${p.smdVisible ? 'display: block;' : ''}
      ${p.smdMaxWidth ? `max-width: ${p.smdMaxWidth};` : ''}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${p.smHidden ? 'display: none;' : ''}
    ${p.smVisible ? 'display: block;' : ''}
      ${p.smMaxWidth ? `max-width: ${p.smMaxWidth};` : ''}
      ${p.smStatic ? `position: static;` : ''}
  }
  `};
`;
export const Relative = styled(Container)`
  position: relative;
`;

export const PageContainer = styled(Container)`
  padding-bottom: ${({ paddingBottom, paddingY }) =>
    paddingBottom || paddingY ? `${paddingBottom || paddingY}px` : '48px'};
`;

export const Row = styled(Container)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: ${({ wrap, nowrap }) => (wrap || nowrap ? wrap || 'nowrap' : 'wrap')};
  flex-wrap: ${({ wrap, nowrap }) => (wrap || nowrap ? wrap || 'nowrap' : 'wrap')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'unset')};
  align-items: ${(p) => (p.alignCenter || p.alignItems ? p.alignItems || 'center' : 'unset')};
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'unset')};
  margin-left: ${({ margin }) => (margin ? `${margin}px` : '0')};
  margin-right: ${({ margin }) => (margin ? `${margin}px` : '0')};
  width: ${(p) => (p.width || p.wide ? p.width || '100%' : 'auto')};
  ${(p) => (p.gap ? `gap: ${p.gap}px;` : '')};
  ${(p) => (p.height ? `height: ${p.height};` : '')};

  ${({ margin }) =>
    margin
      ? `
    > * {
      padding: 0 ${-margin}px;
    }
  `
      : ''};

  ${(p) =>
    p.cols
      ? `
    > * {
      width: ${100 / p.cols}%;
      max-width: ${100 / p.cols}%;
    }
  `
      : ''};

  ${(p) =>
    p.minCols
      ? `
    > * {
      max-width: ${100 / p.minCols}%;
    }
  `
      : ''};

  ${(p) =>
    p.colsMarginBottom
      ? `
     margin-bottom: -${p.colsMarginBottom}px;
    > * {
      margin-bottom: ${p.colsMarginBottom}px;
    }
  `
      : ''};
`;

export const Col = styled(Container)`
  flex-grow: ${({ auto }) => (auto ? 'unset' : '1')};
  ${(p) => (p.width || p.wide ? 'width: 100%' : '')};
  ${(p) => (p.width ? ` max-width: ${p.width}` : '')};
  min-width: ${(p) => (p.minWidth ? p.minWidth : 'unset')};
  ${(p) => (p.padding ? `padding: 0 ${p.padding}px` : '')};

  ${({ paddingRight }) =>
    paddingRight
      ? `
    padding-right: ${paddingRight}px;
  `
      : ``}
  ${({ paddingLeft }) =>
    paddingLeft
      ? `
    padding-left: ${paddingLeft}px;
  `
      : ``}
`;
export const InnerBox = styled.div`
  padding: ${(p) => (p.padding ? `${p.padding}px` : '24px')};
  border-bottom: 1px solid #dde4e9;

  ${({ paddingX }) =>
    paddingX
      ? `
    padding-left: ${paddingX}px;
    padding-right: ${paddingX}px;
  `
      : ``}

  ${({ paddingY }) =>
    paddingY
      ? `
    padding-top: ${paddingY}px;
    padding-bottom: ${paddingY}px;
  `
      : ``} 
  
  &:last-child {
    border-bottom: none;
  }
`;

export const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${COLORS.greyBasic};
  padding: 0;
  margin: ${(p) => (p.marginY ? `${p.marginY}px` : '24px')} 0;
`;

export const Block = styled(Container)`
  ${({ wide }) => (wide ? `width: 100%;` : '')}
  ${({ width }) => (width ? `width: ${width}px;` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;
